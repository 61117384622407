import React from 'react'
import './aboutus.scss'
import BannerIP from './../../components/bannerInsidePages/bannerInsidePages'
import { withRouter } from 'react-router-dom'

class Aboutus extends React.Component {
  jumpTo51 = () => {
    this.props.history.push('/JoinUs')
  }

  render() {
    document.title = '关于我们'
    return (
      <div id="aboutus">
        <BannerIP type={'aboutUs'} />
        <div className="title">公司简介</div>
        <div className="d1 luxy-el">
          <div className="img" data-scroll-reveal=" then enter right  over 0.7s">
            <img
              alt="关于我们"
              title="关于我们"
              src={require('./../../assets/imgs/gywm/about03.png')}
            />
          </div>

          <div className="text" data-scroll-reveal="then enter left  over 0.7s">
            <ul className="ab_text">
              <li>
                <div>
                  CAM汽车咨询创立于2002年，总部位于上海，设立有北京及无锡分部，现有团队超百人，是深耕于汽车行业的一体化整合咨询服务商。
                  CAM依托多年数据积累，借助先进的BI工具、AI算法及成熟的研究方法论向汽车行业提供包括多维数据、定制系统及咨询服务在内的体系化解决方案。
                  CAM独创的产品竞争力指数、敏捷分析方法论、PMS多维分析平台等为各大厂商的战略升级及数字化转型提供了有力的支持。
                </div>
              </li>
              <li>
                <div>
                  经过尽二十年的发展，CAM已经成为“国家高新技术企业”，上海市“双软认证”企业，上海市嘉定区优质企业，拥有专业的软件开发能力和众多软件著作权。
                  搭建了CAM-PMS（多维分析平台）、CAM-AI（人工智能平台）、CAM-BI（敏捷分析平台）、CAM-INFO（信息平台）、CAM-NEV（新能源平台）
                  等数据分析平台和移动端应用工具。
                  我们的研究范围涵盖商情、产品、行业、消费者、市场预测以及专案咨询等领域。
                </div>
              </li>
              <li>
                <div>
                  CAM拥有丰富的历史数据资源、终端渠道资源和代理商资源。数据资源涵盖价格、销量、促销、配置和用户；
                  终端渠道资源全国已超千家，价格监测城市已覆盖全国省会并下探三、四线城市；
                  开展长期战略合作、符合CAM专业要求和执行标准的代理商资源全国已达60多家。
                </div>
              </li>
              <li>
                <div>
                  我们一直秉承“专业、高效、务实、坦诚”的理念，为汽车行业客户提供全方位的解决方案和咨询服务。
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="d3 luxy-el">
          <div className="model">
            <h3 className="ab_title">我们的优势</h3>
            <div className="adv-card">
              <div className="adv-item">
                <img src={require('../../assets/imgs/gywm/devIcon1.png')} alt="" />
                <p className="adv-h4">项目优势</p>
                <p className="adv-title">实地调研+大数据</p>
                <p className="adv-title">线上+线下情报检测</p>
                <p className="adv-title">专业分析+咨询</p>
              </div>
              <div className="adv-item">
                <img src={require('../../assets/imgs/gywm/devIcon2.png')} alt="" />
                <p className="adv-h4">资源优势</p>
                <p className="adv-title">检测网点 1000+</p>
                <p className="adv-title">服务车型 900+</p>
                <p className="adv-title">行业数据累计 17+年</p>
              </div>
              <div className="adv-item">
                <img src={require('../../assets/imgs/gywm/devIcon3.png')} alt="" />
                <p className="adv-h4">服务优势</p>
                <div className="adv-text">
                  <p className="adv-title">专业</p>
                  <p className="adv-title">专注</p>
                </div>
                <div className="adv-text">
                  <p className="adv-title">资深</p>
                  <p className="adv-title">积极</p>
                </div>
                <div className="adv-text">
                  <p className="adv-title">创新</p>
                  <p className="adv-title">主动</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d2 luxy-el">
          <div className="ab_title">发展历程</div>
          <img src={require('../../assets/imgs/gywm/course.png')} alt="发展历程" />
        </div>

        <div className="d4 luxy-el">
          {/* <div className="arrow"></div> */}
          <div className="model">
            <div className="d4_img" style={{ textAlign: 'center' }}>
              <p>和优秀的人一起工作</p>
              <a className="applyFor" onClick={this.jumpTo51}>
                加入我们
              </a>
              {/* <img
                alt="加入我们链接"
                title="加入我们链接"
                className="img2"
                src={require('../../../src/assets/imgs/gywm/gywm_lj.png')}
                onClick={this.jumpTo51}
              /> */}
              {/* https://jobs.51job.com/all/co744189.html */}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Aboutus)
