import React from "react";

class Analysis extends React.Component {
    componentDidMount() {
        $(function () {
            $('#analysisBanner').terseBanner({
                adaptive: true,
                auto: 4000,
                arrow: true,
            });
        });
    }


    render() {
        return <div id={"analysis"}>
            <div id="analysisBanner" className={"banner custom"}>
                <ul>
                    <li className="mb_item">
                        <img alt="综合分析研究一" title="综合分析研究一" data-src={require("../../../assets/imgs/Research/intelligence/cam_sq12.jpg")}/>
                    </li>
                    <li className="mb_item">
                        <img alt="综合分析研究二" title="综合分析研究二" data-src={require("../../../assets/imgs/Research/intelligence/cam_sq13.jpg")}/>
                    </li>
                    <li className="mb_item">
                        <img alt="综合分析研究三" title="综合分析研究三" data-src={require("../../../assets/imgs/Research/intelligence/cam_sq14.jpg")}/>
                    </li>
                    <li className="mb_item">
                        <img alt="综合分析研究四" title="综合分析研究四" data-src={require("../../../assets/imgs/Research/intelligence/cam_sq15.jpg")}/>
                    </li>
                </ul>
            </div>
        </div>
    }
}

export default Analysis;