import React from "react";

class JpBriefing extends React.Component {
    componentDidMount() {
        $(function () {
            $('#jpBriefingBanner').terseBanner({
                adaptive: true,
                auto: 4000,
                arrow: true,
            });
        });
    }



    render() {
        return <div id={"jpBriefing"}>
            <div id="jpBriefingBanner" className={"banner"}>
                <ul>
                    <li className="mb_item">
                        <img alt="销量研究一" title="销量研究一" data-src={require("../../../assets/imgs/Research/intelligence/cam_sq01.jpg")}/>
                    </li>
                    <li className="mb_item">
                        <img alt="销量研究二" title="销量研究二" data-src={require("../../../assets/imgs/Research/intelligence/cam_sq02.jpg")}/>
                    </li>
                    <li className="mb_item">
                        <img alt="销量研究三" title="销量研究三" data-src={require("../../../assets/imgs/Research/intelligence/cam_sq03.jpg")}/>
                    </li>
                    <li className="mb_item">
                        <img alt="销量研究四" title="销量研究四" data-src={require("../../../assets/imgs/Research/intelligence/cam_sq04.jpg")}/>
                    </li>
                </ul>
            </div>
        </div>
    }
}

export default JpBriefing;