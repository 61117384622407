import React from 'react'
import './repository.scss'
import BannerIP from './../../components/bannerInsidePages/bannerInsidePages'

class Repository extends React.Component {
  render() {
    return (
      <div id="page-repository">
        <BannerIP type={'example1'} />
        <div className="title">CAM知识库</div>
      </div>
    )
  }
}

export default Repository
