import React from 'react'
import './_layout.scss'
import BannerIP from '../../components/bannerInsidePages/bannerInsidePages'
import { Switch, Route, NavLink, Redirect } from 'react-router-dom'
import navs from './../../config/navs'
import { CSSTransition } from 'react-transition-group'
import { Modal } from 'antd'
class CamAI extends React.Component {
  constructor() {
    super()
    this.state = {
      visible: false,
    }
  }

  render() {
    document.title = 'CAM研究'
    const nav_childs = navs.find(m => m.routePath === '/Research/:type').childs
    const type = this.props.match.params.type
    if (nav_childs.every(v => !new RegExp(type, 'i').test(v.url))) return <Redirect to="/404" />
    return (
      <div id="page-research">
        <BannerIP type={'research'} />
        <div>
          <div className={'top'}>
            {/* <div className="title">CAM 研究</div> */}
            <ul>
              <li>
                <NavLink activeClassName="active" to={'/Research/Intelligence'}>
                  商情分析
                  {/* <span>Intelligence</span> */}
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName="active" to={'/Research/Product'}>
                  产品研究
                  {/* <span>Product Research</span> */}
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName="active" to={'/Research/Industry'}>
                  行业观察
                  {/* <span>Industry Observation</span> */}
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName="active" to={'/Research/Bazaar'}>
                  市场预测
                  {/* <span>Forecast</span> */}
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName="active" to={'/Research/Consumer'}>
                  消费者调研
                  {/* <span>Consumer Survey</span> */}
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName="active" to={'/Research/Consult'}>
                  专案咨询
                  {/* <span>Special Consultation</span> */}
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName="active" to={'/Research/Snatched'}>
                  智能网联
                  {/* <span>Intelligent snatched</span> */}
                </NavLink>
              </li>
            </ul>
            <div className={'border'} />
          </div>
          <div className={'content'}>
            {nav_childs.map(({ component: Component, url }, index) => (
              <Route key={'route_research_' + index} path={url}>
                {({ match }) => (
                  <CSSTransition
                    key={'csst_' + url}
                    in={match != null}
                    timeout={600}
                    classNames="slide"
                    unmountOnExit
                  >
                    <Component />
                  </CSSTransition>
                )}
              </Route>
            ))}
          </div>
          <div className={'footer'}>
            <div>
              <div>
                <div className="title">研究咨询服务</div>
                <p>关于CAM研究的更多咨询或合作，请联系我们的工作人员。</p>
              </div>

              <a className="applyFor" onClick={() => this.setState({ visible: true })}>
                获取解决方案
              </a>
            </div>
          </div>
          <Modal
            wrapClassName={'common-modal footer-modal'}
            centered
            visible={this.state.visible}
            footer={null}
            onCancel={() => this.setState({ visible: false })}
          >
            <div className={'contactUs'}>
              <div>
                <h2>联系我们</h2>
                <p>E-mail：xifuz@chinaautomarket.com</p>
              </div>
              <div>
                <p>上海（总部）</p>
                <p>
                  <span>
                    <img
                      alt="电话"
                      title="电话"
                      src={require('../../assets/imgs/modal/tel_add.png')}
                    />
                  </span>
                  +8621-6481 1040 &nbsp;&nbsp; 13512102701
                </p>
                <p>
                  <span>
                    <img
                      alt="地址"
                      title="地址"
                      src={require('../../assets/imgs/modal/tel_add.png')}
                    />
                  </span>
                  上海市中山西路2025号永升大厦518室
                  <br />
                  Room 518, No.2025 Yongsheng Building, West Zhongshan Rd, Shanghai, China
                </p>
              </div>
              {/*<div>
                            <p>北京</p>
                            <p>
                        <span>
                        <img src={require("../../assets/imgs/modal/tel_add.png")} alt=""/>
                    </span>
                                +8610-5743 7122
                            </p>
                            <p>
                         <span>
                        <img src={require("../../assets/imgs/modal/tel_add.png")} alt=""/>
                    </span>
                                北京市朝阳区工体东路20号百富国际大厦A座7D
                                <br/>
                                Rm7D, Baifu International Building A, No.20 Gongti East Road, Chaoyang, Beijing, China
                            </p>
                        </div>
                        <div>
                            <p>无锡</p>
                            <p>
                        <span>
                        <img src={require("../../assets/imgs/modal/tel_add.png")} alt=""/>
                    </span>
                                +0510-6696 8832
                            </p>
                            <p>
                         <span>
                        <img src={require("../../assets/imgs/modal/tel_add.png")} alt=""/>
                    </span>
                                无锡市震泽路18号无锡（国家）软件园双子座B座1801室
                                <br/>
                                Room1801,Gemini Building B, No.18 ipark, Zhengze Rd, Wuxi, China
                            </p>
                        </div>*/}
            </div>
          </Modal>
        </div>
      </div>
    )
  }
}

export default CamAI
