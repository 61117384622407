import React from 'react'
import './bannerInsidePages.scss'
//import ScrollReveal from 'scrollreveal';//页面缓入插件

//页面缓入配置
// let config = {
//     after: '0s',
//     enter: 'top',
//     move: '70px',
//     over: '2s',
//     easing: 'ease-in-out',
//     viewportFactor: 0.33,
//     reset: true,
//     init: true
//   };

class BannerIP extends React.Component {
  componentWillUnmount() {
    document.body.scrollTop = document.documentElement.scrollTop = 1
  }
  componentDidMount() {
    // $(function() {
    //     $("img").lazyload({effect: "fadeIn"});
    //  });
    if (!/msie [6|7|8|9]/i.test(navigator.userAgent)) {
      ;(function () {
        var config = {
          after: '0s',
          enter: '',
          move: '44px',
          over: '0.66s',
          easing: 'ease-in-out',
          opacity: 0,
          viewportFactor: 0.33,
          reset: false,
          init: true,
        }
        const sr = new scrollReveal(config)
        setTimeout(() => {
          document.body.scrollTop = document.documentElement.scrollTop = 0
          document.body.scrollTop = document.documentElement.scrollTop = 1
        }, 500)
      })()
    }
  }

  render() {
    let type = this.props.type
    let html = ''
    switch (type) {
      case 'data':
        html = (
          <div>
            <img
              alt="CAM数据"
              title="CAM数据"
              src={require('./../../assets/imgs/bannerInsidePages/CAMdata-banner.png')}
            />
            <br />
            <div className="text_camData">
              <h1 className="line1" data-scroll-reveal="enter right over 1.5s">
                CAM数据
              </h1>
              <span className="line2" data-scroll-reveal="enter left over 2s move 40px">
                CAM拥有丰富的历史数据资源、终端渠道资源和代理商资源。
              </span>
            </div>
          </div>
        )
        break

      case 'system':
        html = (
          <div>
            <img
              alt="CAM系统"
              title="CAM系统"
              src={require('./../../assets/imgs/bannerInsidePages/CAMsys-banner.png')}
            />
            <br />
            <div className="text_system">
              <div>
                <h1 className="line1" data-scroll-reveal="enter right over 1.5s">
                  CAM系统
                </h1>
                <span className="line2" data-scroll-reveal="enter left over 2s move 40px">
                  CAM数据平台为客户定制分析市场需求或是提供快速分析把握市场动态的应对措施。
                  <br />
                  整合线上线下的情报监测，精准专业的分析，让客户更加全面掌握市场，不断提高行业竞争力。
                </span>
              </div>
            </div>
          </div>
        )
        break
      case 'research':
        html = (
          <div>
            <img
              alt="CAM研究"
              title="CAM研究"
              src={require('./../../assets/imgs/bannerInsidePages/CAMrea-banner.png')}
            />
            <br />
            <div className="text_research">
              <div>
                <h1 className="line1" data-scroll-reveal="enter right over 1.5s">
                  CAM研究
                </h1>
                <span className="line2" data-scroll-reveal="enter left over 2s move 40px">
                  CAM研究包括了商情分析、产品研究、行业观察、市场预测、消费者调研和专案咨询。
                  <br />
                  实时跟踪车市动态，全面、准确的数据为客户决策提供支持。
                </span>
              </div>
            </div>
          </div>
        )
        break
      case 'app':
        html = (
          <div>
            <img
              alt="CAM移动端"
              title="CAM移动端"
              src={require('./../../assets/imgs/bannerInsidePages/CAMapp-banner.png')}
            />{' '}
            <br />
            <div className="text_app">
              <h1 className="line1" data-scroll-reveal="enter right over 1.5s">
                CAM移动端
              </h1>
              <span className="line2" data-scroll-reveal="enter left over 2s move 40px">
                为客户提供便捷式移动查询平台，随时随地掌控行业动态，便捷查询行业数据。
              </span>
            </div>
          </div>
        )
        break
      case 'example':
        html = (
          <div>
            <img
              alt="成功案例"
              title="成功案例"
              src={require('./../../assets/imgs/bannerInsidePages/cgal_banner.png')}
            />
            <br />
            <div className="text_example">
              <div>
                <h1 className="line1" data-scroll-reveal="enter right over 1.5s">
                  成功案例
                </h1>
                <span className="line2" data-scroll-reveal="enter left over 2s move 40px">
                  切实提供智能化解决方案&nbsp;&nbsp;高效助力汽车行业发展
                </span>
              </div>
            </div>
          </div>
        )
        break
      case 'example1':
        html = (
          <div className={'example1'}>
            <img
              alt="案例一"
              title="案例一"
              src={require('./../../assets/imgs/bannerInsidePages/cgal_banner01.png')}
            />{' '}
            <br />
            <div className="text_example1">
              <div className="bg">
                <h1 className="line1" data-scroll-reveal="enter right over 1.5s">
                  产品装备竞争力案例
                </h1>
              </div>
              <span className="line2" data-scroll-reveal="enter left over 2s move 40px">
                关键词：车型对比、装备、竞争力
              </span>
            </div>
          </div>
        )
        break
      case 'example2':
        html = (
          <div className={'example1'}>
            <img
              alt="案例二"
              title="案例二"
              src={require('./../../assets/imgs/bannerInsidePages/cgal_banner01.png')}
            />{' '}
            <br />
            <div className="text_example1">
              <div className="bg">
                <h1 className="line1" data-scroll-reveal="enter right over 1.5s">
                  自主高端品牌市场性及应对研究案例
                </h1>
              </div>
              <span className="line2" data-scroll-reveal="enter left over 2s move 40px">
                关键词：自主高端品牌、品牌市场、市场应对
              </span>
            </div>
          </div>
        )
        break
      case 'aboutUs':
        html = (
          <div>
            <img
              alt="关于我们"
              title="关于我们"
              src={require('./../../assets/imgs/bannerInsidePages/aboutus_banner.png')}
            />
            <br />
            <div className="text_aboutUs">
              <div>
                <h1 className="line2" data-scroll-reveal="enter bottom over 1.5s move 40px">
                  专业
                </h1>
                <div className="line" data-scroll-reveal="enter bottom over 1.5s move 40px"></div>
                <h1 className="line2" data-scroll-reveal="enter bottom over 1.5s move 40px">
                  高效
                </h1>
                <div className="line" data-scroll-reveal="enter bottom over 1.5s move 40px"></div>
                <h1 className="line2" data-scroll-reveal="enter bottom over 1.5s move 40px">
                  务实
                </h1>
                <div className="line" data-scroll-reveal="enter bottom over 1.5s move 40px"></div>
                <h1 className="line2" data-scroll-reveal="enter bottom over 1.5s move 40px">
                  坦诚
                </h1>
              </div>
            </div>
          </div>
        )
        break

      case 'joinUs':
        html = (
          <div>
            <img
              alt="加入我们"
              title="加入我们"
              src={require('./../../assets/imgs/bannerInsidePages/joinus-banner.png')}
            />
            <br />
            <div className="text_joinUs">
              <div>
                <h1 className="line1" data-scroll-reveal="enter bottom over 1.5s move 40px">
                  加入我们
                </h1>
                <span className="line2" data-scroll-reveal="enter bottom over 1.5s move 40px">
                  在CAM，晋升机制灵活，任人唯贤，拥有广阔的发展空间！
                  <br />
                  CAM公司始终遵循理论结合实战的培训方式，在项目中完善理论、丰富经验；秉承扁平化管理，项目经理直接负
                  <br />
                  责制，高效沟通协调；坚持人性关怀，尊重员工意愿制定职业发展计划，最大限度发挥员工个人潜能！
                </span>
              </div>
            </div>
          </div>
        )
        break
    }
    return <div className="bannerIP luxy-el">{html}</div>
  }
}
export default BannerIP
