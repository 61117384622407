import React, { Component } from 'react'
import './modal.scss'
import { Input, Select, Form, Button, message } from 'antd'
import 'antd/dist/antd.css'
import { pms, nev, info, bi, ai } from '../../config/functions'
import request from '../../utils/request'
import { email_reg, tel_reg } from '../../utils/regexp'
import FunctionsModal from './functionsModal'

message.config({
  top: 200,
  duration: 2,
})

const modalOpenClass = 'modal-open'
const Option = Select.Option
const toggleBodyClass = visible => {
  const body = document.body
  if (visible) {
    body.classList.add(modalOpenClass)
  } else {
    body.classList.remove(modalOpenClass)
  }
}

class SqsyModal extends Component {
  constructor(props) {
    super(props)
    toggleBodyClass(props.visible)
    this.state = {
      visible: props.visible || false,
      functions: [],
      platformName: '',
      modalVisible: false,
    }
  }

  close = e => {
    this.setState(
      {
        visible: false,
      },
      () => {
        setTimeout(() => this.props.onClose(), 300)
        toggleBodyClass(false)
      }
    )
  }

  sqsy = () => {
    let { platform } = this.props

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (platform.toLowerCase().includes('pms')) {
          let zff = []
          values.functions.split(',').map(i => {
            if (i.includes('pms')) {
              zff.push(i.substring(3, i.length))
            } else {
              zff.push(i)
            }
          })
          values.functions = zff.join(',')
        }
        request('http://api.chinaautomarket.com/api/UserTrail', 'post', values).then(res => {
          if (res.result === 'OK') {
            message.success('申请成功，请等待审核！')
          } else {
            message.error('申请失败，请重试')
          }
        })
      }
    })
  }

  componentDidMount() {
    let { platform } = this.props
    let platformName

    platform = platform.toLowerCase()
    platformName = platform.includes('pms')
      ? 'pms'
      : platform.includes('ai')
      ? 'ai'
      : platform.includes('bi')
      ? 'bi'
      : platform.includes('info')
      ? 'info'
      : platform.includes('nev')
      ? 'nev'
      : ''
    this.setState({ platformName }, () => {
      this.findFunctions()
    })
  }

  // 自定义表单校验规则
  validatorForm = (rule, value, callback) => {
    if (value && rule.pattern && !value.match(rule.pattern)) {
      callback(rule.message)
    } else {
      callback()
    }
  }

  setModalVisible = (modalVisible, flag, data) => {
    if (flag) {
      this.props.form.setFieldsValue({ functions: data })
    }
    this.setState({ modalVisible })
  }

  findFunctions = () => {
    const { platformName } = this.state
    let functions
    functions =
      platformName === 'pms'
        ? pms
        : platformName === 'ai'
        ? []
        : platformName === 'bi'
        ? bi
        : platformName === 'info'
        ? info
        : platformName === 'nev'
        ? nev
        : []
    if (functions.length > 0) {
      functions.map(i => {
        if (i.children) {
          i.value = i.value + 'oneStep'
        }
      })
    }

    this.props.form.setFieldsValue({ functions: null })
    this.setState({ functions })
  }

  handleChange = platformName => {
    this.setState({ platformName }, () => this.findFunctions())
  }

  render() {
    const { visible, functions, platformName, modalVisible } = this.state
    const { getFieldDecorator } = this.props.form
    return (
      <div className={`modal-container ${visible ? 'modal-show' : 'modal-hide'} ontrial`}>
        <div className={'modal-body1'}>
          <div className="close">
            <img
              alt="申请试用"
              title="申请试用"
              onClick={this.close}
              src={require('./../../assets/imgs/modal/sqsy_close.png')}
            />
          </div>
          <div className={'modal-content1'}>
            <div className={'explain'}>
              <h2>CAM系统试用申请</h2>
              <p>
                如需申请凭条试用，请按照下方步骤填写申请资料。
                资料填写后，我们工作人员会尽快与您取得联系，请您耐心等待。谢谢！
              </p>
              <p>申请步骤:</p>
              <ul>
                <li>
                  <span>·</span>1. 请选择您感兴趣的平台
                </li>
                <li>
                  <span>·</span>2. 填写申请信息
                </li>
                <li>
                  <span>·</span>3. 等待工作人员联系
                </li>
              </ul>
            </div>
            <div className={'form'}>
              <Form className={'form-content'}>
                <div>
                  <Form.Item hasFeedback>
                    {getFieldDecorator('name', {
                      rules: [
                        {
                          required: true,
                          message: '请输入姓名',
                        },
                      ],
                    })(<Input placeholder="您的姓名" />)}
                  </Form.Item>
                  <Form.Item hasFeedback>
                    {getFieldDecorator('sex', {
                      rules: [
                        {
                          required: true,
                          message: '请选择性别',
                        },
                      ],
                      initialValue: '男',
                    })(
                      <Select placeholder="您的性别" hasFeedback>
                        <Option value="男">男</Option>
                        <Option value="女">女</Option>
                      </Select>
                    )}
                  </Form.Item>
                </div>
                <div>
                  <Form.Item hasFeedback>
                    {getFieldDecorator('tel', {
                      rules: [
                        {
                          required: true,
                          message: '请输入联系电话',
                        },
                        {
                          pattern: tel_reg,
                          validator: this.validatorForm,
                          message: '请输入正确的电话格式',
                        },
                      ],
                    })(<Input placeholder="您的联系电话" />)}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator('email', {
                      rules: [
                        {
                          required: true,
                          message: '请输入邮箱',
                        },
                        {
                          pattern: email_reg,
                          validator: this.validatorForm,
                          message: '请输入正确的邮箱格式',
                        },
                      ],
                    })(<Input placeholder="您的企业邮箱" />)}
                  </Form.Item>
                </div>
                <div>
                  <Form.Item hasFeedback>
                    {getFieldDecorator('company', {
                      rules: [
                        {
                          required: true,
                          message: '请输入公司名',
                        },
                      ],
                    })(<Input placeholder="您的公司名" />)}
                  </Form.Item>
                  <Form.Item hasFeedback>
                    {getFieldDecorator('position', {
                      rules: [
                        {
                          required: true,
                          message: '请输入职务',
                        },
                      ],
                    })(<Input placeholder="您的公司职务" />)}
                  </Form.Item>
                </div>
                <div className={'large-width special'}>
                  <Form.Item hasFeedback>
                    {getFieldDecorator('platform', {
                      rules: [
                        {
                          required: true,
                          message: '请选择申请平台',
                        },
                      ],
                      initialValue: platformName,
                    })(
                      <Select placeholder="您申请试用的平台" onChange={this.handleChange}>
                        <Option value="pms">CAM PMS 多维分析平台</Option>
                        <Option value="ai">CAM AI 人工智能平台</Option>
                        <Option value="bi">CAM BI 敏捷分析平台</Option>
                        <Option value="info">CAM INFO 信息平台</Option>
                        <Option value="nev">CAM NEV 新能源平台</Option>
                      </Select>
                    )}
                  </Form.Item>
                </div>
                <div className={'large-width'}>
                  {functions.length > 0 && (
                    <Form.Item hasFeedback>
                      {getFieldDecorator('functions', {
                        rules: [
                          {
                            required: true,
                            message: '请选择平台功能',
                          },
                        ],
                      })(
                        <Input
                          readOnly={true}
                          placeholder="您所需的平台功能"
                          onClick={() => {
                            this.setModalVisible(true)
                          }}
                        />
                      )}
                    </Form.Item>
                  )}
                </div>
              </Form>
              <div className="sqsy-btn">
                <Button type={'primary'} onClick={this.sqsy}>
                  申请试用
                </Button>
              </div>
              <div className="remark">
                注：CAM在任何场合下都不会泄露您的信息|如果遇到其他问题，请联系我们
              </div>
            </div>
          </div>
        </div>
        <FunctionsModal
          modalVisible={modalVisible}
          functions={functions}
          setModalVisible={this.setModalVisible}
          platformName={platformName}
        />
      </div>
    )
  }
}

export default Form.create()(SqsyModal)
