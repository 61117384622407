function parseJSON(response) {
    return response.json();
}

function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
        const Authorization = response.headers.get("Authorization");
        if (Authorization) {
            sessionStorage.setItem("Authorization", Authorization);
        }
        return response;
    }

    const error = new Error(response.statusText);
    error.response = response;
    throw error;
}
export default function request(url, method = "GET", body, isStringify = true) {
    if (method.toUpperCase() === "GET") {
        if (body) {
            url += "?";
            for (let key in body) {
                let typeStr = Object.prototype.toString.call(body[key]);
                if (typeStr === "[object Array]") {
                    for (let value of body[key]) {
                        url += `${key}=${value}&`;
                    }
                } else if (
                    typeStr === "[object String]" ||
                    typeStr === "[object Number]" ||
                    typeStr === "[object Boolean]" ||
                    typeof body[key] === "undefined"
                ) {
                    url += `${key}=${body[key]}&`;
                } else {
                    alert(`暂不支持GET传递${typeStr}类型参数,请自行扩展或改用POST传参!`, false);
                    throw new Error(`暂不支持GET传递${typeStr}类型参数,请自行扩展或改用POST传参!`);
                }
            }
            url = url.substring(0, url.length - 1);
        }
        url +=
            (url.includes("?") ? "&r=" : "?r=") +
            localStorage.getItem("loginTimestamp");
        body = undefined;
    } else {
        if (body) {
            if (isStringify) {
                body = JSON.stringify(body);
            } else {
                let params = new URLSearchParams();
                for (let key in body) {
                    let typeStr = Object.prototype.toString.call(body[key]);
                    if (typeStr === "[object Array]") {
                        for (let value of body[key]) {
                            params.append(key, value);
                        }
                    } else {
                        params.set(key, body[key]);
                    }
                }
                body = params;
            }
        } else body = undefined;
    }
    return fetch(url, {
        mode: "cors",
        method,
        headers: {
            "Content-Type": isStringify
                ? "application/json; charset=UTF-8"
                : "application/x-www-form-urlencoded; charset=UTF-8",
            Accept: "application/json",
            Authorization: sessionStorage.getItem("Authorization") || "" // 从sessionStorage中获取access token
        },
        body
    })
        .then(checkStatus)
        .then(parseJSON)
        .then(data => {
            if (data.result !== "OK") {
                alert(data.message, false);
                return;
            }
            return data;
        })
        .catch(err => {
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 401:
                        window.routerhistory.push("/");
                        break;
                    case 404:
                        alert("404路径错误!", false);
                        break;
                    default:
                        alert("服务器发生错误!", false);
                        break;
                }
                return;
            }
            alert("服务器发生错误!", false);
        });
}