import React from "react";

class Price extends React.Component {
    componentDidMount() {
        $(function () {
            $('#priceBanner').terseBanner({
                adaptive: true,
                auto: 4000,
                arrow: true,
            });
        });
    }



    render() {
        return <div id={"price"}>
            <div id="priceBanner" className={"banner custom"}>
                <ul>
                    <li className="mb_item">
                        <img alt="价格研究一" title="价格研究一" data-src={require("../../../assets/imgs/Research/intelligence/cam_sq06.jpg")}/>
                    </li>
                    <li className="mb_item">
                        <img alt="价格研究二" title="价格研究二" data-src={require("../../../assets/imgs/Research/intelligence/cam_sq07.jpg")}/>
                    </li>
                    <li className="mb_item">
                        <img alt="价格研究三" title="价格研究三" data-src={require("../../../assets/imgs/Research/intelligence/cam_sq08.jpg")}/>
                    </li>
                    <li className="mb_item">
                        <img alt="价格研究四" title="价格研究四" data-src={require("../../../assets/imgs/Research/intelligence/cam_sq09.jpg")}/>
                    </li>
                </ul>
            </div>
        </div>
    }
}

export default Price;