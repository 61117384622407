import React from 'react'

class EconomicResearch extends React.Component {
  componentDidMount() {
    $(function () {
      $('#economicResearchBanner').terseBanner({
        adaptive: true,
        auto: 4000,
        arrow: true,
      })
    })
  }

  componentWillUnmount() {
    let banner = $('#economicResearchBanner').data('terseBanner')
    if (banner.stop) banner.stop() //清除定时器
    $('#economicResearchBanner').data('terseBanner', undefined)
  }

  render() {
    return (
      <div id={'economicResearch'} className={'tabContent'}>
        <div className="explain">
          <div className="headline">
            <p>
              宏观形势研究样例展示
              <span>(宏观经济研究)</span>
            </p>
          </div>
          <div className="content1">
            <p>
              <b>目的:</b>
            </p>
            <p>
              跟踪并分析解读CPI、PPI、PMI、进出口、新增信贷等主要经济指标，分析指标变化原因及未来动向。
            </p>
            <p>
              <b>内容:</b>
            </p>
            <p>
              通过跟踪分析宏观经济指标变化情况，研判红颜禁止整体动向，对市场需求变化作前瞻判断，从而为企业运营决策提供宏观指引。
            </p>
          </div>
        </div>
        <div id="economicResearchBanner" className={'banner'}>
          <ul>
            <li className="mb_item">
              <img
                alt="宏观经济研究一"
                title="宏观经济研究一"
                data-src={require('../../../assets/imgs/Research/economicResearch/cam_sq37.png')}
              />
            </li>
            <li className="mb_item">
              <img
                alt="宏观经济研究二"
                title="宏观经济研究二"
                data-src={require('../../../assets/imgs/Research/economicResearch/cam_sq38.png')}
              />
            </li>
            <li className="mb_item">
              <img
                alt="宏观经济研究三"
                title="宏观经济研究三"
                data-src={require('../../../assets/imgs/Research/economicResearch/cam_sq39.png')}
              />
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

export default EconomicResearch
