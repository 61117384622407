import React from 'react'
import { Carousel, Tabs } from 'antd'
import './BI.scss'
import Modal from '@/components/modal'
import { withRouter } from 'react-router-dom'

class CamBI extends React.Component {
  constructor() {
    super()
    this.state = {
      // tabName: 'special',
    }
  }
  componentDidMount() {
    //轮播图配置
    $(function () {
      //给左右按钮绑定轮播插件的左右按钮事件
      $('#s-pic-btn a').on('click', function () {
        $('#camBI-pic .tb-arrow .' + this.className).click()
      })

      $('#camBI-pic').terseBanner({
        adaptive: true,
        auto: 5000,
        arrow: true,
        btn: true,
        init: function () {
          let banner = this
          $('#page-CamBI .disc').hover(
            function () {
              banner.stop()
            },
            function () {
              banner.reStart()
            }
          )
        },
        before: function (currentIndex) {
          //文字动画初始状态

          $('#page-CamBI  .disc .txt').removeClass('active')
        },
        after: function (currentIndex) {
          //文字动画最终状态
          $('#page-CamBI  .disc .txt').eq(currentIndex).addClass('active')
        },
      })
      $('#s-pic-btn1 a').on('click', function () {
        $('#camBI-pic1 .tb-arrow .' + this.className).click()
      })
      $('#camBI-pic1').terseBanner({
        adaptive: true,
        auto: 5000,
        arrow: true,
        btn: true,
        init: function () {
          let banner = this
          $('#page-CamBI .bright .disc').hover(
            function () {
              banner.stop()
            },
            function () {
              banner.reStart()
            }
          )
        },
      })
    })
  }
  UNSAFE_componentWillUnmount() {
    let banner = $('#camBI-pic').data('terseBanner')
    if (banner.stop) banner.stop() //清除定时器
    $('#camBI-pic').data('terseBanner', undefined)

    let banner1 = $('#camBI-pic1').data('terseBanner')
    if (banner1.stop) banner1.stop() //清除定时器
    $('#camBI-pic1').data('terseBanner', undefined)
  }

  changeTab = tabName => {
    this.setState({ tabName })
  }

  applicate = () => {
    Modal.ontrial({
      visible: true,
      platform: this.props.history.location.pathname.split('/')[2],
    })
  }

  render() {
    const { tabName } = this.state

    return (
      <div id="page-CamBI">
        <div className="base luxy-el">
          <div className="title">CAM BI &nbsp;敏捷分析平台</div>
          <div className="describe">
            CAM BI
            敏捷分析平台作为一个灵活敏捷分析工具，其主要作用是借助客户验证假想，弹性化自动工作流架构可适配多种不同数据源、算法及异构关联，并可实现增量更新。在实际运用中，为了更高速响应客户的高频业务需求，CAM采用了以实战经验为核心的混合式服务机制，借助自由敏捷分析平台的柔性化、高效的特点及长期沉淀的方法论体系，实现对市场的真正的高效敏捷分析。
          </div>
          <a className="applyFor" onClick={this.applicate}>
            申请试用
          </a>
          <div className="ct">
            {/* <div className="title1" ><i />基础功能</div> */}
            <div className="ct1">
              <div className="left">
                <div className="mk mk1">
                  <div className="img">
                    <img
                      alt="多场景业务构建"
                      title="多场景业务构建"
                      src={require('./../../assets/imgs/CamBI/camIcon1.png')}
                    />
                  </div>
                  <div className="txt">
                    <div className="line1" data-scroll-reveal=" enter  right  over 0.4s">
                      多场景业务构建
                    </div>
                    <div className="line2" data-scroll-reveal=" enter  right  over 0.66s">
                      为响应不同客户需求，提供了多种数据分析手段，支持典型业务场景和定制场景的构建。
                    </div>
                  </div>
                </div>
                <div className="mk mk2">
                  <div className="img">
                    <img
                      alt="高效数据处理"
                      title="高效数据处理"
                      src={require('./../../assets/imgs/CamBI/camIcon2.png')}
                    />
                  </div>
                  <div className="txt">
                    <div
                      className="line1"
                      data-scroll-reveal=" enter  right over 0.44s after 0.2s "
                    >
                      高效数据处理
                    </div>
                    <div
                      className="line2"
                      data-scroll-reveal=" enter  right over 0.77s after 0.2s "
                    >
                      关联百种数据源，独特的数据探索方式，高效便捷地实现了数据导入、清洗、转换等操作。
                    </div>
                  </div>
                </div>
              </div>
              <div className="mid">
                <div className="mk5">
                  <div className="img05">
                    <img
                      alt="BI动图"
                      title="BI动图"
                      src={require('./../../assets/imgs/sysMask.png')}
                    />
                  </div>
                  <div className="img07">
                    <img
                      alt="BI动图"
                      title="BI动图"
                      src={require('./../../assets/imgs/sysMay.png')}
                    />
                  </div>
                  <div className="img06">
                    <img
                      alt="BI动图"
                      title="BI动图"
                      src={require('./../../assets/imgs/CamBI/BI3.png')}
                    />
                  </div>
                  <div className="light">
                    <div className="img img7">
                      <img
                        alt="BI动图"
                        title="BI动图"
                        src={require('./../../assets/imgs/light.png')}
                      />
                    </div>
                    <div className="img img8">
                      <img
                        alt="BI动图"
                        title="BI动图"
                        src={require('./../../assets/imgs/light.png')}
                      />
                    </div>
                    <div className="img img9">
                      <img
                        alt="BI动图"
                        title="BI动图"
                        src={require('./../../assets/imgs/light.png')}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="mid">
                <div className="img img5">
                  <img
                    alt="BI动图"
                    title="BI动图"
                    src={require('./../../assets/imgs/CamBI/bi_d.png')}
                  />
                </div>
                <div className="img img6">
                  <img
                    alt="BI动图"
                    title="BI动图"
                    src={require('./../../assets/imgs/CamBI/bi_a.png')}
                  />
                </div>
                <div className="img img7">
                  <img
                    alt="BI动图"
                    title="BI动图"
                    src={require('./../../assets/imgs/CamBI/bi_b.png')}
                  />
                </div>
                <div className="img img8">
                  <img
                    alt="BI动图"
                    title="BI动图"
                    src={require('./../../assets/imgs/CamBI/bi_b.png')}
                  />
                </div>
                <div className="img img9">
                  <img
                    alt="BI动图"
                    title="BI动图"
                    src={require('./../../assets/imgs/CamBI/bi_b.png')}
                  />
                </div>
              </div> */}
              <div className="right">
                <div className="mk mk3">
                  <div className="img">
                    <img
                      alt="智能分析"
                      title="智能分析"
                      src={require('./../../assets/imgs/CamBI/camIcon3.png')}
                    />
                  </div>
                  <div className="txt">
                    <div
                      className="line1"
                      data-scroll-reveal=" enter  right   over 0.5s  after 0.2s"
                    >
                      智能分析
                    </div>
                    <div
                      className="line2"
                      data-scroll-reveal=" enter  right   over 0.77s  after 0.2s"
                    >
                      依靠先进的IT技术，将复杂的数据源和各类数据信息进行业务逻辑整合并进行计算。
                    </div>
                  </div>
                </div>
                <div className="mk mk4">
                  <div className="img">
                    {' '}
                    <img
                      alt="可视化页面"
                      title="可视化页面"
                      src={require('./../../assets/imgs/CamBI/camIcon4.png')}
                    />
                  </div>
                  <div className="txt">
                    <div className="line1" data-scroll-reveal=" enter  right  over .66s after .4s">
                      可视化页面
                    </div>
                    <div className="line2" data-scroll-reveal=" enter  right  over .66s after .5s">
                      可视化制作各类分析展现、数据挖掘等，数据结果一目了然。
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="advantage luxy-el">
          {/* <div className="title1">
            <i />
            平台优势
          </div> */}
          <ul>
            <li data-scroll-reveal="  enter bottom  over 0.5s move 50px">
              <div className="img img1">
                {' '}
                <img
                  alt="一页包罗"
                  title="一页包罗"
                  src={require('./../../assets/imgs/CamBI/BIIcon1.png')}
                />
              </div>
              <div className="line1">一页包罗</div>
              <div className="line2">多源数据链接 多形式分析</div>
            </li>
            <li data-scroll-reveal="  enter bottom  over 1s move 50px after 0.5s ">
              <div className="img img2">
                {' '}
                <img
                  alt="多维数据"
                  title="多维数据"
                  src={require('./../../assets/imgs/CamBI/BIIcon2.png')}
                />
              </div>
              <div className="line1">多维数据</div>
              <div className="line2">多维分析、挖掘数据关联价值</div>
            </li>
            <li data-scroll-reveal="  enter bottom  over 1.5s move 50px after .5s ">
              <div className="img img3">
                {' '}
                <img
                  alt="交互探索"
                  title="交互探索"
                  src={require('./../../assets/imgs/CamBI/BIIcon3.png')}
                />
              </div>
              <div className="line1">交互探索</div>
              <div className="line2">采用交互式方式深入探索分析</div>
            </li>
            <li data-scroll-reveal="  enter bottom  over 1.5s move 50px after 1s ">
              <div className="img img4">
                {' '}
                <img
                  alt="自助分析"
                  title="自助分析"
                  src={require('./../../assets/imgs/CamBI/BIIcon4.png')}
                />
              </div>
              <div className="line1">自助分析</div>
              <div className="line2">自助分析展示响应多需求</div>
            </li>
          </ul>
        </div>

        <div className="bright luxy-el">
          <div>
            <div className="title1">平台亮点</div>
            <Tabs defaultActiveKey="1">
              <Tabs.TabPane key="1" tab="典型业务场景建立">
                <div className="ct1 special">
                  <div className="pic-txt">
                    <Carousel effect="fade" className="whiteArea">
                      <div className="car-box">
                        <div className="txt">
                          <div className="line1">细分市场发展趋势</div>
                          <div className="line2">
                            该用例用于辅助识别细分市场发展趋势，整合了多年的历史数据横跨产品、销量、价格、基本参数等多个分析维度，可探索销售结构变化趋势、产品投放趋势、价量重心变化趋势等宏观趋势如何变化，并把握子级别之间的相互影响。
                            <span>
                              用户可选择CAM分类标准、自定义分类标准或自定义竞品组等灵活的市场细分方式，并支持对时间间隔进行缩放。
                            </span>
                          </div>
                        </div>
                        <img
                          alt="细分市场发展趋势"
                          title="细分市场发展趋势"
                          src={require('./../../assets/imgs/CamBI/bi_01.jpg')}
                        />
                      </div>
                      <div className="car-box">
                        <div className="txt">
                          <div className="line1">细分市场竞争格局</div>
                          <div className="line2">
                            该用例用于了解特定时间段内各个细分市场的价量结构情况、销量排名（主销分布情况）以及主要竞争车系在细分市场中所处的竞争地位等。
                            <span>
                              我们提供了多种筛选器来过滤次要信息，并能展现表象背后隐藏细节，从而帮助探索销量排名/分布背后的实际原因。
                            </span>
                          </div>
                        </div>
                        <img
                          alt="细分市场竞争格局"
                          title="细分市场竞争格局"
                          src={require('./../../assets/imgs/CamBI/bi_02.jpg')}
                        />
                      </div>
                      <div className="car-box">
                        <div className="txt">
                          <div className="line1">新能源区域市场分析</div>
                          <div className="line2">
                            该用例用于了解某个时间点内新能源车的区域销量及区域加权TP情况，以及当地的价格构成情况（包括成交价、折扣、厂补、地补、国补等）。
                            <span>
                              我们提供了时间、品牌等筛选器来帮助定位分析对象，并辅以同比增长、累计增长等市场变化指标以备参考。
                            </span>
                          </div>
                        </div>
                        <img
                          alt="新能源区域市场分析"
                          title="新能源区域市场分析"
                          src={require('./../../assets/imgs/CamBI/bi_03.jpg')}
                        />
                      </div>
                      <div className="car-box">
                        <div className="txt">
                          <div className="line1">竞品对标分析</div>
                          <div className="line2">
                            该用例用于竞品的主销车款、MSRP及配置差异对比，用户可在不切换页面的情况下选择对标车款，了解其PVA总值、MSRP分布及其相对于标杆的具体配置差异情况。
                            <span>
                              我们提供了多种筛选器来过滤次要信息，并可自由选择销量的计算时间段及销量的计量尺度（累计或月均）。
                            </span>
                          </div>
                        </div>
                        <img
                          alt="新能源区域市场分析"
                          title="新能源区域市场分析"
                          src={require('./../../assets/imgs/CamBI/bi_04.jpg')}
                        />
                      </div>
                    </Carousel>
                  </div>
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane key="2" tab="定制业务场景建立">
                <div className="ct1 custom">
                  <div className="pic-txt">
                    <div className="whiteArea">
                      <div id="camBI-pic1">
                        <Carousel effect="fade">
                          <div>
                            <img
                              alt="竞品投放节奏/策略"
                              title="竞品投放节奏/策略"
                              src={require('./../../assets/imgs/CamBI/bi_05.jpg')}
                            />
                          </div>
                          <div>
                            <img
                              alt="前沿技术趋势分析"
                              title="前沿技术趋势分析"
                              src={require('./../../assets/imgs/CamBI/bi_06.jpg')}
                            />
                          </div>
                          <div>
                            <img
                              alt="产品竞争力分析"
                              title="产品竞争力分析"
                              src={require('./../../assets/imgs/CamBI/bi_07.jpg')}
                            />
                          </div>
                        </Carousel>
                      </div>
                    </div>
                  </div>
                </div>
              </Tabs.TabPane>
            </Tabs>
            {/* <div className={'tab'}>
              <div className="p">
                <p
                  className={tabName === 'special' ? 'active' : ''}
                  onClick={() => this.changeTab('special')}
                >
                  典型业务场景建立
                </p>
              </div>
              <div className="p">
                <p
                  className={tabName === 'custom' ? 'active' : ''}
                  onClick={() => this.changeTab('custom')}
                >
                  定制业务场景建立
                </p>
              </div>
            </div>
            <div
              className="ct1 special"
              style={tabName === 'special' ? { display: 'block' } : { display: 'none' }}
            >
              <div className="pic-txt">
                <Carousel effect="fade" className="whiteArea">
                  <div className="car-box">
                    <div className="txt">
                      <div className="line1">细分市场发展趋势</div>
                      <div className="line2">
                        该用例用于辅助识别细分市场发展趋势，整合了多年的历史数据横跨产品、销量、价格、基本参数等多个分析维度，可探索销售结构变化趋势、产品投放趋势、价量重心变化趋势等宏观趋势如何变化，并把握子级别之间的相互影响。
                        <span>
                          用户可选择CAM分类标准、自定义分类标准或自定义竞品组等灵活的市场细分方式，并支持对时间间隔进行缩放。
                        </span>
                      </div>
                    </div>
                    <img
                      alt="细分市场发展趋势"
                      title="细分市场发展趋势"
                      src={require('./../../assets/imgs/CamBI/bi_01.jpg')}
                    />
                  </div>
                  <div className="car-box">
                    <div className="txt">
                      <div className="line1">细分市场竞争格局</div>
                      <div className="line2">
                        该用例用于了解特定时间段内各个细分市场的价量结构情况、销量排名（主销分布情况）以及主要竞争车系在细分市场中所处的竞争地位等。
                        <span>
                          我们提供了多种筛选器来过滤次要信息，并能展现表象背后隐藏细节，从而帮助探索销量排名/分布背后的实际原因。
                        </span>
                      </div>
                    </div>
                    <img
                      alt="细分市场竞争格局"
                      title="细分市场竞争格局"
                      src={require('./../../assets/imgs/CamBI/bi_02.jpg')}
                    />
                  </div>
                  <div className="car-box">
                    <div className="txt">
                      <div className="line1">新能源区域市场分析</div>
                      <div className="line2">
                        该用例用于了解某个时间点内新能源车的区域销量及区域加权TP情况，以及当地的价格构成情况（包括成交价、折扣、厂补、地补、国补等）。
                        <span>
                          我们提供了时间、品牌等筛选器来帮助定位分析对象，并辅以同比增长、累计增长等市场变化指标以备参考。
                        </span>
                      </div>
                    </div>
                    <img
                      alt="新能源区域市场分析"
                      title="新能源区域市场分析"
                      src={require('./../../assets/imgs/CamBI/bi_03.jpg')}
                    />
                  </div>
                  <div className="car-box">
                    <div className="txt">
                      <div className="line1">竞品对标分析</div>
                      <div className="line2">
                        该用例用于竞品的主销车款、MSRP及配置差异对比，用户可在不切换页面的情况下选择对标车款，了解其PVA总值、MSRP分布及其相对于标杆的具体配置差异情况。
                        <span>
                          我们提供了多种筛选器来过滤次要信息，并可自由选择销量的计算时间段及销量的计量尺度（累计或月均）。
                        </span>
                      </div>
                    </div>
                    <img
                      alt="新能源区域市场分析"
                      title="新能源区域市场分析"
                      src={require('./../../assets/imgs/CamBI/bi_04.jpg')}
                    />
                  </div>
                </Carousel>
                <div className="whiteArea"> 
                  <div className="disc">
                    <div className="txt active">
                      <div className="line1">细分市场发展趋势</div>
                      <div className="line2">
                        该用例用于辅助识别细分市场发展趋势，整合了多年的历史数据横跨产品、销量、价格、基本参数等多个分析维度，可探索销售结构变化趋势、产品投放趋势、价量重心变化趋势等宏观趋势如何变化，并把握子级别之间的相互影响。
                        <span>
                          用户可选择CAM分类标准、自定义分类标准或自定义竞品组等灵活的市场细分方式，并支持对时间间隔进行缩放。
                        </span>
                      </div>
                    </div>
                    <div className="txt">
                      <div className="line1">细分市场竞争格局</div>
                      <div className="line2">
                        该用例用于了解特定时间段内各个细分市场的价量结构情况、销量排名（主销分布情况）以及主要竞争车系在细分市场中所处的竞争地位等。
                        <span>
                          我们提供了多种筛选器来过滤次要信息，并能展现表象背后隐藏细节，从而帮助探索销量排名/分布背后的实际原因。
                        </span>
                      </div>
                    </div>
                    <div className="txt">
                      <div className="line1">新能源区域市场分析</div>
                      <div className="line2">
                        该用例用于了解某个时间点内新能源车的区域销量及区域加权TP情况，以及当地的价格构成情况（包括成交价、折扣、厂补、地补、国补等）。
                        <span>
                          我们提供了时间、品牌等筛选器来帮助定位分析对象，并辅以同比增长、累计增长等市场变化指标以备参考。
                        </span>
                      </div>
                    </div>
                    <div className="txt">
                      <div className="line1">竞品对标分析</div>
                      <div className="line2">
                        该用例用于竞品的主销车款、MSRP及配置差异对比，用户可在不切换页面的情况下选择对标车款，了解其PVA总值、MSRP分布及其相对于标杆的具体配置差异情况。
                        <span>
                          我们提供了多种筛选器来过滤次要信息，并可自由选择销量的计算时间段及销量的计量尺度（累计或月均）。
                        </span>
                      </div>
                    </div>
                  </div>
                  <div id="camBI-pic">
                    <ul>
                      <li>
                        <img
                          alt="细分市场发展趋势"
                          title="细分市场发展趋势"
                          data-src={require('./../../assets/imgs/CamBI/bi_01.jpg')}
                        />{' '}
                      </li>
                      <li>
                        <img
                          alt="细分市场竞争格局"
                          title="细分市场竞争格局"
                          data-src={require('./../../assets/imgs/CamBI/bi_02.jpg')}
                        />{' '}
                      </li>
                      <li>
                        <img
                          alt="新能源区域市场分析"
                          title="新能源区域市场分析"
                          data-src={require('./../../assets/imgs/CamBI/bi_03.jpg')}
                        />{' '}
                      </li>
                      <li>
                        <img
                          alt="竞品对标分析"
                          title="竞品对标分析"
                          data-src={require('./../../assets/imgs/CamBI/bi_04.jpg')}
                        />{' '}
                      </li>
                    </ul>
                  </div> 
                  <div id="s-pic-btn">
                    <a className="prev">
                      <img
                        alt="上一页"
                        title="上一页"
                        src={require('./../../assets/imgs/banner/left.png')}
                      ></img>
                    </a>
                    <a className="next">
                      <img
                        alt="下一页"
                        title="下一页"
                        src={require('./../../assets/imgs/banner/right.png')}
                      ></img>
                    </a>
                  </div>
                 </div> 
              </div>
            </div>
            <div
              className="ct1 custom"
              style={tabName === 'custom' ? { display: 'block' } : { display: 'none' }}
            >
              <div className="pic-txt">
                <div className="whiteArea">
                  <div id="camBI-pic1">
                    <Carousel effect="fade">
                      <div>
                        <img
                          alt="竞品投放节奏/策略"
                          title="竞品投放节奏/策略"
                          src={require('./../../assets/imgs/CamBI/bi_05.jpg')}
                        />
                      </div>
                      <div>
                        <img
                          alt="前沿技术趋势分析"
                          title="前沿技术趋势分析"
                          src={require('./../../assets/imgs/CamBI/bi_06.jpg')}
                        />
                      </div>
                      <div>
                        <img
                          alt="产品竞争力分析"
                          title="产品竞争力分析"
                          src={require('./../../assets/imgs/CamBI/bi_07.jpg')}
                        />
                      </div>
                    </Carousel>
                  </div>
                  <div id="s-pic-btn1">
                    <a className="prev">
                      <img
                        alt="上一页"
                        title="上一页"
                        src={require('./../../assets/imgs/banner/left.png')}
                      ></img>
                    </a>
                    <a className="next">
                      <img
                        alt="下一页"
                        title="下一页"
                        src={require('./../../assets/imgs/banner/right.png')}
                      ></img>
                    </a>
                  </div> 
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    )
  }
}
export default withRouter(CamBI)
