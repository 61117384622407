import React from 'react'
import './PMS.scss'
import Modal from '@/components/modal'
import { withRouter } from 'react-router-dom'
class CamPMS extends React.Component {
  constructor(props) {
    super(props)
  }

  applicate = () => {
    Modal.ontrial({
      visible: true,
      platform: this.props.history.location.pathname.split('/')[2],
    })
  }

  render() {
    return (
      <div id="page-CamPMS">
        <div className="base luxy-el">
          <div className="title">CAM PMS 多维分析平台</div>
          <div className="con">
            CAM
            PMS多维分析平台依靠强大的IT技术支持，结合多维多源数据，借助深度立体量化分析引擎，为多元化商业场景下的各类决策提供系统化、平台化的数据及应用支撑，打造多功能、智能化分析平台。
          </div>
          <a className="applyFor" onClick={this.applicate}>
            申请试用
          </a>
          <div className="ct">
            {/* <div className="title1"><i></i>基础功能</div> */}
            <div className="ct1">
              <div className="left">
                <img
                  alt="快速检索数据"
                  title="快速检索数据"
                  src={require('./../../assets/imgs/CamPMS/pms_01.jpg')}
                />
                <div className="line1">快速检索数据</div>
                <div className="line2">
                  整合配置、价格、销量和促销力度等数据源实现数据之间的联合查询。
                </div>
              </div>
              <ul className="right">
                <li className="li01">
                  <div className="img img1">
                    <img
                      alt="灵活数据源"
                      title="灵活数据源"
                      src={require('./../../assets/imgs/CamPMS/pms1.png')}
                    />
                  </div>

                  <div className="txt txt1">
                    <div className="line1" data-scroll-reveal=" enter  right  over 0.4s">
                      灵活数据源
                    </div>
                    <div className="line2" data-scroll-reveal=" enter  right  over 0.66s">
                      自定义配置字段、灵活添加多套销量数据源、可设置不同的价格和销量数据源数据权限。
                    </div>
                  </div>
                </li>
                <li className="li02">
                  <div className="img img2">
                    <img
                      alt="服务各层级的专业人士"
                      title="服务各层级的专业人士"
                      src={require('./../../assets/imgs/CamPMS/pms2.png')}
                    />
                  </div>
                  <div className="txt txt2">
                    <div
                      className="line1"
                      data-scroll-reveal=" enter  right over 0.44s after 0.2s "
                    >
                      服务各层级的专业人士
                    </div>
                    <div
                      className="line2"
                      data-scroll-reveal=" enter  right over 0.77s after 0.2s "
                    >
                      进行新产品分析和规划，为不同层级的专业人士产品研发提供战略性信息支持。
                    </div>
                  </div>
                </li>
                <li className="li03">
                  <div className="img img3">
                    <img
                      alt="实时监控市场动态"
                      title="实时监控市场动态"
                      src={require('./../../assets/imgs/CamPMS/pms3.png')}
                    />
                  </div>
                  <div className="txt txt3">
                    <div
                      className="line1"
                      data-scroll-reveal=" enter  right   over 0.5s  after 0.2s"
                    >
                      实时监控市场动态
                    </div>
                    <div
                      className="line2"
                      data-scroll-reveal=" enter  right   over 0.77s  after 0.2s"
                    >
                      建立实时快速沟通市场渠道，结合反馈，高效分析行业动态。
                    </div>
                  </div>
                </li>
                <li className="li04">
                  <div className="img img4">
                    <img
                      alt="一键下载"
                      title="一键下载"
                      src={require('./../../assets/imgs/CamPMS/pms4.png')}
                    />
                  </div>
                  <div className="txt txt4">
                    <div className="line1" data-scroll-reveal=" enter  right  over .66s after .4s">
                      一键下载
                    </div>
                    <div className="line2" data-scroll-reveal=" enter  right  over .66s after .5s">
                      多项功能，专业报表、分析报告支持一键下载。
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="advantage luxy-el">
          {/* <div className="title1"><i></i>平台优势</div> */}
          <ul>
            <li data-scroll-reveal="  enter bottom  over 0.5s move 50px">
              <div className="img img1">
                <img
                  alt="灵活的权限管理"
                  title="灵活的权限管理"
                  src={require('./../../assets/imgs/CamPMS/pmsIcon1.png')}
                />
              </div>
              <div className="line1">灵活的权限管理</div>
            </li>
            <li data-scroll-reveal="  enter bottom  over 1s move 50px after .3s ">
              <div className="img img2">
                <img
                  alt="各模块相互转化"
                  title="各模块相互转化"
                  src={require('./../../assets/imgs/CamPMS/pmsIcon2.png')}
                />
              </div>
              <div className="line1">各模块相互转化</div>
            </li>
            <li data-scroll-reveal="  enter bottom  over 1.2s move 50px after .5s ">
              <div className="img img3">
                <img
                  alt="多样化表现形式"
                  title="多样化表现形式"
                  src={require('./../../assets/imgs/CamPMS/pmsIcon3.png')}
                />
              </div>
              <div className="line1">多样化表现形式</div>
            </li>
            <li data-scroll-reveal="  enter bottom  over 1.2s move 50px after .7s ">
              <div className="img img4">
                <img
                  alt="全面的历史数据"
                  title="全面的历史数据"
                  src={require('./../../assets/imgs/CamPMS/pmsIcon4.png')}
                />
              </div>
              <div className="line1">全面的历史数据</div>
            </li>
            <li data-scroll-reveal="  enter bottom  over 1.2s move 50px after .9s ">
              <div className="img img5">
                <img
                  alt="可扩展定制化开发"
                  title="可扩展定制化开发"
                  src={require('./../../assets/imgs/CamPMS/pmsIcon5.png')}
                />
              </div>
              <div className="line1">可扩展定制化开发</div>
            </li>
            <li data-scroll-reveal="  enter bottom  over 1.2s move 50px after 1.1s ">
              <div className="img img6">
                <img
                  alt="数据兼容性"
                  title="数据兼容性"
                  src={require('./../../assets/imgs/CamPMS/pmsIcon6.png')}
                />
              </div>
              <div className="line1">数据兼容性</div>
            </li>
            <li data-scroll-reveal="  enter bottom  over 1.2s move 50px after 1.3s ">
              <div className="img img7">
                <img
                  alt="专业化分析模块"
                  title="专业化分析模块"
                  src={require('./../../assets/imgs/CamPMS/pmsIcon7.png')}
                />
              </div>
              <div className="line1">专业化分析模块</div>
            </li>
          </ul>
        </div>
        <div className="bright luxy-el">
          <div>
            <div className="title1">平台亮点</div>
            <ul>
              <li data-scroll-reveal="  enter bottom  over 0.5s ">
                <img
                  alt="大数据支撑"
                  title="大数据支撑"
                  src={require('./../../assets/imgs/CamPMS/pmsI1.png')}
                />
                <div className="line1">大数据支撑</div>
                <div className="line2">
                  全面整合
                  <br />
                  汽车行业数据
                </div>
              </li>
              <li data-scroll-reveal="  enter bottom  over 0.7s after 0.5s ">
                <img
                  alt="多维度分析"
                  title="多维度分析"
                  src={require('./../../assets/imgs/CamPMS/pmsI2.png')}
                />
                <div className="line1">多维度分析</div>
                <div className="line2">
                  以车型为基础
                  <br />
                  进行不同维度间的
                  <br />
                  数据挖掘和关联
                </div>
              </li>
              <li data-scroll-reveal="  enter bottom  over 1s  after .5s ">
                <img
                  alt="可视化数据"
                  title="可视化数据"
                  src={require('./../../assets/imgs/CamPMS/pmsI3.png')}
                />
                <div className="line1">可视化数据</div>
                <div className="line2">
                  界面数据可视化
                  <br />
                  高效直观、精准分析
                </div>
              </li>
              <li data-scroll-reveal="  enter bottom  over 0.8s  after 0.5s ">
                <img
                  alt="IT技术支持"
                  title="IT技术支持"
                  src={require('./../../assets/imgs/CamPMS/pmsI4.png')}
                />
                <div className="line1">IT技术支持</div>
                <div className="line2">
                  先进IT技术运算
                  <br />
                  庞大且复杂的行业数据
                  <br />
                  呈现专业结构
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(CamPMS)
