import React from 'react'
import './snatched.scss'
import { Modal } from 'antd'

class ResearchSnatched extends React.Component {
  constructor() {
    super()
    this.state = {
      visible: false,
      html: '',
      title: '',
    }
  }
  render() {
    const { visible, title, html } = this.state
    return (
      <div id="page-snatched">
        <div className="title">智能网联“3+2”产品体系</div>
        <div className="center">
          <div className="res-variety">
            <div className="var">
              <p>行业研究</p>
            </div>
            <div className="var">
              <p>产品研究</p>
            </div>
            <div className="var">
              <p>用户研究</p>
            </div>
          </div>
          <div className="var-item">
            <div className="var-list">
              <ul>
                <li>政策&法规解读</li>
                <li>企业战略对标研究</li>
                <li>新产品/新技术跟踪</li>
                <li>行业专家深度解读</li>
                <li>新科技发展趋势预测</li>
              </ul>
            </div>
            <div className="var-list">
              <ul>
                <li>配置对比</li>
                <li>功能解析和UX评测</li>
                <li>OTA升级前后对比分析</li>
                <li>装备率数据检测</li>
                <li>配置感知价值和PVA</li>
              </ul>
            </div>
            <div className="var-list">
              <ul>
                <li>用户画像</li>
                <li>用车场景库&痛点和需求</li>
                <li>产品造型诊断&配置研究</li>
                <li>可用性测试</li>
                <li>产品定义共创</li>
              </ul>
            </div>
          </div>

          <div className="res-title">
            <p>智能网联汽车配置数据库</p>
          </div>
          <div className="res-title">
            <p>智能网联可视化系统（视频库）</p>
          </div>
        </div>

        <Modal
          wrapClassName={'common-modal product-modal'}
          centered
          title={title}
          visible={visible}
          footer={null}
          onCancel={() => this.setState({ visible: false })}
        >
          {html}
        </Modal>
      </div>
    )
  }
}
export default ResearchSnatched
