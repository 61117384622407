import React from 'react'
import './example2.scss'
import BannerIP from './../../components/bannerInsidePages/bannerInsidePages'

class Example2 extends React.Component {
  render() {
    document.title = '成功案例二'
    return (
      <div id="page-example2">
        <BannerIP type={'example2'} />
        <div className="ct">
          <div className="title">案例背景</div>
          <div className="con">
            某企业需研究自主高端品牌成功经验及4P策略，并期望针对自身品牌提供相应改进提升建议。
          </div>
          <div className="md1">
            <div className="left">
              <div className="tit">何为4P策略？</div>
              <ul>
                <li>4P策略即为：</li>
                <li>
                  Product（产品组合）—— 向目标市场提供各种适合消费者需求产品的方式来实现其营销目标；
                </li>
                <li>
                  Price（产品定价）—— 以按照市场规律制定价格和变动价格等方式来实现其营销目标；
                </li>
                <li>
                  Place（渠道规划）—— 合理地选择分销渠道和组织商品实体流通的方式来实现其营销目标；
                </li>
                <li>
                  Promotion（营销策略）——
                  利用各种信息传播手段刺激消费者购买欲望，促进产品销售的方式来实现其营销目。
                </li>
              </ul>
            </div>
            <div className="right">
              <img
                alt="案列二图片"
                title="案列二图片"
                src={require('./../../assets/imgs/Research/intelligence/sqfx.png')}
              />
            </div>
          </div>

          <div className="exa-div">
            <div className="title2">解决方案</div>
            <div className="md2">
              <div>
                <div className="pic">
                  <img
                    alt=""
                    title=""
                    width=""
                    height=""
                    src={require('imgs/example2/alIcon.png')}
                  />
                  <div
                    className="inner-text1"
                    data-scroll-reveal=" enter right  over .4s  after .3s"
                  >
                    深入梳理
                    <br />
                    需求方向
                  </div>
                  <div className="d1">
                    <div className="step">Step1：</div>
                    <div className="con_text">
                      针对车企提出的自主高
                      <br />
                      端品牌专项研究需求，
                      <br />
                      经CAM专项研究小组审
                      <br />
                      议，确定研究方向和细
                      <br />
                      化需求点。
                    </div>
                  </div>
                  <div
                    className="inner-text2"
                    data-scroll-reveal=" enter right  over .4s  after .3s"
                  >
                    专门制定
                    <br />
                    项目计划
                  </div>
                  <div className="d2">
                    <div className="step">Step2：</div>
                    <div className="con_text">
                      针对客户提出的研究需
                      <br />
                      求，专项制定研究方案
                      <br />
                      提纲和执行时间计划，
                      <br />
                      并由CAM专项研究小组
                      <br />
                      负责执行。
                    </div>
                  </div>
                  <div
                    className="inner-text3"
                    data-scroll-reveal=" enter right  over .4s  after .3s"
                  >
                    调研整理
                    <br />
                    需求材料
                  </div>
                  <div className="d3">
                    <div className="step">Step3：</div>
                    <div className="con_text">
                      除收集整理公开信息和
                      <br />
                      资料，针对特定性需求
                      <br />
                      组织市场调研和深访获
                      <br />
                      取更有深度的研究资
                      <br />
                      料。
                    </div>
                  </div>
                  <div
                    className="inner-text4"
                    data-scroll-reveal=" enter right  over .4s  after .3s"
                  >
                    专家研讨
                    <br />
                    策略建议
                  </div>
                  <div className="d4">
                    <div className="step">Step4：</div>
                    <div className="con_text">
                      根据汇总整理的研究材
                      <br />
                      料出具专项研究报告，
                      <br />
                      并与业内专家研讨，提
                      <br />
                      供针对性的应对策略建
                      <br />
                      议供参考。
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="title3">案例总结</div>
          <div className={'summary-div'}>
            <ul className="summary">
              <li data-scroll-reveal=" enter right  over .5s move 200px">
                深入剖析自主高端品牌的发展历程及成功秘诀，得出“力推爆款产品的重要性” 结论
              </li>
              <li data-scroll-reveal=" enter right  over .66s move 200px after 0.4s">
                梳理自主高端品牌的4P营销策略，得出“除拥有爆款产品外，需敢于差异化创新”结论
              </li>
              <li data-scroll-reveal=" enter right  over .77s move 200px after 0.8s">
                运用对标分析，学习借鉴其他竞品或竟企的成功做法，找出短板，改进或提升本品牌
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default Example2
