import React from 'react'
import { Link } from 'react-router-dom'
import './systemNav.scss'

export default function (props) {
  const url = location.pathname

  return (
    <div id="systemNav">
      {props.navs.map((m, i) =>
        m.url == url ? (
          <a key={'sn_' + i} style={i == 0 ? { borderLeftWidth: '0px' } : {}} className="active">
            <div data-scroll-reveal="  enter top  over 0.7s ">{m.name}</div>
          </a>
        ) : (
          <Link key={'sn_' + i} to={m.url} style={i == 0 ? { borderLeftWidth: '0px' } : {}}>
            {m.name}
          </Link>
        )
      )}
    </div>
  )
}
