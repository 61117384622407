import React from "react";

class PolicyResearch extends React.Component {
    componentDidMount() {
        $(function () {
            $('#policyResearchBanner').terseBanner({
                adaptive: true,
                auto: 4000,
                arrow: true,
            });
        });
    }

    componentWillUnmount() {
        let banner = $('#policyResearchBanner').data('terseBanner');
        if (banner.stop)
            banner.stop();//清除定时器
        $('#policyResearchBanner').data('terseBanner', undefined);
    }


    render() {
        return <div id={"policyResearch"} className={"tabContent"}>
            <div id="policyResearchBanner" className={"banner"}>
                <ul>
                    <li className="mb_item">
                        <img alt="行业政策研究一" title="行业政策研究一" data-src={require("../../../assets/imgs/Research/policyResearch/cam_sq60.jpg")}/>
                    </li>
                    <li className="mb_item">
                        <img alt="行业政策研究二" title="行业政策研究二" data-src={require("../../../assets/imgs/Research/policyResearch/cam_sq61.jpg")}/>
                    </li>
                    <li className="mb_item">
                        <img alt="行业政策研究三" title="行业政策研究三" data-src={require("../../../assets/imgs/Research/policyResearch/cam_sq62.jpg")}/>
                    </li>
                    <li className="mb_item">
                        <img alt="行业政策研究四" title="行业政策研究四" data-src={require("../../../assets/imgs/Research/policyResearch/cam_sq63.jpg")}/>
                    </li>
                    <li className="mb_item">
                        <img alt="行业政策研究五" title="行业政策研究五" data-src={require("../../../assets/imgs/Research/policyResearch/cam_sq64.jpg")}/>
                    </li>
                    <li className="mb_item">
                        <img alt="行业政策研究六" title="行业政策研究六" data-src={require("../../../assets/imgs/Research/policyResearch/cam_sq65.jpg")}/>
                    </li>
                </ul>
            </div>
        </div>
    }
}

export default PolicyResearch;