import React from "react";
class AtomobileTrends extends React.Component {
    componentDidMount() {
        $(function () {
            $('#atomobileTrendsBanner').terseBanner({
                adaptive: true,
                auto: 4000,
                arrow: true,
            });
        });
    }

    componentWillUnmount() {
        let banner = $('#atomobileTrendsBanner').data('terseBanner');
        if (banner.stop)
            banner.stop();//清除定时器
        $('#atomobileTrendsBanner').data('terseBanner', undefined);
    }


    render() {
        return <div id={"atomobileTrends"} className={"tabContent"}>
            <div id="atomobileTrendsBanner" className={"banner"}>
                <ul>
                    <li className="mb_item">
                        <img alt="车企重要动向一" title="车企重要动向一" data-src={require("../../../assets/imgs/Research/atomobileTrends/hy5_01.jpg")}/>
                    </li>
                    <li className="mb_item">
                        <img alt="车企重要动向二" title="车企重要动向二" data-src={require("../../../assets/imgs/Research/atomobileTrends/hy5_02.jpg")}/>
                    </li>
                    <li className="mb_item">
                        <img alt="车企重要动向三" title="车企重要动向三" data-src={require("../../../assets/imgs/Research/atomobileTrends/hy5_03.jpg")}/>
                    </li>
                </ul>
            </div>
        </div>
    }
}

export default AtomobileTrends;