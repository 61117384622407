import React from "react";

class Activity extends React.Component {
    componentDidMount() {
        $(function () {
            $('#activityBanner').terseBanner({
                adaptive: true,
                auto: 4000,
                arrow: true,
            });
        });
    }


    render() {
        return <div id={"activity"}>
            <div id="activityBanner" className={"banner custom"}>
                <ul>
                    <li className="mb_item">
                        <img alt="店头活动监测分析一" title="店头活动监测分析一" data-src={require("../../../assets/imgs/Research/intelligence/cam_sq10.jpg")}/>
                    </li>
                    <li className="mb_item">
                        <img alt="店头活动监测分析二" title="店头活动监测分析二" data-src={require("../../../assets/imgs/Research/intelligence/cam_sq11.jpg")}/>
                    </li>
                </ul>
            </div>
        </div>
    }
}

export default Activity;