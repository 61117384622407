import React from 'react'
import './product.scss'
import ScResearch from '../../components/research/product/scResearch'
import CpResearch from '../../components/research/product/cpResearch'
import SmResearch from '../../components/research/product/smResearch'
import JpBriefing from '../../components/research/product/jpBriefing'
import JpAnalysis from '../../components/research/product/jpAnalysis'
import JpTrack from '../../components/research/product/jpTrack'
import { Modal } from 'antd'

class ResearchProduct extends React.Component {
  constructor() {
    super()
    this.state = {
      visible: false,
      html: '',
      title: '',
    }
  }
  showModal = key => {
    let html, title
    switch (key) {
      case 'scResearch':
        html = <ScResearch />
        title = '细分市场研究报告'
        break
      case 'cpResearch':
        html = <CpResearch />
        title = '企业产品型谱研究报告'
        break
      case 'smResearch':
        html = <SmResearch />
        title = '新技术月报报告'
        break
      case 'jpBriefing':
        html = <JpBriefing />
        title = '竞品上市简报'
        break
      case 'jpAnalysis':
        html = <JpAnalysis />
        title = '竞品上市分析报告'
        break
      case 'jpTrack':
        html = <JpTrack />
        title = '竞品上市跟踪报告'
        break
    }
    this.setState({ visible: true, html, title })
  }

  render() {
    const { visible, title, html } = this.state
    return (
      <div id="page-product">
        <div className="title">产品全生命周期服务一览表</div>
        <table>
          <thead>
            <tr>
              <th></th>
              <th colSpan={3}>
                <span>开发期</span>
                <span>导入期</span>
              </th>
              <th>成长期</th>
              <th>成熟期</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>阶段</td>
              <td className={'custom-width'}>产品规划阶段</td>
              <td className={'custom-width'}>产品设计阶段</td>
              <td className={'custom-width1'}>产品上市准备阶段</td>
              <td className={'custom-width'} colSpan={2}>
                产品生命周期维护阶段
              </td>
            </tr>
            <tr>
              <td>重点</td>
              <td>产品规划</td>
              <td>趋势研究</td>
              <td>竞争力研究</td>
              <td colSpan={2}>市场监测/提高竞争力</td>
            </tr>
            <tr>
              <td>目标</td>
              <td>
                <ul>
                  <li>
                    <p>细分市场研究报告</p>
                    <p onClick={() => this.showModal('scResearch')}>
                      <span>查看样例 > </span>
                      {/* <img
                        alt="查看样例"
                        title="查看样例"
                        src={require('./../../assets/imgs/Research/more.png')}
                      /> */}
                    </p>
                  </li>
                  <li>
                    <p>产品型谱研究报告</p>
                    <p onClick={() => this.showModal('cpResearch')}>
                      <span>查看样例 > </span>
                      {/* <img
                        alt="查看样例"
                        title="查看样例"
                        src={require('./../../assets/imgs/Research/more.png')}
                      /> */}
                    </p>
                  </li>
                  <li>
                    <p>新技术月报报告</p>
                    <p onClick={() => this.showModal('smResearch')}>
                      <span>查看样例 > </span>
                      {/* <img
                        alt="查看样例"
                        title="查看样例"
                        src={require('./../../assets/imgs/Research/more.png')}
                      /> */}
                    </p>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>车展报告</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>新产品日历</li>
                  <li>新车谍报</li>
                  <li>预上市新品研究</li>
                </ul>
              </td>
              <td colSpan={2}>
                <ul>
                  <li>
                    <p>竞品上市简报</p>
                    {/*<p onClick={() => this.showModal("jpBriefing")}>*/}
                    {/*<span>查看样例</span>*/}
                    {/*<img src={require("./../../assets/imgs/Research/more.png")} />*/}
                    {/*</p>*/}
                  </li>
                  <li>
                    <p>竞品上市分析报告</p>
                    <p onClick={() => this.showModal('jpAnalysis')}>
                      <span>查看样例 > </span>

                      {/* <img
                        alt="查看样例"
                        title="查看样例"
                        src={require('./../../assets/imgs/Research/more.png')}
                      /> */}
                    </p>
                  </li>
                  <li>
                    <p>竞品上市跟踪报告</p>
                    <p onClick={() => this.showModal('jpTrack')}>
                      <span>查看样例 > </span>
                      {/* <img
                        alt="查看样例"
                        title="查看样例"
                        src={require('./../../assets/imgs/Research/more.png')}
                      /> */}
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <Modal
          wrapClassName={'common-modal product-modal'}
          centered
          title={title}
          visible={visible}
          footer={null}
          onCancel={() => this.setState({ visible: false })}
        >
          {html}
        </Modal>
      </div>
    )
  }
}
export default ResearchProduct
