import React from 'react'
import './bazaar.scss'
import { Tabs } from 'antd'

class ResearchBazaar extends React.Component {
  constructor() {
    super()
    this.state = {
      index: null,
    }
  }
  componentDidMount() {
    this.setState({
      index: 0,
    })
  }

  handleTab = index => {
    this.setState({ index })
  }
  render() {
    const { index } = this.state
    return (
      <div id="page-bazaar">
        <div className="base luxy-el">
          <div>
            <div className="title">CAM市场预测建模架构</div>
            <div className="chart">
              <img
                alt="CAM市场预测建模架构"
                title="CAM市场预测建模架构"
                src={require('./../../assets/imgs/Research/archMod.png')}
              />
            </div>
          </div>
          <div className="md2">
            <div className="title2">市场预测样例展示</div>
            <div className={'tab'}>
              <div className="item">
                <div className="list">
                  <img
                    alt="市场预测样例展示"
                    title="市场预测样例展示"
                    src={require('./../../assets/imgs/Research/cam_sq41.jpg')}
                  />
                  <p>下阶段销量预测</p>
                </div>
                <div className="list">
                  <img
                    alt="市场预测样例展示"
                    title="市场预测样例展示"
                    src={require('./../../assets/imgs/Research/cam_sq42.jpg')}
                  />
                  <p>车身级别预测</p>
                </div>
                <div className="list">
                  <img
                    alt="市场预测样例展示"
                    title="市场预测样例展示"
                    src={require('./../../assets/imgs/Research/cam_sq43.jpg')}
                  />
                  <p>细分市场预测</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default ResearchBazaar
