import React from "react";
import "./404.scss";
import { withRouter } from "react-router-dom";
class NoMatch extends React.Component{
    componentDidMount() {
        $("#top,#foot,#sideBar").hide();
    }
    componentWillUnmount() {
            $("#top,#foot,#sideBar").show();
    }

    goBack = ()=> {
        this.props.history.goBack();
    };
    render(){
        return(
                <div id={"noMatch"}>
                    <div>
                        <h1>
                            <img src={require("./../../assets/imgs/404/404.png")} width="100%" alt=""/>
                        </h1>
                        <button onClick={this.goBack}>Go back</button>
                    </div>
                </div>
            )
    }
}  
export default withRouter(NoMatch);