import "babel-polyfill";
import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter,
  Switch,
  Route,
  withRouter,
  Router,
  Redirect
} from "react-router-dom";
import navs from "./config/navs";
import "css-loader/lib/css-base"; //懒加载样式

import NoMatch from "./components/common/404";
import Preloading from "./components/common/Preloading";
import "./assets/css/site.scss";

import Top from "./components/top/top";
import SideBar from "./components/sideBar/sideBar";
import Foot from "./components/foot/foot";

import { ConfigProvider } from "antd";
import zhCN from "antd/es/locale/zh_CN";

class Main extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    /*if (!/msie [6|7|8|9]/i.test(navigator.userAgent)) {
      luxy.init({
        wrapper: "#luxy",
        targets: ".luxy-el",
        wrapperSpeed: 0.08
      });
    }*/
    //页面宽度变化，改变字体大小
    let $html = $("html");
    const width = 1904;
    let st;
    let resize = () => {
      clearTimeout(st);
      st = setTimeout(() => {
        let size = 22;
        $html.css({ fontSize: ($html.width() / width) * size });
      }, 50);
    };
    $(window).resize(resize);
    resize();
  }

  render() {
    let navArr = [];
    navs.map((m, index) => {
      if (!navArr.includes(m.url)) {
        navArr.push(m);
      }
      if (m.childs)
        m.childs.map(m1 => {
          if (!navArr.includes(m1.url)) {
            navArr.push(m1);
          }
        });
    });

    return (
      <ConfigProvider locale={zhCN}>
        <BrowserRouter>
          <Fragment>
            <Top />
            <SideBar />
            <div id="luxy">
              <Switch>
                {navArr.map((m, index) => (
                  <Route
                    key={"route_" + index}
                    exact
                    path={m.routePath || m.url}
                    component={m.component}
                  />
                ))}
                <Route key={"NoMatch"} component={NoMatch} path="/404" />
                <Redirect to="/404" />
              </Switch>
              <Foot />
            </div>
            <Preloading />
          </Fragment>
        </BrowserRouter>
      </ConfigProvider>
    );
  }
}

ReactDOM.render(<Main />, document.getElementById("mainCt"));
