import React, {Component} from 'react';
import {Form, Button, Checkbox, Modal} from 'antd';
import './modal.scss';

class FunctionsModal extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {functions, modalVisible, setModalVisible, platformName} = this.props;
        return (
                <Modal
                    centered
                    wrapClassName="common-modal functions-modal"
                    maskClosable={false}
                    title="选择试用功能"
                    visible={modalVisible}
                    onCancel={() => setModalVisible(false)}
                    width={platformName === "bi" ? 800 : 600}
                    onOk={() => {
                        this.props.form.validateFields((err, {items}) => {
                            if (!err) {
                                setModalVisible(false, true, items.join(","))
                            }
                        })
                    }}
                >
                    <Form>
                        <Form.Item>
                            {getFieldDecorator('items')(
                                <Checkbox.Group>
                                    {functions.map(v => (
                                       /* v.children ?
                                            <div key={v.value}>
                                                <div className={"name"}>{v.label}</div>
                                                <div className={"children"}>
                                                    {v.children.map(v1 => (
                                                        <div key={v1.value} className={"items"} title={v1.label}>
                                                            <Checkbox
                                                                value={v1.value}
                                                            >{v1.label}
                                                            </Checkbox>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div> :*/
                                            <div key={v.value} className={"items"} title={v.label}>
                                                <Checkbox
                                                    value={v.value}
                                                >{v.label}
                                                </Checkbox>
                                            </div>
                                    ))}
                                </Checkbox.Group>
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
        );
    }
}

export default Form.create()(FunctionsModal);