import React  from "react";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import "./_layout.scss";
import BannerIP from "../../components/bannerInsidePages/bannerInsidePages";
import navs from "./../../config/navs";
import SystemNav from "./navs/systemNav";
import {
  TransitionGroup,
  CSSTransition,
  SwitchTransition
} from "react-transition-group";

export default function SysLayout({ match }) {
  document.title = "CAM系统";
  const nav_childs = navs.find(m => m.routePath === "/System/:type").childs;
  const type = match.params.type;
  if (nav_childs.every(v => !new RegExp(type, "i").test(v.url)))
    return <Redirect to="/404" />;
  return (
    <div id="page-sys">
      <BannerIP type={"system"} />
      <SystemNav navs={nav_childs} />

      <div className="slide-container slide-in-appear">
        {nav_childs.map(({ component: Component, url }) => {
          return (
            <Route key={"sub_" + url} exact path={url}>
              {({ match }) => (
                <CSSTransition
                  key={"csst_" + url}
                  in={match != null}
                  timeout={600}
                  classNames="slide"
                  unmountOnExit
                >
                  <Component />
                </CSSTransition>
              )}
            </Route>
          );
        })}
      </div>
    </div>
  );
}
