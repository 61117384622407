import React from 'react';
import ReactDOM from 'react-dom';
import SqsyModal from './sqsyModal';

const show = (props) => {
    let component = null;
    const div = document.createElement('div');
    document.body.appendChild(div);
    const onClose = () => {
        ReactDOM.unmountComponentAtNode(div);
        document.body.removeChild(div);
        if (typeof props.onClose === 'function') {
            props.onClose();
        }
    };
    ReactDOM.render(
       <SqsyModal onClose={onClose}
                         {...props}
                         ref={c => component = c}
            >{props.content}</SqsyModal>,
        div
    );
    return () => component.close();
};

const ModalBox = {};

ModalBox.ontrial = (props) => show({
    ...props,
    type: 'ontrial'
});

export default ModalBox;