import React from 'react'
import BannerIP from './../../components/bannerInsidePages/bannerInsidePages'
import './joinUs.scss'

class joinUs extends React.Component {
  handleChange = e => {
    let $self = $(e.currentTarget)
    let parentNode = $self.parent('.li')
    let siblingNode = parentNode.siblings('.li'),
      childNode = parentNode.find('.d02')
    siblingNode.find('.d02').slideUp(400)
    if (childNode.css('display') === 'block') {
      childNode.slideUp(400)
    } else {
      childNode.slideDown(400)
    }
  }

  render() {
    document.title = '加入我们'
    return (
      <div id="joinUS">
        <BannerIP type={'joinUs'} />
        <div className="base luxy-el">
          <div className="title">简历投递邮箱</div>
          <div className="yx">xueg@chinaautomarket.com</div>
          {/* <div className="yx">上海：jiaqiany@chinaautomarket.com</div> */}
          <ul>
            <li className="li li01">
              <div className="d01" onClick={e => this.handleChange(e)}>
                <div className="qz"></div>
                <div className="txt1">销售经理&nbsp;&nbsp;(上海)</div>
                <div className="txt2">本科/2年以上</div>
                <div className="txt3">薪资面议</div>
              </div>
              <div className="d02">
                <div className="tit">岗位描述：</div>
                <div className="con">1、销售及客户开发工作；</div>
                <div className="con">
                  2、负责市场的开拓和维护，进行客户提案、竞标谈判，完成销售指标；
                </div>
                <div className="con">3、针对客户需求进行项目规划，设计项目方案；</div>
                <div className="con">
                  4、及时反馈客户意见，把握市场动向，规划新的业务方向，与公司内部各部门密切沟通；
                </div>
                <div className="con">5、公司及部门交代的其他事务。</div>
                <div className="tit">任职要求：</div>
                <div className="con">
                  1、本科以上学历，2年以上汽车相关行业市场或者销售经验优先；
                </div>
                <div className="con">2、了解国内OEM厂商及汽车零部件公司的市场需求优先；</div>
                <div className="con">3、良好的工作热情和团队合作精神；</div>
                <div className="con">4、流利的英文能力优先；</div>
                <div className="con">5、PPT熟练者优先。</div>
              </div>
            </li>
            <li className="li li02">
              <div className="d01" onClick={e => this.handleChange(e)}>
                <div className="qz"></div>
                <div className="txt1">销售助理&nbsp;&nbsp;(上海)</div>
                <div className="txt2">本科/2年以上</div>
                <div className="txt3">薪资面议</div>
              </div>
              <div className="d02">
                <div className="tit">岗位描述：</div>
                <div className="con">
                  1、负责公司销售合同及其他营销文件资料的管理、归类、整理、建档和保管工作；
                </div>
                <div className="con">
                  2、负责各类销售指标的月度、季度、年度统计报表和报告的制作、编写，并随时答复领导对销售动态情况的质询；
                </div>
                <div className="con">
                  3、负责收集、整理、归纳市场行情、价格，以及新产品、替代品、客源等信息资料，提出分析报告，为部门业务人员、领导决策提供参考；
                </div>
                <div className="con">4、协助部门经理做好部内内务、各种部内会议的记录等工作；</div>
                <div className="con">
                  5、逐步推广使用电脑信息系统处理营销资料，妥善保管电脑资料，不泄露销售秘密；
                </div>
                <div className="con">6、完成营销部部长临时交办的其他任务。</div>
                <div className="tit">任职要求：</div>
                <div className="con">1、专科或本科以上学历，行政或文职经验优先；</div>
                <div className="con">2、良好的工作热情和团队合作精神；</div>
                <div className="con">3、英语专业八级及口语水平优秀者优先；</div>
                <div className="con">4、PPT熟练者优先。</div>
              </div>
            </li>
            <li className="li li03">
              <div className="d01" onClick={e => this.handleChange(e)}>
                <div className="qz"></div>
                <div className="txt1">汽车英语翻译&nbsp;&nbsp;(上海)</div>
                <div className="txt2">本科/2年以上</div>
                <div className="txt3">薪资面议</div>
              </div>
              <div className="d02">
                <div className="tit">岗位描述：</div>
                <div className="con">1、负责公司英语报告、文案撰写工作；</div>
                <div className="con">2、户口不限，公司将办理保险等各项事宜；</div>
                <div className="con">3、公司为个人提供良好的发展前景，优厚的福利待遇！</div>
                <div className="con">4、学习机会多，来信请注明期望薪资，合则约见。</div>
                <div className="tit">任职要求：</div>
                <div className="con">
                  1、正规院校英语、汽车工程专业本科或以上学历，专业英语八级以上(口语熟练且有多年翻译工作经验可放宽到六级)优先；
                </div>
                <div className="con">2、一年以上汽车专业翻译工作经验，笔译流利；</div>
                <div className="con">3、责任心强，综合素质优秀。</div>
              </div>
            </li>
            <li className="li li04">
              <div className="d01" onClick={e => this.handleChange(e)}>
                <div className="qz"></div>
                <div className="txt1">汽车产品研究员&nbsp;&nbsp;(无锡)</div>
                <div className="txt2">本科/2年以上</div>
                <div className="txt3">薪资面议</div>
              </div>
              <div className="d02">
                <div className="tit">岗位描述：</div>
                <div className="con">
                  1、在（乘用车）产品生命周期的各阶段，分析产品的卖点、基本参数、装备配置、造型设计、市场定位及产品策略；
                </div>
                <div className="con">
                  2、跟踪汽车产业的新技术动态（包括但不限于技术合作、技术规划、技术开发、技术应用等），撰写新技术跟踪报告；
                </div>
                <div className="con">
                  3、作为车展研究团队的一员，挖掘国内外车展的亮点，并提炼趋势性观点；
                </div>
                <div className="con">4、参与其它产品营销类报告的撰写。</div>
                <div className="tit">任职要求：</div>
                <div className="con">1、全日制专科院校毕业，理工科佳；</div>
                <div className="con">
                  2、精通 Microsoft Powerpoint、Excel办公软件，对Photoshop的基本操作有一定了解；
                </div>
                <div className="con">
                  3、对汽车行业有一定的了解，熟悉各大主流汽车企业、汽车品牌的基本情况；
                </div>
                <div className="con">
                  4、对乘用车产品有一定的了解，熟知主流乘用车产品的细分市场及其主要竞品；
                </div>
                <div className="con">
                  5、自我驱动，无需监督也能自行妥善安排并完成本职工作，结果导向；
                </div>
                <div className="con">6、工作态度严谨，关注细节，能从细节中提炼出有价值的洞察。</div>
                <div className="tit">任职基本素质：</div>
                <div className="con">1.对卓越和个人发展有坚持；</div>
                <div className="con">2.出色的学术能力和分析能力；</div>
                <div className="con">3.富有好奇心，乐于思考，充满干劲；</div>
                <div className="con">4.对商业真正感兴趣，喜欢发现问题、解决问题；</div>
                <div className="con">5.能用语言及PPT形象地表达复杂的技术及商业概念。</div>
              </div>
            </li>
            <li className="li li05">
              <div className="d01" onClick={e => this.handleChange(e)}>
                <div className="qz"></div>
                <div className="txt1">汽车装备研究员&nbsp;&nbsp;(上海)</div>
                <div className="txt2">本科/2年以上</div>
                <div className="txt3">薪资面议</div>
              </div>
              <div className="d02">
                <div className="tit">岗位描述：</div>
                <div className="con">1、汽车产品、装备信息调研；</div>
                <div className="con">2、搜集、归类整理汽车信息；</div>
                <div className="con">3、研究车型的外观、内饰造型和装备等变化，并撰写相关报告；</div>
                <div className="con">4、其他汽车产品相关报告的撰写。</div>
                <div className="tit">任职要求：</div>
                <div className="con">1、汽车相关专业大专及以上学历；</div>
                <div className="con">
                  2、熟练操作计算机及各类办公软件( Word, Excel, Power point ...)；
                </div>
                <div className="con">3、具备缜密的逻辑思维能力和分析能力；</div>
                <div className="con">
                  4、具备较强的理解能力和接受能力，能快速学习和吸收新的知识；
                </div>
                <div className="con">5、具备优秀的沟通能力和人际交往能力；</div>
                <div className="con">6、具备良好的团队精神，能够与他人合作完成工作；</div>
                <div className="con">7、要求积极上进，认真负责，踏实勤恳。</div>
              </div>
            </li>
            <li className="li li06">
              <div className="d01" onClick={e => this.handleChange(e)}>
                <div className="qz"></div>
                <div className="txt1">
                  数据分析助理&nbsp;&nbsp;(汽车数据研究员)&nbsp;&nbsp;(无锡)
                </div>
                <div className="txt2">本科/2年以上</div>
                <div className="txt3">薪资面议</div>
              </div>
              <div className="d02">
                <div className="tit">岗位描述：</div>
                <div className="con">
                  1、汽车市场相关数据清洗整合：产销数据，库存数据，经销商网点数据等；
                </div>
                <div className="con">
                  2、配合项目经理完成项目相关的工作(信息搜集，数据清洗，报告制作)及临时需求；
                </div>
                <div className="con">
                  3、熟悉所负责项目的内容和执行计划，根据项目需求，制定完善的项目维护流程；
                </div>
                <div className="con">
                  4、日常的汽车行业数据周报、月报制作整理。独立承担部分信息数据整理分析工作；
                </div>
                <div className="con">5、负责为公司各部门提供基础数据查询和分析处理的支持；</div>
                <div className="con">6、按照领导要求，独立完成专项数据处理分析工作；</div>
                <div className="con">7、严格遵守数据的保密规定；</div>
                <div className="con">8、及时完成上级安排的其他工作。</div>
                <div className="tit">任职要求：</div>
                <div className="con">
                  1、大专及以上学历，对汽车行业及数据分析感兴趣，统计学和数学系相关专业优先；
                </div>
                <div className="con">2、有汽车行业工作经验/市场分析经验者优先；</div>
                <div className="con">
                  3、精通office办公软件，较强的excel数据处理能力，熟悉SQL，SPSS等分析软件优先；
                </div>
                <div className="con">
                  4、PPT熟练者优先有较强的抗压能力、逻辑能力以及团队沟通能力；
                </div>
                <div className="con">5、心态沉稳踏实，细致认真，有较强的求知欲与接受能力。</div>
              </div>
            </li>
            <li className="li li09">
              <div className="d01" onClick={e => this.handleChange(e)}>
                <div className="qz"></div>
                <div className="txt1">
                  数据分析助理&nbsp;&nbsp;(汽车数据研究员)&nbsp;&nbsp;(上海)
                </div>
                <div className="txt2">本科/2年以上</div>
                <div className="txt3">薪资面议</div>
              </div>
              <div className="d02">
                <div className="tit">岗位描述：</div>
                <div className="con">1、负责各种信息的收集、归纳、整理以及发布；</div>
                <div className="con">2、负责汽车市场相关数据清洗整合，报表制作；</div>
                <div className="con">
                  3、配合项目经理完成项目相关的工作(信息搜集，数据清洗，报告制作)及临时需求；
                </div>
                <div className="con">4、完成上级安排的其他工作。</div>
                <div className="tit">任职要求：</div>
                <div className="con">
                  1、本科及以上学历，对汽车行业及数据分析感兴趣，统计学和数学系相关专业优先；
                </div>
                <div className="con">
                  2、有汽车行业工作经验/市场分析经验者优先，优秀毕业生也可以；
                </div>
                <div className="con">3、精通office办公软件，较强的excel数据处理能力；</div>
                <div className="con">4、有较强的抗压能力、逻辑能力以及团队沟通能力；</div>
                <div className="con">5、心态沉稳踏实，细致认真，有较强的求知欲与接受能力。</div>
              </div>
            </li>
            <li className="li li07">
              <div className="d01" onClick={e => this.handleChange(e)}>
                <div className="qz"></div>
                <div className="txt1">汽车行业研究助理&nbsp;&nbsp;(无锡)</div>
                <div className="txt2">本科/2年以上</div>
                <div className="txt3">薪资面议</div>
              </div>
              <div className="d02">
                <div className="tit">岗位描述：</div>
                <div className="con">1、定期对宏观经济、汽车行业政策及企业动态进行跟踪；</div>
                <div className="con">
                  2、协助高级研究员、研究员完成制作宏观环境分析报告及专项研究报告；
                </div>
                <div className="con">3、协助完成其他行业研究专项报告及客户定制化需求；</div>
                <div className="con">
                  4、跟踪市场热点事件并能够进行专业性解析，从事件的来龙去脉来分析其对汽车行业及厂商可能带来的潜在影响，并提供应对策略建议等；
                </div>
                <div className="con">5、协助研究员或项目经理，参与其它临时需求报告的制作。</div>
                <div className="tit">任职要求：</div>
                <div className="con">
                  1、拥有经济、金融、管理等相关专业知识背景，拥有良好的逻辑分析能力和语言表达能力；
                </div>
                <div className="con">
                  2、对汽车行业拥有浓厚兴趣，对汽车行业发展现状及发展规律有所了解，能对行业政策作出解读；
                </div>
                <div className="con">3、善于学习和探索，保持高度的好奇心；</div>
                <div className="con">4、为人积极乐观，善于总结和纠正错误；</div>
                <div className="con">5、熟练应用Office软件；</div>
                <div className="con">6、工作认真、耐心、负责；</div>
                <div className="con">7、拥有良好的英语能力。</div>
              </div>
            </li>
            <li className="li li08">
              <div className="d01" onClick={e => this.handleChange(e)}>
                <div className="qz"></div>
                <div className="txt1">实习生&nbsp;&nbsp;(上海)</div>
                <div className="txt2">本科/经验不限</div>
                <div className="txt3">薪资面议</div>
              </div>
              <div className="d02">
                <div className="tit">岗位描述：</div>
                <div className="con">
                  1、在（乘用车）产品生命周期的各阶段，搜集整理产品基本参数、动力系统及配置信息；
                </div>
                <div className="con">2、跟踪汽车产业的新技术动态，撰写新技术跟踪报告；</div>
                <div className="con">3、编译及整理国内外的最新汽车技术资料；</div>
                <div className="tit">任职要求：</div>
                <div className="con">1、全日制本科院校毕业，理工科佳；</div>
                <div className="con">
                  2、熟练的英文读写能力，至少通过CET-6，及日语一级等有效成绩佳；
                </div>
                <div className="con">
                  3、熟练使用Microsoft Powerpoint、Excel、Photoshop办公软件；
                </div>
                <div className="con">
                  4、自我驱动，无需监督也能自行妥善安排并完成本职工作，结果导向；
                </div>
                <div className="con">5、工作态度严谨，关注细节；</div>
                <div className="con">6、每周需要保证四天工作时间。</div>
                <div className="tit">任职基本素质：</div>
                <div className="con">1、对卓越和个人发展有坚持；</div>
                <div className="con">2、出色的学术能力和分析能力；</div>
                <div className="con">3、富有好奇心，乐于思考，充满干劲；</div>
                <div className="con">4、对商业真正感兴趣，喜欢发现问题、解决问题；</div>
                <div className="con">5、能用语言及PPT形象地表达复杂的技术及商业概念。</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

export default joinUs
