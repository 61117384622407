import React from "react";

class ScResearch extends React.Component {
    componentDidMount() {
        $(function () {
            $('#scResearchBanner').terseBanner({
                adaptive: true,
                auto: 4000,
                arrow: true,
            });
        });
    }



    render() {
        return <div id={"scResearch"}>
            <div id="scResearchBanner" className={"banner custom"}>
                <ul>
                    <li className="mb_item">
                        <img alt="细分市场研究报告一" title="细分市场研究报告一" data-src={require("../../../assets/imgs/Research/industry/cam_sq26.jpg")}/>
                    </li>
                    <li className="mb_item">
                        <img alt="细分市场研究报告二" title="细分市场研究报告二" data-src={require("../../../assets/imgs/Research/industry/cam_sq27.jpg")}/>
                    </li>
                    <li className="mb_item">
                        <img alt="细分市场研究报告三" title="细分市场研究报告三" data-src={require("../../../assets/imgs/Research/industry/cam_sq28.jpg")}/>
                    </li>
                    <li className="mb_item">
                        <img alt="细分市场研究报告四" title="细分市场研究报告四" data-src={require("../../../assets/imgs/Research/industry/cam_sq29.jpg")}/>
                    </li>
                    <li className="mb_item">
                        <img alt="细分市场研究报告五" title="细分市场研究报告五" data-src={require("../../../assets/imgs/Research/industry/cam_sq30.jpg")}/>
                    </li>
                    <li className="mb_item">
                        <img alt="细分市场研究报告六" title="细分市场研究报告六" data-src={require("../../../assets/imgs/Research/industry/cam_sq31.jpg")}/>
                    </li>
                </ul>
            </div>
        </div>
    }
}

export default ScResearch;