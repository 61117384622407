import React from "react";

class SmResearch extends React.Component {
    componentDidMount() {
        $(function () {
            $('#smResearchBanner').terseBanner();
        });
    }



    render() {
        return <div id={"smResearch"}>
            <div id="smResearchBanner" className={"banner custom"}>
                <ul>
                    <li className="mb_item">
                        <img alt="新技术月报报告" title="新技术月报报告" src={require("../../../assets/imgs/Research/industry/cam_sq34.jpg")}/>
                    </li>
                </ul>
            </div>
        </div>
    }
}

export default SmResearch;