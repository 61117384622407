//路由表
import Home from '../routes/home/home'

import System from '../routes/system/_layout'
import CamPMS from '../routes/system/PMS'
import CamAI from '../routes/system/AI'
import CamBI from '../routes/system/BI'
import CamInfo from '../routes/system/Info'
import CamNEV from '../routes/system/nev'

import Research from '../routes/research/_layout'
import Intelligence from '../routes/research/intelligence'
import Product from '../routes/research/product'
import Bazaar from '../routes/research/bazaar'
import Consult from '../routes/research/consult'
import Consumer from '../routes/research/consumer'
import Industry from '../routes/research/industry'
import Snatched from '../routes/research/snatched'

import CamData from '../routes/camData/camData'

import CamAPP from '../routes/camApp/camAPP'

import Example from '../routes/example/example'
import Example1 from '../routes/example/example1'
import Example2 from '../routes/example/example2'
import Aboutus from '../routes/aboutus/aboutus'
import JoinUs from '../routes/joinUs/joinUs'

// CAM知识库
import Repository from '../routes/repository/repository'

export default [
  {
    name: '首页',
    url: '/',
    component: Home,
  },
  {
    name: 'CAM数据',
    url: '/CamData',
    component: CamData,
    // ,childs: [
    //     { name: '产品', url: "/ServicePro" ,component: ServicePro},
    //     { name: '商情', url: "/ServiceBS" ,component: ServiceBS},
    //     { name: '销量', url: "/ServiceSale" ,component:ServiceSale},
    //    // { name: '行研', url: "/ServiceIndustry" , component:ServiceIndustry },
    //     { name: '平台', url: "/ServicePlatform" ,component: ServicePlatform}
    // ]
  },
  {
    name: 'CAM系统',
    url: '/System/CamPMS',
    routePath: '/System/:type',
    component: System,
    childs: [
      { name: 'CAM PMS', url: '/System/CamPMS', component: CamPMS },
      { name: 'CAM AI', url: '/System/CamAI', component: CamAI },
      { name: 'CAM BI', url: '/System/CamBI', component: CamBI },
      { name: 'CAM INFO', url: '/System/CamINFO', component: CamInfo },
      { name: 'CAM NEV', url: '/System/CamNEV', component: CamNEV },
    ],
  },
  {
    name: 'CAM研究',
    url: '/Research/Intelligence',
    routePath: '/Research/:type',
    component: Research,
    childs: [
      { name: '商情分析', url: '/Research/Intelligence', component: Intelligence },
      { name: '产品研究', url: '/Research/Product', component: Product },
      { name: '行业观察', url: '/Research/Industry', component: Industry },
      { name: '市场预测', url: '/Research/Bazaar', component: Bazaar },
      { name: '消费者调研', url: '/Research/Consumer', component: Consumer },
      { name: '专案咨询', url: '/Research/Consult', component: Consult },
      { name: '智能网联', url: '/Research/Snatched', component: Snatched },
    ],
  },

  {
    name: 'CAM移动端',
    url: '/CamAPP',
    component: CamAPP,
    // ,childs: [
    //     { name: '移动端', url: "/SolutionApp",component:SolutionApp },
    //     { name: '新能源', url: "/SolutionNEV", component:SolutionNEV}
    // ]
  },
  // {
  //   name: 'CAM知识库',
  //   url: '/Repository',
  //   component: Repository,
  // },
  {
    name: '成功案例',
    url: '/Example',
    component: Example,
    noShowChilds: true,
    childs: [
      { name: '案例一', url: '/Example1', component: Example1 },
      { name: '案例二', url: '/Example2', component: Example2 },
    ],
  },
  {
    name: '关于我们',
    url: '/Aboutus',
    component: Aboutus,
  },
  {
    name: '加入我们',
    url: '/JoinUs',
    component: JoinUs,
    show: false,
  },
]
