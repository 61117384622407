import React from 'react'
import './nev.scss'
import './PMS.scss'
import Modal from '@/components/modal'
import { withRouter } from 'react-router-dom'

class CamNEV extends React.Component {
  applicate = () => {
    Modal.ontrial({
      visible: true,
      platform: this.props.history.location.pathname.split('/')[2],
    })
  }
  render() {
    return (
      <div id="page-CamNEV">
        <div className="base luxy-el">
          <div className="title">CAM NEV 新能源平台</div>
          <div className="con">
            CAM
            NEV新能源平台将量价数据、三电数据、充电桩数据及行业重点政策及热点动态信息聚合，旨在从业务分析、业务咨询、业务建议等层面为新能源厂商提供一体化解决方案。
          </div>
          <a className="applyFor" onClick={this.applicate}>
            申请试用
          </a>
          <div className="ct">
            {/* <div className="title1" ><i></i>基础功能</div> */}
            <div className="ct1">
              <div className="left">
                <img
                  alt="基础功能-图片"
                  title="基础功能-图片"
                  src={require('./../../assets/imgs/CamNEV/nev.png')}
                />
              </div>
              <ul className="right">
                <li className="li01">
                  <div className="img">
                    {' '}
                    <img
                      alt="市场分析"
                      title="市场分析"
                      src={require('./../../assets/imgs/CamNEV/camIcon1.png')}
                    />
                  </div>

                  <div className="txt txt1">
                    <div className="line1" data-scroll-reveal=" enter  right  over 0.4s">
                      市场分析
                    </div>
                    <div className="line2" data-scroll-reveal=" enter  right  over 0.66s">
                      新能源车型市场表现分析功能，包括量价分析、终端促销分析及细分市场分析
                    </div>
                  </div>
                </li>
                <li className="li02">
                  <div className="img">
                    <img
                      alt="核心三电"
                      title="核心三电"
                      src={require('./../../assets/imgs/CamNEV/camIcon2.png')}
                    />
                  </div>
                  <div className="txt txt2">
                    <div
                      className="line1"
                      data-scroll-reveal=" enter  right over 0.44s after 0.2s "
                    >
                      核心三电
                    </div>
                    <div
                      className="line2"
                      data-scroll-reveal=" enter  right over 0.77s after 0.2s "
                    >
                      新能源车型核心三电装机量、出货量统计及排名分析
                    </div>
                  </div>
                </li>
                <li className="li03">
                  <div className="img">
                    <img
                      alt="行业情报"
                      title="行业情报"
                      src={require('./../../assets/imgs/CamNEV/camIcon3.png')}
                    />
                  </div>
                  <div className="txt txt3">
                    <div
                      className="line1"
                      data-scroll-reveal=" enter  right   over 0.5s  after 0.2s"
                    >
                      行业情报
                    </div>
                    <div
                      className="line2"
                      data-scroll-reveal=" enter  right   over 0.77s  after 0.2s"
                    >
                      新能源汽车行业情报整理分析功能，包含政策趋势研判、行业热点深度解读报告
                    </div>
                  </div>
                </li>
                <li className="li04">
                  <div className="img">
                    {' '}
                    <img
                      alt="促销政策"
                      title="促销政策"
                      src={require('./../../assets/imgs/CamNEV/camIcon4.png')}
                    />
                  </div>
                  <div className="txt txt4">
                    <div className="line1" data-scroll-reveal=" enter  right  over .66s after .4s">
                      促销政策
                    </div>
                    <div className="line2" data-scroll-reveal=" enter  right  over .66s after .5s">
                      新能源行业的相关政策查询，促销信息
                    </div>
                  </div>
                </li>
                <li className="li05">
                  <div className="img">
                    <img
                      alt="充电桩"
                      title="充电桩"
                      src={require('./../../assets/imgs/CamNEV/camIcon5.png')}
                    />
                  </div>
                  <div className="txt txt5">
                    <div
                      className="line1"
                      data-scroll-reveal=" enter  right   over 0.5s  after 0.2s"
                    >
                      充电桩
                    </div>
                    <div
                      className="line2"
                      data-scroll-reveal=" enter  right   over 0.77s  after 0.2s"
                    >
                      全国及分省市充电桩（站）分布状况统计及分析
                    </div>
                  </div>
                </li>
                <li className="li06">
                  <div className="img">
                    {' '}
                    <img
                      alt="产品分析"
                      title="产品分析"
                      src={require('./../../assets/imgs/CamNEV/camIcon6.png')}
                    />
                  </div>
                  <div className="txt txt6">
                    <div className="line1" data-scroll-reveal=" enter  right  over .66s after .4s">
                      产品分析
                    </div>
                    <div className="line2" data-scroll-reveal=" enter  right  over .66s after .5s">
                      上市及预上市新能源车型分析和预测功能，着重于产品分析和产品生命周期管理
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="advantage luxy-el">
          {/* <div className="title1"  ><i></i>平台优势</div> */}
          <ul>
            <li data-scroll-reveal="  enter bottom  over 0.5s move 50px">
              <div className="img">
                {' '}
                <img
                  alt="数据关联挖掘"
                  title="数据关联挖掘"
                  src={require('./../../assets/imgs/CamNEV/nevIcon1.png')}
                />
              </div>
              <div className="line1">数据关联挖掘</div>
              <div className="line2">整合关联各项数据并深度挖掘</div>
            </li>
            <li data-scroll-reveal="  enter bottom  over 1s move 50px after 0.5s ">
              <div className="img">
                {' '}
                <img
                  alt="实时数据反馈"
                  title="实时数据反馈"
                  src={require('./../../assets/imgs/CamNEV/nevIcon2.png')}
                />
              </div>
              <div className="line1">实时数据反馈</div>
              <div className="line2">把握第一手最新数据</div>
            </li>
            <li data-scroll-reveal="  enter bottom  over 1.5s move 50px after .5s ">
              <div className="img">
                {' '}
                <img
                  alt="多维交叉分析"
                  title="多维交叉分析"
                  src={require('./../../assets/imgs/CamNEV/nevIcon3.png')}
                />
              </div>
              <div className="line1">多维交叉分析</div>
              <div className="line2">多维多数据源的交叉关联分析</div>
            </li>
            <li data-scroll-reveal="  enter bottom  over 1.5s move 50px after 1s ">
              <div className="img">
                {' '}
                <img
                  alt="一体化解决方案"
                  title="一体化解决方案"
                  src={require('./../../assets/imgs/CamNEV/nevIcon4.png')}
                />
              </div>
              <div className="line1">一体化解决方案</div>
              <div className="line2">数据服务分析 信息推送一体化</div>
            </li>
          </ul>
        </div>

        <div className="bright luxy-el">
          <div>
            <div className="title1">平台亮点</div>
            <div className={'bright-info'}>
              <div className="nev_change" style={{ display: 'inline-block' }}>
                <div className="nev_c_div">
                  <div>
                    <img
                      alt=""
                      title=""
                      className="nev_div_img"
                      src={require('../../../src/assets/imgs/CamNEV/nev1.png')}
                    />
                  </div>
                  <div className="nev_wz">数据集成&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;信息梳理</div>
                  {/* <div className={'look_info'}>查看更多</div> */}
                </div>
                <div className="nev_card">
                  <div className="nev_item">
                    <div className="item_list">
                      <p>零售批发数据</p>
                      <p>价格促销数据</p>
                    </div>
                    <div className="item_list">
                      <p>装备配置数据</p>
                      <p>核心三电数据</p>
                    </div>
                    <div className="item_list">
                      <p>重磅政策、行业动态&充电桩分布</p>
                    </div>
                  </div>
                </div>
                {/* <table className="nev_table_1">
                  <tbody>
                    <tr>
                      <td className="nev_td_2">零售批发数据</td>
                      <td className="nev_td_2">价格促销数据</td>
                    </tr>
                    <tr>
                      <td className="nev_td_2">装备配置数据</td>
                      <td className="nev_td_2">核心三电数据</td>
                    </tr>
                    <tr>
                      <td className="nev_td_2" colSpan={2} style={{ textAlign: 'center' }}>
                        重磅政策、行业动态&充电桩分布
                      </td>
                    </tr>
                  </tbody>
                </table> */}
              </div>
              <div className="nev_change" style={{ display: 'inline-block' }}>
                <div className="nev_c_div">
                  <div>
                    <img
                      alt=""
                      title=""
                      className="nev_div_img"
                      src={require('../../../src/assets/imgs/CamNEV/nev2.png')}
                    />
                  </div>
                  <div className="nev_wz">数据整合&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;信息分析</div>
                  {/* <div className={'look_info'}>查看更多</div> */}
                </div>
                <div className="nev_card1">
                  <div className="nev_item">
                    <div className="item_list">
                      <p>综合分析</p>
                    </div>
                    <div className="item_list">
                      <p>价量分析</p>
                      <p>装备分析</p>
                      <p>信息分析</p>
                    </div>
                    <div className="item_list">
                      <p>细分市场</p>
                      <p>PVA差异</p>
                      <p>政策解读</p>
                    </div>
                    <div className="item_list">
                      <p>级别市场</p>
                      <p>装备率</p>
                      <p>行业热点</p>
                    </div>
                    <div className="item_list">
                      <p>区域市场</p>
                      <p>技术趋势</p>
                      <p>充电桩</p>
                    </div>
                  </div>
                </div>
                {/* <table className="nev_table_1">
                  <tbody>
                    <tr>
                      <td className="nev_td_2" colSpan={3} style={{ textAlign: 'center' }}>
                        综合分析
                      </td>
                    </tr>
                    <tr>
                      <td className="nev_td_2">价量分析</td>
                      <td className="nev_td_2">装备分析</td>
                      <td className="nev_td_2">信息分析</td>
                    </tr>
                    <tr>
                      <td className="nev_td_3">细分市场</td>
                      <td className="nev_td_3">PVA差异</td>
                      <td className="nev_td_3">政策解读</td>
                    </tr>
                    <tr>
                      <td className="nev_td_3">级别市场</td>
                      <td className="nev_td_3">装备率</td>
                      <td className="nev_td_3">行业热点</td>
                    </tr>
                    <tr>
                      <td className="nev_td_3">区域市场</td>
                      <td className="nev_td_3">技术趋势</td>
                      <td className="nev_td_3">充电桩</td>
                    </tr>
                  </tbody>
                </table> */}
              </div>
              <div className="nev_change" style={{ display: 'inline-block' }}>
                <div className="nev_c_div">
                  <div>
                    <img
                      alt=""
                      title=""
                      className="nev_div_img"
                      src={require('../../../src/assets/imgs/CamNEV/nev3.png')}
                    />
                  </div>
                  <div className="nev_wz">设计报告&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;报告超市</div>
                  {/* <div className={'look_info'}>查看更多</div> */}
                </div>
                <div className="nev_card2">
                  <div className="nev_item">
                    <div className="item_list">
                      <div>
                        <img
                          alt=""
                          title=""
                          className="nev_icon"
                          src={require('../../../src/assets/imgs/CamNEV/nev4.png')}
                        />
                      </div>
                      <div className="nev_text">
                        <p>市场分析报告</p>
                        <p>区域市场研究</p>
                      </div>
                    </div>
                    <div className="item_list">
                      <div className="nev_text">
                        <p>热点专项报告</p>
                        <p>技术趋势报告</p>
                      </div>
                      <div>
                        <img
                          alt=""
                          title=""
                          className="nev_icon"
                          src={require('../../../src/assets/imgs/CamNEV/nev5.png')}
                        />
                      </div>
                    </div>
                    <div className="item_list">
                      <div>
                        <img
                          alt=""
                          title=""
                          className="nev_icon"
                          src={require('../../../src/assets/imgs/CamNEV/nev6.png')}
                        />
                      </div>
                      <div className="nev_text">
                        <p>充电桩研究</p>
                        <p>充电桩地图</p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <table className="nev_table_1">
                  <tbody>
                    <tr>
                      <td className="nev_td_2" colSpan={1}>
                        <img
                          alt=""
                          title=""
                          className="nev_icon"
                          src={require('../../../src/assets/imgs/CamNEV/nev4.png')}
                        />
                      </td>
                      <td className="nev_td_2" colSpan={2}>
                        市场分析报告
                        <br />
                        区域市场研究
                      </td>
                    </tr>
                    <tr>
                      <td className="nev_td_2" colSpan={2}>
                        热点专项报告
                        <br />
                        技术趋势报告
                      </td>
                      <td className="nev_td_2" colSpan={1}>
                        <img
                          alt=""
                          title=""
                          className="nev_icon"
                          src={require('../../../src/assets/imgs/CamNEV/nev5.png')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="nev_td_2" colSpan={1}>
                        <img
                          alt=""
                          title=""
                          className="nev_icon"
                          src={require('../../../src/assets/imgs/CamNEV/nev6.png')}
                        />
                      </td>
                      <td className="nev_td_2" colSpan={2}>
                        充电桩研究
                        <br />
                        充电桩地图
                      </td>
                    </tr>
                  </tbody>
                </table> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default withRouter(CamNEV)
