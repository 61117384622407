import React from 'react'
import './consumer.scss'

class ResearchConsumer extends React.Component {
  render() {
    return (
      <div id="page-consumer">
        <div className="base luxy-el">
          <div>
            <div className="content">
              CAM汽车咨询自2008年开始全面开展咨询类专项调研研究。结合CAM历年累计的数据库、消费者数据、专家库等，提供整体围绕汽车产品整个生命周期体系化的市场专项消费者调研研究类解决方案。
            </div>
            <div className="tb">
              <ul>
                <li className="li01">
                  <div className="d01">环节热点</div>
                  <div className="d02">
                    CAM服务
                    <br />
                    （举例）
                  </div>
                </li>
                <li className="li02">
                  <div className="d01">概念车型方向规定</div>
                  <div className="d02">
                    <div className="d03 dl01">
                      <div className="dian">·</div>
                      <div className="d02_con">造型规划</div>
                    </div>
                    <div className="d03">
                      <div className="dian">·</div>
                      <div className="d02_con">细分市场可行性分析（市场定位）</div>
                    </div>
                    <div className="d03">
                      <div className="dian">·</div>
                      <div className="d02_con">PVA配置感知价值测试</div>
                    </div>
                  </div>
                </li>
                <li className="li02">
                  <div className="d01">量产车型发布及上市</div>
                  <div className="d02">
                    <div className="d03 dl01">
                      <div className="dian">·</div>
                      <div className="d02_con">实车诊断测试</div>
                    </div>
                    <div className="d03">
                      <div className="dian">·</div>
                      <div className="d02_con">产品定位策略</div>
                    </div>
                    <div className="d03">
                      <div className="dian">·</div>
                      <div className="d02_con">目标人群分析（用户画像）</div>
                    </div>
                    <div className="d03">
                      <div className="dian">·</div>
                      <div className="d02_con">销售渠道研究</div>
                    </div>
                  </div>
                </li>
                <li className="li03">
                  <div className="li03_d1">
                    <div className="img img1">
                      <img
                        alt=""
                        title=""
                        src={require('./../../assets/imgs/Research/consumer/xfz_car.png')}
                      />
                    </div>
                    <div className="wz">上市前</div>
                  </div>
                  <div className="li03_d2">
                    <div className="img img2">
                      <img
                        alt=""
                        title=""
                        src={require('./../../assets/imgs/Research/consumer/xfz_car.png')}
                      />
                    </div>
                  </div>
                  <div className="li03_d3">
                    <div className="wz">上市后</div>
                    <div className="img img3">
                      <img
                        alt=""
                        title=""
                        src={require('./../../assets/imgs/Research/consumer/xfz_car.png')}
                      />
                    </div>
                  </div>
                </li>
                <li className="li02">
                  <div className="d01">车型产品上市</div>
                  <div className="d02">
                    <div className="d03 dl01">
                      <div className="dian">·</div>
                      <div className="d02_con">产品卖点研究</div>
                    </div>
                    <div className="d03">
                      <div className="dian">·</div>
                      <div className="d02_con">产品分析研究</div>
                    </div>
                    <div className="d03">
                      <div className="dian">·</div>
                      <div className="d02_con">新车营销媒体效果测试</div>
                    </div>
                  </div>
                </li>
                <li className="li02">
                  <div className="d01">
                    车型产品
                    <br />
                    生命周期延续
                  </div>
                  <div className="d02">
                    <div className="d03 dl01">
                      <div className="dian">·</div>
                      <div className="d02_con">消费者&经销商满意度研究</div>
                    </div>
                    <div className="d03">
                      <div className="dian">·</div>
                      <div className="d02_con">改款换代策略</div>
                    </div>
                    <div className="d03">
                      <div className="dian">·</div>
                      <div className="d02_con">神秘客户及入店检查</div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="rea-pic">
              <div className="title">消费者调研项目流程</div>
              <div className="pic1">
                <img
                  alt="消费者调研项目流程"
                  title="消费者调研项目流程"
                  src={require('./../../assets/imgs/Research/consumer/xfz_img1.png')}
                />
              </div>
              <ul className="ys">
                <li>优势：</li>
                <li>1.稳固的传统调研渠道</li>
                <li>
                  2.结合网络大数据及CAM历年消费者数据库、专家库等累积数据对项目研究结论相互结合补充
                </li>
              </ul>
              <div className="pic2">
                <p>双盲互查</p>
                <img
                  alt="消费者调研项目流程"
                  title="消费者调研项目流程"
                  src={require('./../../assets/imgs/Research/consumer/xfz_img2.png')}
                />
              </div>
              <ul className="ys">
                <li>优势：</li>
                <li>1.采用双盲数据审核机制</li>
                <li>
                  2.结合网络大数据及CAM历年消费者数据库、专家库等累积数据对项目研究结论相互结合补充
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default ResearchConsumer
