import React from "react";

class JpjpAnalysis extends React.Component {
    componentDidMount() {
        $(function () {
            $('#jpAnalysisBanner').terseBanner({
                adaptive: true,
                auto: 4000,
                arrow: true,
            });
        });
    }



    render() {
        return <div id={"jpAnalysis"}>
            <div id="jpAnalysisBanner" className={"banner custom"}>
                <ul>
                    <li className="mb_item">
                        <img alt="竞品上市分析报告一" title="竞品上市分析报告一" src={require("../../../assets/imgs/Research/industry/cam_sq35.jpg")}/>
                    </li>
                    <li className="mb_item">
                        <img alt="竞品上市分析报告二" title="竞品上市分析报告二" src={require("../../../assets/imgs/Research/industry/cam_sq36.jpg")}/>
                    </li>
                </ul>
            </div>
        </div>
    }
}

export default JpjpAnalysis;