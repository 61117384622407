import React from "react";

class HotspotResearch extends React.Component {
    componentDidMount() {
        $(function () {
            $('#hotspotResearchBanner').terseBanner({
                adaptive: true,
                auto: 4000,
                arrow: true,
            });
        });
    }

    componentWillUnmount() {
        let banner = $('#hotspotResearchBanner').data('terseBanner');
        if (banner.stop)
            banner.stop();//清除定时器
        $('#hotspotResearchBanner').data('terseBanner', undefined);
    }


    render() {
        return <div id={"hotspotResearch"} className={"tabContent"}>
            <div className="explain">
                <div className="headline">
                    <p>宏观形势研究样例展示
                        <span>(宏观热点研究)</span></p>
                </div>
                <div className="content1">
                    <p><b>目的:</b></p>
                    <p>跟踪并分析重要经济会议及热点经济事件，研判其对经济发展及汽车行业发展可能带来的影响。</p>
                    <p><b>内容:</b></p>
                    <p>通过深入剖析宏观经济会议和宏观热点事件中所释放的宏观经济政策导向和细化举措，解读其对汽车行业发展的可能影响，从而为企业经营决策提供参考。
                    </p>
                </div>
            </div>
            <div id="hotspotResearchBanner" className={"banner"}>
                <ul>
                    <li className="mb_item">
                        <img alt="宏观热点研究一" title="宏观热点研究一" data-src={require("../../../assets/imgs/Research/hotspotResearch/cam_sq52.jpg")}/>
                    </li>
                    <li className="mb_item">
                        <img alt="宏观热点研究二" title="宏观热点研究二" data-src={require("../../../assets/imgs/Research/hotspotResearch/cam_sq53.jpg")}/>
                    </li>
                    <li className="mb_item">
                        <img alt="宏观热点研究三" title="宏观热点研究三" data-src={require("../../../assets/imgs/Research/hotspotResearch/cam_sq54.jpg")}/>
                    </li>
                    <li className="mb_item">
                        <img alt="宏观热点研究四" title="宏观热点研究四" data-src={require("../../../assets/imgs/Research/hotspotResearch/cam_sq55.jpg")}/>
                    </li>
                </ul>
            </div>
        </div>
    }
}

export default HotspotResearch;