import React from 'react'
import { Tabs, Carousel } from 'antd'
import './consult.scss'

class ResearchConsult extends React.Component {
  render() {
    return (
      <div id="page-consult">
        <div className="base luxy-el">
          <div>
            <div className="title">CAM专案咨询业务体系</div>
            <div className="con">基于厂商业务模块划分而制定的专项咨询研究业务体系</div>
          </div>
          <div className={'chart'}>
            <img
              alt="CAM专案咨询业务体系"
              title="CAM专案咨询业务体系"
              src={require('./../../assets/imgs/Research/resa_zazx.png')}
            />
          </div>
          <div className="eg-bj">
            <Tabs defaultActiveKey="1">
              <Tabs.TabPane tab="传统业务研究样例展示" key="1">
                <Carousel>
                  <div className="li03">
                    <div className="d1">新能源</div>
                    <div>
                      <div className="d2">
                        <img
                          alt="新能源一"
                          title="新能源一"
                          className="img"
                          src={require('../../assets/imgs/Research/consult/cam_sq52.jpg')}
                        />
                      </div>
                      <div className="d3">
                        <img
                          alt="新能源二"
                          title="新能源二"
                          className="img"
                          src={require('../../assets/imgs/Research/consult/cam_sq53.jpg')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="li04">
                    <div className="d1">自动驾驶</div>
                    <div>
                      <div className="d2">
                        <img
                          alt="自动驾驶一"
                          title="自动驾驶一"
                          className="img"
                          src={require('../../assets/imgs/Research/consult/cam_sq54.jpg')}
                        />
                      </div>
                      <div className="d3">
                        <img
                          alt="自动驾驶二"
                          title="自动驾驶二"
                          className="img"
                          src={require('../../assets/imgs/Research/consult/cam_sq55.jpg')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="li05">
                    <div className="d1">智能网联</div>
                    <div>
                      <div className="d2">
                        <img
                          alt="智能网联一"
                          title="智能网联一"
                          className="img"
                          src={require('../../assets/imgs/Research/consult/cam_sq56.jpg')}
                        />
                      </div>
                      <div className="d3">
                        <img
                          alt="智能网联二"
                          title="智能网联二"
                          className="img"
                          src={require('../../assets/imgs/Research/consult/cam_sq57.jpg')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="li06">
                    <div className="d1">充电设施</div>
                    <div>
                      <div className="d2">
                        <img
                          alt="充电设施一"
                          title="充电设施一"
                          className="img"
                          src={require('../../assets/imgs/Research/consult/cam_sq58.jpg')}
                        />
                      </div>
                      <div className="d3">
                        <img
                          alt="充电设施二"
                          title="充电设施二"
                          className="img"
                          src={require('../../assets/imgs/Research/consult/cam_sq59.jpg')}
                        />
                      </div>
                    </div>
                  </div>
                </Carousel>
              </Tabs.TabPane>
              <Tabs.TabPane tab="创新业务研究样例展示" key="2">
                <Carousel>
                  <div className="li03">
                    <div className="d1">汽车金融</div>
                    <div>
                      <div className="d2">
                        <img
                          alt="汽车金融一"
                          title="汽车金融一"
                          className="img"
                          src={require('../../assets/imgs/Research/consult/cam_sq44.jpg')}
                        />
                      </div>
                      <div className="d3">
                        <img
                          alt="汽车金融二"
                          title="汽车金融二"
                          className="img"
                          src={require('../../assets/imgs/Research/consult/cam_sq45.jpg')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="li04">
                    <div className="d1">汽车电商</div>
                    <div>
                      <div className="d2">
                        <img
                          alt="汽车电商一"
                          title="汽车电商一"
                          className="img"
                          src={require('../../assets/imgs/Research/consult/cam_sq47.jpg')}
                        />
                      </div>
                      <div className="d3">
                        <img
                          alt="汽车电商二"
                          title="汽车电商二"
                          className="img"
                          src={require('../../assets/imgs/Research/consult/cam_sq46.jpg')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="li05">
                    <div className="d1">二手车</div>
                    <div>
                      <div className="d2">
                        <img
                          alt="二手车一"
                          title="二手车一"
                          className="img"
                          src={require('../../assets/imgs/Research/consult/cam_sq49.jpg')}
                        />
                      </div>
                      <div className="d3">
                        <img
                          alt="二手车二"
                          title="二手车二"
                          className="img"
                          src={require('../../assets/imgs/Research/consult/cam_sq48.jpg')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="li06">
                    <div className="d1">维修保养</div>
                    <div>
                      <div className="d2">
                        <img
                          alt="维修保养一"
                          title="维修保养一"
                          className="img"
                          src={require('../../assets/imgs/Research/consult/cam_sq51.jpg')}
                        />
                      </div>
                      <div className="d3">
                        <img
                          alt="维修保养二"
                          title="维修保养二"
                          className="img"
                          src={require('../../assets/imgs/Research/consult/cam_sq50.jpg')}
                        />
                      </div>
                    </div>
                  </div>
                </Carousel>
              </Tabs.TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    )
  }
}

export default ResearchConsult
