import React from 'react'
import './camAPP.scss'
import BannerIP from '../../components/bannerInsidePages/bannerInsidePages'

class CamAPP extends React.Component {
  render() {
    document.title = 'CAM移动端'
    return (
      <div id="page-camAPP">
        <BannerIP type={'app'} />
        <div className="base luxy-el">
          <div className="md">
            <div className="d1">
              <img
                alt="CAM移动端-APP"
                title="CAM移动端-APP"
                className="img"
                src={require('./../../assets/imgs/CamAPP/app.png')}
              />
            </div>
            <div className="d2">
              <div className="title">移动端行业情报助手辅助</div>
              <div className="title">查询数据更便捷</div>
              <div className="d3">
                <li className="li_01">
                  <img src={require('./../../assets/imgs/CamAPP/appIcon1.png')} />
                  <p>操作便捷快速</p>
                </li>
                <li className="li_02">
                  <img src={require('./../../assets/imgs/CamAPP/appIcon2.png')} />
                  <p>内容清晰流畅</p>
                </li>
                <li className="li_03">
                  <img src={require('./../../assets/imgs/CamAPP/appIcon3.png')} />
                  <p>随时随地掌握动态</p>
                </li>
              </div>
              <ul className="ul01">
                <li>在线数据分析平台</li>
                <li>新车在线查询系统</li>
                <li>在线行业分析定制系统</li>
              </ul>
              <div className="title2">移动端关注</div>
              <div className="con">
                {/* <div className="bh">01</div> */}
                <div className="nr">
                  扫码关注官方公众号【CAM汽车咨询】，申请试用，验证通过即可登录使用
                </div>
              </div>
              <div className="con">
                {/* <div className="bh">02</div> */}
                <div className="nr special">
                  关注官方公众号，可及时了解行业事件，接收实时咨询分享
                </div>
              </div>
              <div className="abq">
                <a>扫描二维码，申请试用 >> </a>
                <div className="pic">
                  <img
                    alt="二维码"
                    title="二维码"
                    className="img2"
                    src={require('./../../assets/imgs/CamAPP/cam-APP03.png')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default CamAPP
