import React from 'react'
import './home.scss'
import Banner from './../../components/banner/banner'
import TabBar from '../../components/tabbar/tabbar'
class Home extends React.Component {
  componentDidMount() {
    /* $(function () {
            //网状动画
            let st;
            let resize = () => {
                clearTimeout(st);
                st = setTimeout(() => {
                    $("#camWang").attr({
                        height: document.documentElement.clientHeight,
                        width: document.documentElement.clientWidth
        
                    });
                    new CamWang($("#camWang")[0]);
                }, 50); 
            };
            $(window).resize(resize); resize();
          
        });*/
  }

  render() {
    document.title = '上海福蓝汽车技术有限公司'
    return (
      <div>
        <Banner />
        <div id="page-home">
          {/*  <canvas id="camWang" width="1920" height="937">
                        <canvas> </canvas>
                    </canvas>
*/}

          <div className="home">
            <div className="system luxy-el">
              <div className="line1">CAM系统</div>
              <div className="line2">
                集市场洞察、结合多维数据整合和大数据智能互联于一体，提供多方位的智能化平台应用。
              </div>
              <ul className="line3">
                <li data-scroll-reveal="enter top  over 0.5s ">
                  <div>
                    <div className="img">
                      <img
                        alt="多维分析平台"
                        title="多维分析平台"
                        width=""
                        height=""
                        src={require('./../../assets/imgs/home/CAMSM/PMS.png')}
                      />
                    </div>
                    <div className="txt">多维分析平台</div>
                    <div className="txt1">CAM&nbsp;PMS</div>
                  </div>
                </li>
                <li data-scroll-reveal=" enter top  over 0.5s after 0.3s">
                  <div>
                    <div className="img">
                      <img
                        alt="人工智能平台"
                        title="人工智能平台"
                        width=""
                        height=""
                        src={require('./../../assets/imgs/home/CAMSM/AI.png')}
                      />
                    </div>
                    <div className="txt">人工智能平台</div>
                    <div className="txt1">CAM&nbsp;AI</div>
                  </div>
                </li>
                <li data-scroll-reveal=" enter top  over 0.5s after 0.5s">
                  {' '}
                  <div>
                    <div className="img">
                      <img
                        alt="敏捷分析平台"
                        title="敏捷分析平台"
                        width=""
                        height=""
                        src={require('./../../assets/imgs/home/CAMSM/BI.png')}
                      />
                    </div>
                    <div className="txt">敏捷分析平台</div>
                    <div className="txt1">CAM&nbsp;BI</div>
                  </div>
                </li>
                <li data-scroll-reveal=" enter top  over 0.5s after 0.7s">
                  <div>
                    <div className="img">
                      <img
                        alt="信息平台"
                        title="信息平台"
                        width=""
                        height=""
                        src={require('./../../assets/imgs/home/CAMSM/INFO.png')}
                      />
                    </div>
                    <div className="txt">信息平台</div>
                    <div className="txt1">CAM&nbsp;INFO</div>
                  </div>
                </li>
                <li data-scroll-reveal="enter top  over 0.5s after 0.9s">
                  {' '}
                  <div>
                    <div className="img">
                      <img
                        alt="新能源平台"
                        title="新能源平台"
                        width=""
                        height=""
                        src={require('./../../assets/imgs/home/CAMSM/NEV.png')}
                      />
                    </div>
                    <div className="txt">新能源平台</div>
                    <div className="txt1">CAM&nbsp;NEV</div>
                  </div>
                </li>
                <li data-scroll-reveal=" enter top over 0.5s after 1.2s">
                  {' '}
                  <div>
                    <div className="img">
                      <img
                        alt="移动端"
                        title="移动端"
                        width=""
                        height=""
                        src={require('./../../assets/imgs/home/CAMSM/APP.png')}
                      />
                    </div>
                    <div className="txt">移动端</div>
                    <div className="txt1">CAM&nbsp;APP</div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="research luxy-el">
              <div className="line1">CAM研究</div>
              <div className="line2">
                CAM运用大数据思维，依托数据集成和整体化架构，对汽车行业深入探索和研究。
              </div>
              <ul className="line3">
                <li data-scroll-reveal="enter top  over 0.5s ">
                  <div>
                    <div className="img">
                      <img
                        alt="商情分析"
                        title="商情分析"
                        width=""
                        height=""
                        src={require('./../../assets/imgs/home/CAMRCH/CAM_Intel.png')}
                      />
                    </div>
                    <div className="txt">商情分析</div>
                    <div className="txt1">CAM Intelligence</div>
                  </div>
                </li>
                <li data-scroll-reveal="enter top over 0.5s after 0.3s">
                  <div>
                    <div className="img">
                      <img
                        alt="产品研究"
                        title="产品研究"
                        width=""
                        height=""
                        src={require('./../../assets/imgs/home/CAMRCH/CAM_PR.png')}
                      />
                    </div>
                    <div className="txt">产品研究</div>
                    <div className="txt1">CAM Product Research</div>
                  </div>{' '}
                </li>
                <li data-scroll-reveal="enter top over 0.5s after 0.5s">
                  <div>
                    <div className="img">
                      <img
                        alt="行业观察"
                        title="行业观察"
                        width=""
                        height=""
                        src={require('./../../assets/imgs/home/CAMRCH/CAM_IO.png')}
                      />
                    </div>
                    <div className="txt">行业观察</div>
                    <div className="txt1">CAM Industry Observation</div>
                  </div>{' '}
                </li>
                <li data-scroll-reveal="enter top over 0.5s after 0.7s">
                  <div>
                    {' '}
                    <div className="img">
                      <img
                        alt="市场预测"
                        title="市场预测"
                        width=""
                        height=""
                        src={require('./../../assets/imgs/home/CAMRCH/CAM_Fore.png')}
                      />
                    </div>
                    <div className="txt">市场预测</div>
                    <div className="txt1">CAM Forecast</div>
                  </div>{' '}
                </li>
                <li data-scroll-reveal="enter top  over 0.5s after 0.9s">
                  <div>
                    <div className="img">
                      <img
                        alt="消费者调研"
                        title="消费者调研"
                        width=""
                        height=""
                        src={require('./../../assets/imgs/home/CAMRCH/CAM_CS.png')}
                      />
                    </div>
                    <div className="txt">消费者调研</div>
                    <div className="txt1">CAM Consumer Survey</div>
                  </div>{' '}
                </li>
                <li data-scroll-reveal="enter top over 0.5s after 1.2s">
                  <div>
                    <div className="img">
                      <img
                        alt="专案咨询"
                        title="专案咨询"
                        width=""
                        height=""
                        src={require('./../../assets/imgs/home/CAMRCH/CAM_SC.png')}
                      />
                    </div>
                    <div className="txt">专案咨询</div>
                    <div className="txt1">CAM Special Consultation</div>
                  </div>
                </li>
              </ul>
            </div>

            <div className="advantage luxy-el">
              <div className="text">
                <div className="line1">雄厚的技术实力</div>
                <TabBar />
              </div>
            </div>
            <div className="cooperate luxy-el">
              <div className="line1">我们的合作客户</div>
              <div className="line2">
                CAM合作客户超百家，目前已与90%的主机厂均有合作关系，合作伙伴遍布汽车经销商集团、零部件、金融、投资、广告等企业。
              </div>
              <div className="line4">
                <ul>
                  <li>
                    <img src={require('../../assets/imgs/home/ComVe/Frame 85.png')}></img>
                    <img src={require('../../assets/imgs/home/ComVe/Frame 86.png')}></img>
                    <img src={require('../../assets/imgs/home/Line.png')}></img>
                    <img src={require('../../assets/imgs/home/MulAut/Frame 126.png')}></img>
                    <img src={require('../../assets/imgs/home/MulAut/Frame 83.png')}></img>
                    <img src={require('../../assets/imgs/home/MulAut/Frame 84.png')}></img>
                    <img src={require('../../assets/imgs/home/MulAut/Frame 85.png')}></img>
                    <img src={require('../../assets/imgs/home/Line.png')}></img>
                    <img src={require('../../assets/imgs/home/MulAut/Frame 86.png')}></img>
                    <img src={require('../../assets/imgs/home/MulAut/Frame 87.png')}></img>
                    <img src={require('../../assets/imgs/home/MulAut/Frame 88.png')}></img>
                    <img src={require('../../assets/imgs/home/MulAut/Frame 89.png')}></img>
                    <img src={require('../../assets/imgs/home/MulAut/Frame 90.png')}></img>
                    <img src={require('../../assets/imgs/home/MulAut/Frame 82.png')}></img>
                    <img src={require('../../assets/imgs/home/MulAut/Frame 91.png')}></img>
                    <img src={require('../../assets/imgs/home/Line.png')}></img>
                    <img src={require('../../assets/imgs/home/ComVe/Frame 127.png')}></img>
                    <img src={require('../../assets/imgs/home/ComVe/Frame 82.png')}></img>
                    <img src={require('../../assets/imgs/home/ComVe/Frame 83.png')}></img>
                    <img src={require('../../assets/imgs/home/ComVe/Frame 84.png')}></img>
                  </li>
                  <li>
                    <img src={require('../../assets/imgs/home/IndeCar/Frame 127.png')}></img>
                    <img src={require('../../assets/imgs/home/IndeCar/Frame 82.png')}></img>
                    <img src={require('../../assets/imgs/home/IndeCar/Frame 83.png')}></img>
                    <img src={require('../../assets/imgs/home/IndeCar/Frame 84.png')}></img>
                    <img src={require('../../assets/imgs/home/IndeCar/Frame 85.png')}></img>
                    <img src={require('../../assets/imgs/home/IndeCar/Frame 86.png')}></img>
                    <img src={require('../../assets/imgs/home/IndeCar/Frame 87.png')}></img>
                    <img src={require('../../assets/imgs/home/IndeCar/Frame 88.png')}></img>
                    <img src={require('../../assets/imgs/home/Line.png')}></img>
                    <img src={require('../../assets/imgs/home/NewEnergy/Frame 128.png')}></img>
                    <img src={require('../../assets/imgs/home/NewEnergy/Frame 82.png')}></img>
                    <img src={require('../../assets/imgs/home/NewEnergy/Frame 83.png')}></img>
                    <img src={require('../../assets/imgs/home/NewEnergy/Frame 84.png')}></img>
                    <img src={require('../../assets/imgs/home/NewEnergy/Frame 85.png')}></img>
                    <img src={require('../../assets/imgs/home/NewEnergy/Frame 86.png')}></img>
                    <img src={require('../../assets/imgs/home/NewEnergy/Frame 87.png')}></img>
                    <img src={require('../../assets/imgs/home/NewEnergy/Frame 88.png')}></img>
                    <img src={require('../../assets/imgs/home/Line.png')}></img>
                  </li>
                  <li>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 86.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 87.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 89.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 88.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 90.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 91.png')}></img>
                    <img src={require('../../assets/imgs/home/Line.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 127.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 82.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 83.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 84.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 85.png')}></img>
                    <img src={require('../../assets/imgs/home/Line.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 127.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 82.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 83.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 84.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 85.png')}></img>
                  </li>
                  <li>
                    <img src={require('../../assets/imgs/home/PartCoo/Frame 130.png')}></img>
                    <img src={require('../../assets/imgs/home/PartCoo/Frame 82.png')}></img>
                    <img src={require('../../assets/imgs/home/PartCoo/Frame 83.png')}></img>
                    <img src={require('../../assets/imgs/home/PartCoo/Frame 84.png')}></img>
                    <img src={require('../../assets/imgs/home/Line.png')}></img>
                    <img src={require('../../assets/imgs/home/FinF/Frame 128.png')}></img>
                    <img src={require('../../assets/imgs/home/FinF/Frame 82-1.png')}></img>
                    <img src={require('../../assets/imgs/home/FinF/Frame 83-1.png')}></img>
                    <img src={require('../../assets/imgs/home/FinF/Frame 84-1.png')}></img>
                    <img src={require('../../assets/imgs/home/FinF/Frame 85.png')}></img>
                    <img src={require('../../assets/imgs/home/Line.png')}></img>
                    <img src={require('../../assets/imgs/home/Parts/Frame 129.png')}></img>
                    <img src={require('../../assets/imgs/home/Parts/Frame 82-2.png')}></img>
                    <img src={require('../../assets/imgs/home/Parts/Frame 83-2.png')}></img>
                    <img src={require('../../assets/imgs/home/Parts/Frame 84-2.png')}></img>
                    <img src={require('../../assets/imgs/home/Parts/Frame 85-1.png')}></img>
                    <img src={require('../../assets/imgs/home/FinF/Frame 82-1.png')}></img>
                  </li>
                </ul>
                <ul>
                  <li>
                    <img src={require('../../assets/imgs/home/ComVe/Frame 85.png')}></img>
                    <img src={require('../../assets/imgs/home/ComVe/Frame 86.png')}></img>
                    <img src={require('../../assets/imgs/home/Line.png')}></img>
                    <img src={require('../../assets/imgs/home/MulAut/Frame 126.png')}></img>
                    <img src={require('../../assets/imgs/home/MulAut/Frame 83.png')}></img>
                    <img src={require('../../assets/imgs/home/MulAut/Frame 84.png')}></img>
                    <img src={require('../../assets/imgs/home/MulAut/Frame 85.png')}></img>
                    <img src={require('../../assets/imgs/home/Line.png')}></img>
                    <img src={require('../../assets/imgs/home/MulAut/Frame 86.png')}></img>
                    <img src={require('../../assets/imgs/home/MulAut/Frame 87.png')}></img>
                    <img src={require('../../assets/imgs/home/MulAut/Frame 88.png')}></img>
                    <img src={require('../../assets/imgs/home/MulAut/Frame 89.png')}></img>
                    <img src={require('../../assets/imgs/home/MulAut/Frame 90.png')}></img>
                    <img src={require('../../assets/imgs/home/MulAut/Frame 82.png')}></img>
                    <img src={require('../../assets/imgs/home/MulAut/Frame 91.png')}></img>
                    <img src={require('../../assets/imgs/home/Line.png')}></img>
                    <img src={require('../../assets/imgs/home/ComVe/Frame 127.png')}></img>
                    <img src={require('../../assets/imgs/home/ComVe/Frame 82.png')}></img>
                    <img src={require('../../assets/imgs/home/ComVe/Frame 83.png')}></img>
                    <img src={require('../../assets/imgs/home/ComVe/Frame 84.png')}></img>
                  </li>
                  <li>
                    <img src={require('../../assets/imgs/home/IndeCar/Frame 127.png')}></img>
                    <img src={require('../../assets/imgs/home/IndeCar/Frame 82.png')}></img>
                    <img src={require('../../assets/imgs/home/IndeCar/Frame 83.png')}></img>
                    <img src={require('../../assets/imgs/home/IndeCar/Frame 84.png')}></img>
                    <img src={require('../../assets/imgs/home/IndeCar/Frame 85.png')}></img>
                    <img src={require('../../assets/imgs/home/IndeCar/Frame 86.png')}></img>
                    <img src={require('../../assets/imgs/home/IndeCar/Frame 87.png')}></img>
                    <img src={require('../../assets/imgs/home/IndeCar/Frame 88.png')}></img>
                    <img src={require('../../assets/imgs/home/Line.png')}></img>
                    <img src={require('../../assets/imgs/home/NewEnergy/Frame 128.png')}></img>
                    <img src={require('../../assets/imgs/home/NewEnergy/Frame 82.png')}></img>
                    <img src={require('../../assets/imgs/home/NewEnergy/Frame 83.png')}></img>
                    <img src={require('../../assets/imgs/home/NewEnergy/Frame 84.png')}></img>
                    <img src={require('../../assets/imgs/home/NewEnergy/Frame 85.png')}></img>
                    <img src={require('../../assets/imgs/home/NewEnergy/Frame 86.png')}></img>
                    <img src={require('../../assets/imgs/home/NewEnergy/Frame 87.png')}></img>
                    <img src={require('../../assets/imgs/home/NewEnergy/Frame 88.png')}></img>
                    <img src={require('../../assets/imgs/home/Line.png')}></img>
                  </li>
                  <li>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 86.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 87.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 89.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 88.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 90.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 91.png')}></img>
                    <img src={require('../../assets/imgs/home/Line.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 127.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 82.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 83.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 84.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 85.png')}></img>
                    <img src={require('../../assets/imgs/home/Line.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 127.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 82.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 83.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 84.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 85.png')}></img>
                  </li>
                  <li>
                    <img src={require('../../assets/imgs/home/PartCoo/Frame 130.png')}></img>
                    <img src={require('../../assets/imgs/home/PartCoo/Frame 82.png')}></img>
                    <img src={require('../../assets/imgs/home/PartCoo/Frame 83.png')}></img>
                    <img src={require('../../assets/imgs/home/PartCoo/Frame 84.png')}></img>
                    <img src={require('../../assets/imgs/home/Line.png')}></img>
                    <img src={require('../../assets/imgs/home/FinF/Frame 128.png')}></img>
                    <img src={require('../../assets/imgs/home/FinF/Frame 82-1.png')}></img>
                    <img src={require('../../assets/imgs/home/FinF/Frame 83-1.png')}></img>
                    <img src={require('../../assets/imgs/home/FinF/Frame 84-1.png')}></img>
                    <img src={require('../../assets/imgs/home/FinF/Frame 85.png')}></img>
                    <img src={require('../../assets/imgs/home/Line.png')}></img>
                    <img src={require('../../assets/imgs/home/Parts/Frame 129.png')}></img>
                    <img src={require('../../assets/imgs/home/Parts/Frame 82-2.png')}></img>
                    <img src={require('../../assets/imgs/home/Parts/Frame 83-2.png')}></img>
                    <img src={require('../../assets/imgs/home/Parts/Frame 84-2.png')}></img>
                    <img src={require('../../assets/imgs/home/Parts/Frame 85-1.png')}></img>
                    <img src={require('../../assets/imgs/home/FinF/Frame 82-1.png')}></img>
                  </li>
                </ul>
                <ul>
                  <li>
                    <img src={require('../../assets/imgs/home/ComVe/Frame 85.png')}></img>
                    <img src={require('../../assets/imgs/home/ComVe/Frame 86.png')}></img>
                    <img src={require('../../assets/imgs/home/Line.png')}></img>
                    <img src={require('../../assets/imgs/home/MulAut/Frame 126.png')}></img>
                    <img src={require('../../assets/imgs/home/MulAut/Frame 83.png')}></img>
                    <img src={require('../../assets/imgs/home/MulAut/Frame 84.png')}></img>
                    <img src={require('../../assets/imgs/home/MulAut/Frame 85.png')}></img>
                    <img src={require('../../assets/imgs/home/Line.png')}></img>
                    <img src={require('../../assets/imgs/home/MulAut/Frame 86.png')}></img>
                    <img src={require('../../assets/imgs/home/MulAut/Frame 87.png')}></img>
                    <img src={require('../../assets/imgs/home/MulAut/Frame 88.png')}></img>
                    <img src={require('../../assets/imgs/home/MulAut/Frame 89.png')}></img>
                    <img src={require('../../assets/imgs/home/MulAut/Frame 90.png')}></img>
                    <img src={require('../../assets/imgs/home/MulAut/Frame 82.png')}></img>
                    <img src={require('../../assets/imgs/home/MulAut/Frame 91.png')}></img>
                    <img src={require('../../assets/imgs/home/Line.png')}></img>
                    <img src={require('../../assets/imgs/home/ComVe/Frame 127.png')}></img>
                    <img src={require('../../assets/imgs/home/ComVe/Frame 82.png')}></img>
                    <img src={require('../../assets/imgs/home/ComVe/Frame 83.png')}></img>
                    <img src={require('../../assets/imgs/home/ComVe/Frame 84.png')}></img>
                  </li>
                  <li>
                    <img src={require('../../assets/imgs/home/IndeCar/Frame 127.png')}></img>
                    <img src={require('../../assets/imgs/home/IndeCar/Frame 82.png')}></img>
                    <img src={require('../../assets/imgs/home/IndeCar/Frame 83.png')}></img>
                    <img src={require('../../assets/imgs/home/IndeCar/Frame 84.png')}></img>
                    <img src={require('../../assets/imgs/home/IndeCar/Frame 85.png')}></img>
                    <img src={require('../../assets/imgs/home/IndeCar/Frame 86.png')}></img>
                    <img src={require('../../assets/imgs/home/IndeCar/Frame 87.png')}></img>
                    <img src={require('../../assets/imgs/home/IndeCar/Frame 88.png')}></img>
                    <img src={require('../../assets/imgs/home/Line.png')}></img>
                    <img src={require('../../assets/imgs/home/NewEnergy/Frame 128.png')}></img>
                    <img src={require('../../assets/imgs/home/NewEnergy/Frame 82.png')}></img>
                    <img src={require('../../assets/imgs/home/NewEnergy/Frame 83.png')}></img>
                    <img src={require('../../assets/imgs/home/NewEnergy/Frame 84.png')}></img>
                    <img src={require('../../assets/imgs/home/NewEnergy/Frame 85.png')}></img>
                    <img src={require('../../assets/imgs/home/NewEnergy/Frame 86.png')}></img>
                    <img src={require('../../assets/imgs/home/NewEnergy/Frame 87.png')}></img>
                    <img src={require('../../assets/imgs/home/NewEnergy/Frame 88.png')}></img>
                    <img src={require('../../assets/imgs/home/Line.png')}></img>
                  </li>
                  <li>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 86.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 87.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 89.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 88.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 90.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 91.png')}></img>
                    <img src={require('../../assets/imgs/home/Line.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 127.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 82.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 83.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 84.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 85.png')}></img>
                    <img src={require('../../assets/imgs/home/Line.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 127.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 82.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 83.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 84.png')}></img>
                    <img src={require('../../assets/imgs/home/JointVe/Frame 85.png')}></img>
                  </li>
                  <li>
                    <img src={require('../../assets/imgs/home/PartCoo/Frame 130.png')}></img>
                    <img src={require('../../assets/imgs/home/PartCoo/Frame 82.png')}></img>
                    <img src={require('../../assets/imgs/home/PartCoo/Frame 83.png')}></img>
                    <img src={require('../../assets/imgs/home/PartCoo/Frame 84.png')}></img>
                    <img src={require('../../assets/imgs/home/Line.png')}></img>
                    <img src={require('../../assets/imgs/home/FinF/Frame 128.png')}></img>
                    <img src={require('../../assets/imgs/home/FinF/Frame 82-1.png')}></img>
                    <img src={require('../../assets/imgs/home/FinF/Frame 83-1.png')}></img>
                    <img src={require('../../assets/imgs/home/FinF/Frame 84-1.png')}></img>
                    <img src={require('../../assets/imgs/home/FinF/Frame 85.png')}></img>
                    <img src={require('../../assets/imgs/home/Line.png')}></img>
                    <img src={require('../../assets/imgs/home/Parts/Frame 129.png')}></img>
                    <img src={require('../../assets/imgs/home/Parts/Frame 82-2.png')}></img>
                    <img src={require('../../assets/imgs/home/Parts/Frame 83-2.png')}></img>
                    <img src={require('../../assets/imgs/home/Parts/Frame 84-2.png')}></img>
                    <img src={require('../../assets/imgs/home/Parts/Frame 85-1.png')}></img>
                    <img src={require('../../assets/imgs/home/FinF/Frame 82-1.png')}></img>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default Home
