import React from 'react'
import './intelligence.scss'
import { Modal } from 'antd'
import Sales from '../../components/research/intelligence/sales'
import Price from '../../components/research/intelligence/price'
import Activity from '../../components/research/intelligence/activity'
import Analysis from '../../components/research/intelligence/analysis'
const info = [
  {
    key: 'sales',
    title: '销量研究',
    content: '跟踪分析车市销售的波动，追源求因。CAM的咨询意见给予企业在产品规划中的帮助。',
    icon: require('../../assets/imgs/Research/intelligence/sqIcon1.png'),
  },
  {
    key: 'price',
    title: '价格研究',
    content:
      '通过不同维度分析市场竞争的价格走势情况，及时掌握价格波动的原因，助企业了解终端市场的主要指标——价格。',
    icon: require('../../assets/imgs/Research/intelligence/sqIcon2.png'),
  },
  {
    key: 'activity',
    title: '店头活动监测分析',
    content: '通过门店活动的监控，及时掌握终端经销商及厂家的最新政策，从而判断市场的行情。',
    icon: require('../../assets/imgs/Research/intelligence/sqIcon3.png'),
  },
  {
    key: 'analysis',
    title: '综合分析研究',
    content:
      '从宏观环境着手，集价格、政策、销量、及KPI的综合分析报告，有助于企业全面了解竞争环境。',
    icon: require('../../assets/imgs/Research/intelligence/sqIcon4.png'),
  },
]

class ResearchIntelligence extends React.Component {
  constructor() {
    super()
    this.state = {
      visible: false,
      html: '',
      title: '',
    }
  }
  showModal = key => {
    let html, title
    switch (key) {
      case 'sales':
        html = <Sales />
        title = '销量研究样例'
        break
      case 'price':
        html = <Price />
        title = '价格研究样例'
        break
      case 'activity':
        html = <Activity />
        title = '店头活动监测分析研究样例'
        break
      case 'analysis':
        html = <Analysis />
        title = '综合分析研究样例'
        break
    }

    this.setState({ visible: true, html, title })
  }

  render() {
    const { visible, html, title } = this.state
    return (
      <div id="page-intelligence">
        <div className="title">
          基于4P营销组合 解决客户各阶段目标需求
          <span>我们的研究体系涵盖营销组合的每个阶段，可提供多元化的服务支持。</span>
        </div>
        <div className={'pic'}>
          <img
            alt="商情分析-图片一"
            title="商情分析-图片一"
            src={require('./../../assets/imgs/Research/intelligence/sqfx.png')}
          />
          <div className={'describe'}>
            {[
              {
                title: '初级阶段',
                content:
                  '定义为情报收集阶段，主要收集不同类型的数据、资料以及其他信息为客户提供各阶段的决策支持。',
              },
              {
                title: '中级阶段',
                content:
                  '定义为研究分析阶段，通过以上情报收集，研究分析各阶段发展的原因、态势，给予客户最直接的结果。',
              },
              {
                title: '高级阶段',
                content:
                  '定义为咨询规划阶段，通过以上研究分析结果，结合相关研究模型，提供客户最优质的建议。',
              },
            ].map(({ title, content }) => (
              <div key={title}>
                <h4>{title}</h4>
                <p>{content}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="intelligence-info">
          {info.map(({ key, icon, title, content }) => (
            <div key={key}>
              <div>
                <span>{title}</span>
                <p>{content}</p>
                <p onClick={() => this.showModal(key)}>
                  <span>查看样例</span>
                </p>
              </div>
              <img alt="" title="" className={key} src={icon} />
            </div>
          ))}
        </div>
        <Modal
          wrapClassName={'common-modal intelligence-modal'}
          centered
          title={title}
          visible={visible}
          footer={null}
          onCancel={() => this.setState({ visible: false })}
        >
          {html}
        </Modal>
      </div>
    )
  }
}

export default ResearchIntelligence
