import React from 'react'
import './foot.scss'
import { Tabs } from 'antd'

export default class Foot extends React.Component {
  // componentDidMount() {
  //   $('.foot-branch span').click(e => {
  //     const $eIndex = $(e.target).index(),
  //       $ct = $('.foot-address').eq($(e.target).index())
  //     if ($ct.css('display') !== 'block') {
  //       $('.foot-branch span').removeClass('active')
  //       $(e.target).addClass('active')
  //       $('.foot-address').slideUp(200)
  //       $ct.slideDown(200)
  //     }
  //   })
  // }
  render() {
    return (
      <div id="foot">
        <ul className="foot-main">
          <li className="foot-link">
            <div className="foot-entrance">快速入口</div>
            <a href="/System/CamPMS">CAM系统</a>
            <a href="/CamData">CAM研究</a>
            <a href="/CamAPP">CAM移动端</a>
            {/* <a href="/Repository">CAM知识库</a> */}
            <a href="/Aboutus">关于我们</a>
          </li>
          <li className="foot-link">
            <div className="foot-entrance">系统链接</div>
            <a href="/System/CamPMS">多维分析平台</a>
            <a href="/System/CamAI">人工智能平台</a>
            <a href="/System/CamBI">敏捷分析平台</a>
            <a href="/System/CamINFO">信息平台</a>
            <a href="/System/CamNEV">新能源平台</a>
          </li>
          <li className="foot-info">
            <div className="foot-contact">联系我们</div>
            <div className="foot-email">E-mail：xifuz@chinaautomarket.com</div>
            <div className="foot-branch">
              <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="上海 ( 总部 )" key="1">
                  <div className="foot-address sh">
                    +8621-64811040 &nbsp; 135-1210-2701
                    <br />
                    上海市中山西路2025号永升大厦518室
                    <br />
                    <p>Room 518, No.2025 Yongsheng Building, West Zhongshan Rd, Shanghai, China</p>
                  </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab="北京" key="2">
                  <div className="foot-address bj">
                    +8610-5743 7122
                    <br />
                    北京市朝阳区工体东路20号百富国际大厦A座7D
                    <br />
                    <p>
                      Rm7D, Baifu International Building A, No.20 Gongti East Road, Chaoyang,
                      Beijing, China
                    </p>
                  </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab="无锡" key="3">
                  <div className="foot-address wx">
                    +0510-6696 8832
                    <br />
                    无锡市新吴区启迪协信中心6号楼1101室
                    <br />
                    <p>Room 1101, Building 6, Qidi Xiexin Center, Xinwu District, Wuxi City</p>
                  </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab="武汉" key="4">
                  <div className="foot-address wh">
                    湖北省武汉市东湖新技术开发区金融港四路中原电子民品园10号楼1楼102室
                    <br />
                    <p>
                      Room102, 1f, building 10, Zhongyuan Electronic civil products Park, No.4
                      Financial Harbor Road, Donghu New Technology Development Zone, Wuhan, Hubei
                    </p>
                  </div>
                </Tabs.TabPane>
              </Tabs>
            </div>
          </li>
          <li className="foot-wx">
            <img alt="公众号" title="公众号" src={require('./../../assets/imgs/home/wx.png')}></img>
            <br />
            <p>微信公众号</p>
          </li>
        </ul>
        <div className="copyRight">
          <a href="http://beian.miit.gov.cn/" target={'view_window'}>
            CopyRight@ 2000-2019 ChinaAutoMarket. All rights reserved 沪ICP备19016403号-1
          </a>
        </div>
      </div>
    )
  }
}
