import React, { Component } from 'react'
import { Tabs } from 'antd'
import './tabbar.scss'

const { TabPane } = Tabs

class TabInfo1 extends Component {
  render() {
    return (
      <div className="tab-info">
        <img src={require('./../../assets/imgs/home/TechStr/Enter_cert2.png')} />
        <img src={require('./../../assets/imgs/home/TechStr/Enter_cert1.png')} />
        <img src={require('./../../assets/imgs/home/TechStr/Enter_cert3.png')} />
      </div>
    )
  }
}
class TabInfo2 extends Component {
  render() {
    return (
      <div className="tab-info2">
        <img src={require('./../../assets/imgs/home/TechStr/NOA.png')} />
      </div>
    )
  }
}

class TabInfo3 extends Component {
  render() {
    return (
      <div className="tab-info3">
        <ul>
          <li>
            <img src={require('./../../assets/imgs/home/TechStr/certificate1.png')} />
            <img src={require('./../../assets/imgs/home/TechStr/certificate2.png')} />
            <img src={require('./../../assets/imgs/home/TechStr/certificate3.png')} />
            <img src={require('./../../assets/imgs/home/TechStr/certificate4.png')} />
            <img src={require('./../../assets/imgs/home/TechStr/certificate5.png')} />
            <img src={require('./../../assets/imgs/home/TechStr/certificate6.png')} />
            <img src={require('./../../assets/imgs/home/TechStr/certificate7.png')} />
            <img src={require('./../../assets/imgs/home/TechStr/certificate7.png')} />
          </li>
          <li>
            <img src={require('./../../assets/imgs/home/TechStr/certificate8.png')} />
            <img src={require('./../../assets/imgs/home/TechStr/certificate9.png')} />
            <img src={require('./../../assets/imgs/home/TechStr/certificate10.png')} />
            <img src={require('./../../assets/imgs/home/TechStr/certificate11.png')} />
            <img src={require('./../../assets/imgs/home/TechStr/certificate12.png')} />
            <img src={require('./../../assets/imgs/home/TechStr/certificate13.png')} />
            <img src={require('./../../assets/imgs/home/TechStr/certificate14.png')} />
            <img src={require('./../../assets/imgs/home/TechStr/certificate14.png')} />
          </li>
        </ul>
      </div>
    )
  }
}

export default class TabBar extends Component {
  render() {
    return (
      <div id="layout">
        <div id="menu">
          <div className="menu-main">
            <Tabs defaultActiveKey="1">
              <TabPane tab="国家高新技术企业" key="1">
                <div className="content">
                  <TabInfo1 />
                </div>
              </TabPane>
              <TabPane tab="ISO质量管理认证" key="2">
                <div className="content">
                  <TabInfo2 />
                </div>
              </TabPane>
              <TabPane tab="众多软件著作权" key="3">
                <div className="content">
                  <TabInfo3 />
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    )
  }
}
