import React from "react";

class CpResearch extends React.Component {
    componentDidMount() {
        $(function () {
            $('#cpResearchBanner').terseBanner({
                adaptive: true,
                auto: 4000,
                arrow: true,
            });
        });
    }


    render() {
        return <div id={"cpResearch"}>
            <div id="cpResearchBanner" className={"banner custom"}>
                <ul>
                    <li className="mb_item">
                        <img alt="产品型谱研究报告一" title="产品型谱研究报告一" data-src={require("../../../assets/imgs/Research/industry/cam_sq32.jpg")}/>
                    </li>
                    <li className="mb_item">
                        <img alt="产品型谱研究报告二" title="产品型谱研究报告二" data-src={require("../../../assets/imgs/Research/industry/cam_sq33.jpg")}/>
                    </li>
                </ul>
            </div>
        </div>
    }
}

export default CpResearch;