import React from 'react'
import './banner.scss'

class Banner extends React.Component {
  componentWillUnmount() {
    document.body.scrollTop = document.documentElement.scrollTop = 1
  }

  componentDidMount() {
    if (!/msie [6|7|8|9]/i.test(navigator.userAgent)) {
      ;(function () {
        var config = {
          after: '0s',
          enter: 'bottom',
          move: '77px',
          over: '0.66s',
          easing: 'ease-in-out',
          viewportFactor: 0.33,
          reset: false,
          init: true,
        }
        setTimeout(() => {
          var sr = new scrollReveal(config)
        }, 300)
      })()
    }
    $(function () {
      $('#camBanner').terseBanner({
        adaptive: true,
        auto: 4000,
        arrow: true,
        init: function () {
          let _self = this
          setTimeout(function () {
            _self.$item.eq(0).find('.sy_txt1').animate(
              {
                top: '40%',
                opacity: 1,
              },
              {
                easing: 'easeInOutBack',
                duration: 1000,
              }
            )
          }, 200)
        },
        before: function (currentIndex) {
          //文字动画初始状态
          let $item = this.$item.eq(currentIndex).find('.sy_txt1')
          $item.stop()
          switch (currentIndex) {
            case 0:
              $item.animate({
                top: '0%',
                opacity: 0,
              })
              break
            case 1:
              $item.animate({
                top: '0%',
                opacity: 0,
              })
              break
            case 2:
              $item.animate({
                bottom: '0%',
                opacity: 0,
              })
              break
          }
        },
        after: function (currentIndex) {
          //文字动画最终状态
          let $item = this.$item.eq(currentIndex).find('.sy_txt1')
          $item.stop()
          switch (currentIndex) {
            case 0:
              $item.animate(
                {
                  top: '40%',
                  opacity: 1,
                },
                {
                  easing: 'easeInOutBack',
                  duration: 1000,
                }
              )
              break
            case 1:
              $item.animate(
                {
                  top: '40%',
                  opacity: 1,
                },
                {
                  easing: 'easeInOutBack',
                  duration: 1000,
                }
              )
              break
            case 2:
              $item.animate(
                {
                  bottom: '35%',
                  opacity: 1,
                },
                {
                  easing: 'easeInOutBack',
                  duration: 1000,
                }
              )
              break
          }
        },
      })
    })
  }

  componentWillUnmount() {
    let banner = $('#camBanner').data('terseBanner')
    if (banner.stop) banner.stop() //清除定时器
    $('#camBanner').data('terseBanner', undefined)
  }

  jumpHome = () => {
    let o = document.getElementById('camBanner')
    let h = o.clientHeight || o.offsetHeight
    $('html,body').animate({ scrollTop: h }, 500)
  }

  render() {
    return (
      <div id="camBanner" className="banner luxy-el">
        <ul>
          <li className="mb_item">
            <img
              alt="首页-PMS平台"
              title="首页-PMS平台"
              data-src={require('./../../assets/imgs/banner/top_banner.png')}
            />
            <div className="sy_txt1 rank1">
              <img className="title" src={require('../../assets/imgs/banner/pms.png')} />
              <span className="line2">
                融合多维多源数据，借助深度立体量化分析引擎，为多元化商业场景下的各类决策提供系统化、平台化的数据及应用支撑。
              </span>
              <a className="more" href="/System/CamPMS">
                <p className="url">了解更多</p>
                <img src={require('../../assets/imgs/banner/more.png')} />
              </a>
            </div>
          </li>
          <li className="mb_item">
            <img
              alt="首页-BI平台"
              title="首页-BI平台"
              data-src={require('./../../assets/imgs/banner/top_banner2.png')}
            />
            <div className="sy_txt1 rank2">
              <img className="title" src={require('../../assets/imgs/banner/reach.png')} />
              <span className="line2">
                CAM研究包括了商情分析、产品研究、行业观察、市场预测、消费者调研和专案咨询；实时跟踪车市动态，全面、准确的数据为客户决策提供支持。
              </span>
              <a className="more" href="/Research/Intelligence">
                <p className="url">了解更多</p>
                <img src={require('../../assets/imgs/banner/more.png')} />
              </a>
            </div>
          </li>
          {/* <li className="mb_item">
            <img
              alt="首页-新能源平台"
              title="首页-新能源平台"
              data-src={require('./../../assets/imgs/banner/sy_banner_nev01.jpg')}
            />
            <div className="sy_txt1 rank3">
              CAM&nbsp;NEV新能源平台
              <span className="line2">
                新能源平台将量价数据、三电数据、充电桩数据、行业重点
                <br />
                政策及热点动态信息聚合，为新能源厂商提供一体化解决方案。
              </span>
              <a className="more" href="/System/CamNEV">
                <p className="url">了解更多</p>
                <img src={require('../../assets/imgs/banner/more.png')} />
              </a>
            </div>
          </li> */}
        </ul>
      </div>
    )
  }
}

export default Banner
