import React from 'react'
import './sideBar.scss'

export default class SideBar extends React.Component {
  componentDidMount() {
    let st
    $(window).scroll(e => {
      clearTimeout(st)
      st = setTimeout(() => {
        let scrollTop = $(window).scrollTop()
        if (scrollTop > 300) {
          $('.scrollTo').css('opacity', 1)
        } else {
          $('.scrollTo').css('opacity', 0)
        }
      }, 25)
    })
  }

  scrollTop = () => {
    $('html,body').animate({ scrollTop: 0 }, 500)
  }

  render() {
    return (
      <ul id="sideBar">
        <li className="contact">
          <div>
            <p>
              <img alt="联系我们" title="联系我们" src={require('./../../assets/imgs/phone.png')} />
            </p>
            <p className="imgBtn" title="联系我们">
              联系
              <br />
              电话
            </p>
          </div>
          <div className="content">
            <p>
              您好！
              <br />
              商务咨询请联系
            </p>
            <p>
              <span>上海（总部）</span>
            </p>
            <p>+8621-6481 1040</p>
            <p>13512102701</p>
            {/* <p><span>北京：</span><br />+8610-5743 7122</p>
                    <p><span>无锡：</span><br />+0510-6696 8832</p> */}
          </div>
        </li>
        <li className="codeImg">
          <div>
            <p>
              <img alt="二维码" title="二维码" src={require('./../../assets/imgs/code.png')} />
            </p>
            <p className="imgBtn" title="二维码">
              微信
              <br />
              扫码
            </p>
          </div>
          <div className="content">
            <p>关注微信公众号</p>
            <p>
              <img alt="二维码" title="二维码" src={require('./../../assets/imgs/wx_code.png')} />
            </p>
          </div>
        </li>
        <li className="scrollTo" onClick={this.scrollTop}>
          <div>
            <p className="imgBtn">
              <img
                alt="返回顶部"
                title="返回顶部"
                src={require('./../../assets/imgs/arrows.png')}
              />
            </p>
          </div>
        </li>
      </ul>
    )
  }
}
