export let pms = [
    {
        value: '仪表盘',
        label: '仪表盘',
    },
    {
        value: 'pms多维分析',
        label: '多维分析',
    },
    {
        value: '产品',
        label: '产品',
    },
    {
        value: '销量',
        label: '销量',
    },
    {
        value: '价格',
        label: '价格',
    },
    {
        value: 'pms商务政策',
        label: '商务政策',
    },
    {
        value: '模型',
        label: '模型',
    }
];
export let nev = [
    {
        value: '市场分析',
        label: '市场分析',
    },
    {
        value: '核心三电',
        label: '核心三电',
    },
    {
        value: '行业政策',
        label: '行业政策',
    },
    {
        value: '产品分析',
        label: '产品分析',
    },
    {
        value: '商务政策',
        label: '商务政策',
    },
    {
        value: '充电桩',
        label: '充电桩',
    },
    {
        value: '多维分析',
        label: '多维分析',
    }

];
export let info = [
    {


        value: '行业政策动向',
        label: '行业政策动向',
    },
    {
        value: '宏观经济动态',
        label: '宏观经济动态',
    },
    {
        value: '行业重大事件',
        label: '行业重大事件',
    },
    {
        value: '车企重要动态',
        label: '车企重要动态',
    },
    {
        value: '后市场聚焦',
        label: '后市场聚焦',
    },
    {
        value: '零部件关注',
        label: '零部件关注',
    },
    {
        value: '新能源专栏',
        label: '新能源专栏',
    },
    {
        value: '新能源政策',
        label: '新能源政策',
    },
    {
        value: '其他新闻概要',
        label: '其他新闻概要',
    }
    ,
    {
        value: '数据维护下载',
        label: '数据维护下载',
    }

];
export let bi = [
    {
        label: "Product Ladder",
        value: "Product Ladder",
    },
    {
        label: "销量快速查询",
        value: "销量快速查询",
    },
    {
        label: "销量爬坡 (改款)",
        value: "销量爬坡 (改款)",
    },
    {
        label: "配置优劣势对比",
        value: "配置优劣势对比",
    },
    {
        label: "动力等级梯度",
        value: "动力等级梯度",
    },
    {
        label: "价量关系",
        value: "价量关系",
    },
    {
        label: "销量爬坡",
        value: "销量爬坡",
    },
    {
        label: "新老款对比（堆叠符号） (导航)",
        value: "新老款对比（堆叠符号） (导航)",
    },
    {
        label: "可视化",
        value: "可视化",
    },
    {
        label: "设计套装差异",
        value: "设计套装差异",
    },
    {
        label: "优劣势对比",
        value: "优劣势对比",
    },
    {
        label: "老款主销产品策略",
        value: "老款主销产品策略",
    },
    {
        label: "分车系型谱",
        value: "分车系型谱",
    },
    {
        label: "细分市场格局",
        value: "细分市场格局",
    },
    {
        label: "Product Ladder (单套装)",
        value: "Product Ladder (单套装)",
    },
    {
        label: "下载格式",
        value: "下载格式",
    },
    {
        label: "下载格式 (2)",
        value: "下载格式 (2)",
    }, {
        label: "价量分布",
        value: "价量分布",
    },
    {
        label: "ICE子级别校验-截止201809",
        value: "ICE子级别校验-截止201809",
    }, {
        label: "配置梯度-任选对比",
        value: "配置梯度-任选对比",
    }, {
        label: "Product Ladder (单套装) -切换维度",
        value: "Product Ladder (单套装) -切换维度",
    }, {
        label: "配置解释及介绍",
        value: "配置解释及介绍",
    }, {
        label: "Product Ladder（多套装）",
        value: "Product Ladder（多套装）",
    }, {
        label: "Product Ladder（任选对比）",
        value: "Product Ladder（任选对比）",
    }
];


