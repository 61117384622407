import React from 'react'
import './example.scss'

import BannerIP from '../../components/bannerInsidePages/bannerInsidePages'

class Example extends React.Component {
  render() {
    document.title = '成功案例'
    return (
      <div id="page-example">
        <BannerIP type={'example'} />
        <div className="pf">
          <div className="title">
            案<span>例精</span>选
          </div>
          <ul className="item luxy-el">
            <li data-scroll-reveal="enter bottom  over 0.5s">
              <a href="/Example1">
                <div className="txt">
                  <div className="al">案例一</div>
                </div>
                <div className="name">产品装备竞争力</div>
              </a>
            </li>
            <li data-scroll-reveal="enter bottom  over 0.6s after 0.5s ">
              <a href="/Example2">
                <div className="txt">
                  <div className="al">案例二</div>
                </div>
                <div className="name">自主高端品牌市场性及应对研究</div>
              </a>
            </li>
            {/* <li data-scroll-reveal="enter bottom  over 1s after 1.5s ">
                        <img src={require("./../../assets/imgs/example/al03.jpg")} />
                        <div className="txt">
                            <div className="al">案例三</div><div className="name">自主高端品牌市场性及应对研究</div>
                        </div>
                    </li>
                    <li data-scroll-reveal="enter bottom  over 0.8s  after 0.5s ">
                        <img src={require("./../../assets/imgs/example/al04.jpg")} />
                        <div className="txt">
                            <div className="al">案例四</div><div className="name">自主高端品牌市场性及应对研究</div>
                        </div>
                    </li>
                    <li data-scroll-reveal=" enter bottom  over 0.8s  after 1.3s  ">
                        <img src={require("./../../assets/imgs/example/al05.jpg")} />
                        <div className="txt">
                            <div className="al">案例五</div><div className="name">自主高端品牌市场性及应对研究</div>
                        </div>
                    </li> */}
            <li data-scroll-reveal="enter right  over 0.9s after 0.5s ">
              <a href="#">
                <div className="name">
                  了解更多案例
                  <br />
                  请联系
                  <br />
                  xifuz@chinaautomarket.com
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}
export default Example
