import React from 'react'
import './example1.scss'
import BannerIP from './../../components/bannerInsidePages/bannerInsidePages'

class Example1 extends React.Component {
  render() {
    document.title = '成功案例一'
    return (
      <div id="page-example1">
        <BannerIP type={'example1'} />
        <div className="ct">
          <div className="title">案例背景</div>
          <div className="con">
            某企业需研究对比旗下车型与对标竞品产品配置竞争力，并期望了解新兴装备车型搭载情况。
          </div>
          <div className="exa-div">
            <div className="title2">解决方案</div>
            <div className="content-div">
              <div className="content">
                <div className="content-title">充分了解客户所需的装备需求</div>
                <div className="content-inner1">
                  <div className="inner1-block">
                    <div data-scroll-reveal=" enter right  over .5s after 0.1s ">
                      CAM装备数据库 900+字段
                    </div>
                  </div>
                  <div className="inner1-line">
                    <div>结合CAM推荐和客户自身需求遴选满足装备竞争力研究的字段</div>
                  </div>
                  <div className="inner1-block">
                    <div data-scroll-reveal=" enter right  over .6s after 0.3s">客户基础装备库</div>
                  </div>
                  <div className="inner1-line">
                    <div>新增客户专有字段和新兴趋势性字段，完善装备库</div>
                  </div>
                  <div className="inner1-block">
                    <div data-scroll-reveal=" enter right  over .6s after 0.5s">
                      为客户定制
                      <br />
                      完整装备库
                    </div>
                  </div>
                </div>
                <div className="content-title">模拟构建专属字段</div>
                <div className="content-inner2">
                  <img alt="" title="" width="" height="" src={require('imgs/example1/mxgj.png')} />
                  <div>
                    <div className="inner2-text1">
                      搭建字段
                      <br />
                      逻辑关系
                    </div>
                    <div
                      className="inner2-text1"
                      data-scroll-reveal=" enter right  over .4s  after .3s"
                    >
                      字段虚拟赋值
                    </div>
                    <div
                      className="inner2-text1"
                      data-scroll-reveal=" enter right  over .4s  after .4s"
                    >
                      构建产品竞争力
                      <br />
                      计算模型
                    </div>
                    <div
                      className="inner2-text1"
                      data-scroll-reveal=" enter right  over .4s  after .6s"
                    >
                      市场装备价值感
                      <br />
                      联合调研
                    </div>
                    <div
                      className="inner2-text1"
                      data-scroll-reveal=" enter right  over .4s  after .9s"
                    >
                      客户专属装备
                      <br />
                      字段赋值
                    </div>
                    <div className="inner2-text2">区分字段性质及等级</div>
                    <div className="inner2-text2">
                      模拟赋值计算
                      <br />
                      验证模型可靠性
                    </div>
                    <div className="inner2-text2">
                      依据客户需求
                      <br />
                      进行市场调研
                    </div>
                    <div className="inner2-text2">
                      获取各细分市场
                      <br />
                      最新装备感知价值
                    </div>
                  </div>
                </div>
                <div className="content-title">定制导入客户数据，为其拓展系统功能</div>
                <div className="content-inner3">
                  <div className={'customer-div'}>
                    <p>
                      <img
                        alt=""
                        title=""
                        width=""
                        height=""
                        src={require('imgs/example1/sys.png')}
                      />
                    </p>
                    <p>
                      客户定制
                      <br />
                      装备库
                    </p>
                  </div>
                  <div>客户数据导入</div>
                  <div>
                    <p>
                      <img
                        alt=""
                        title=""
                        width=""
                        height=""
                        src={require('imgs/example1/data.png')}
                      />
                    </p>
                    <p>
                      <span>便捷查询系统</span>
                      <span>车型装备价值实时对比</span>
                    </p>
                  </div>
                  <div>功能拓展</div>
                  <div>
                    <div>
                      <p>结合车型派生</p>
                      <p data-scroll-reveal=" enter right  over .3s after .3s">
                        市场装备率研究模型
                      </p>
                    </div>
                    <div>
                      <p>结合销量数据</p>
                      <p data-scroll-reveal=" enter right  over .4s after .5s">
                        市场装车率研究模型
                      </p>
                    </div>
                    <div>
                      <p>结合历史装备数据</p>
                      <p data-scroll-reveal=" enter right  over .3s after .8s">
                        单一装备趋势研究模型
                      </p>
                    </div>
                    <div>
                      <p>特定用户应用场景</p>
                      <p data-scroll-reveal=" enter right  over .3s after 1s">其他定制研究模型</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="title3">案例总结</div>
          <div className={'summary-div'}>
            <ul className="summary">
              <li data-scroll-reveal=" enter right  over .5s move 200px">
                车型装备更新，解决企业产品装备竞争力对比问题
              </li>
              <li data-scroll-reveal=" enter right  over .66s move 200px after 0.4s">
                持续合作多年，客户装备库不断更新完善
              </li>
              <li data-scroll-reveal=" enter right  over .77s move 200px after 0.8s">
                为客户定制提供多场景分析模型，解决日常工作问题
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default Example1
