import React from "react";

class JpTrack extends React.Component {
    componentDidMount() {
        $(function () {
            $('#jpTrackBanner').terseBanner({
                adaptive: true,
                auto: 4000,
                arrow: true,
            });
        });
    }



    render() {
        return <div id={"jpTrack"}>
            <div id="jpTrackBanner" className={"banner custom"}>
                <ul>
                    <li className="mb_item">
                        <img alt="图片一" title="图片一" src={require("../../../assets/imgs/Research/industry/cam_sq37.jpg")}/>
                    </li>
                    <li className="mb_item">
                        <img alt="图片二" title="图片二" src={require("../../../assets/imgs/Research/industry/cam_sq38.jpg")}/>
                    </li>
                </ul>
            </div>
        </div>
    }
}

export default JpTrack;