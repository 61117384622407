import React from 'react'

class Sales extends React.Component {
  componentDidMount() {
    $(function () {
      $('#salesBanner').terseBanner({
        adaptive: true,
        auto: 4000,
        arrow: true,
      })
    })
  }

  render() {
    return (
      <div id={'sales'}>
        <div id="salesBanner" className={'banner custom'}>
          <ul>
            <li className="mb_item" style={{ height: '18rem' }}>
              <img
                alt="销量研究一"
                title="销量研究一"
                data-src={require('../../../assets/imgs/Research/intelligence/cam_sq01.png')}
              />
            </li>
            <li className="mb_item" style={{ height: '18rem' }}>
              <img
                alt="销量研究二"
                title="销量研究二"
                data-src={require('../../../assets/imgs/Research/intelligence/cam_sq02.png')}
              />
            </li>
            <li className="mb_item" style={{ height: '18rem' }}>
              <img
                alt="销量研究三"
                title="销量研究三"
                data-src={require('../../../assets/imgs/Research/intelligence/cam_sq03.png')}
              />
            </li>
            <li className="mb_item" style={{ height: '18rem' }}>
              <img
                alt="销量研究四"
                title="销量研究四"
                data-src={require('../../../assets/imgs/Research/intelligence/cam_sq04.png')}
              />
            </li>
            <li className="mb_item" style={{ height: '18rem' }}>
              <img
                alt="销量研究五"
                title="销量研究五"
                data-src={require('../../../assets/imgs/Research/intelligence/cam_sq05.png')}
              />
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

export default Sales
