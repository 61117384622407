import React from 'react'
import './camData.scss'
import BannerIP from '../../components/bannerInsidePages/bannerInsidePages'

class CamData extends React.Component {
  render() {
    document.title = 'CAM数据'
    return (
      <div id="page-camData">
        <BannerIP type={'data'} />
        <div className={'data-info'}>
          <div className="title">CAM Data 基础数据</div>
          <div className="block-box">
            <div className="block" data-scroll-reveal="enter  right  over 0.5s">
              <p>
                <img
                  alt="数据资源丰富"
                  title="数据资源丰富"
                  src={require('./../../assets/imgs/CamData/camdata1.png')}
                />
              </p>
              <h4>数据资源丰富</h4>
              <p>十多年的终端信息、行业数据、企业研究、厂商情报等持续跟踪收集</p>
            </div>
            <div className="block" data-scroll-reveal="  enter right  over 0.7s after 0.5s ">
              <p>
                <img
                  alt="市场监测反馈"
                  title="市场监测反馈"
                  src={require('./../../assets/imgs/CamData/camdata2.png')}
                />
              </p>
              <h4 data-scroll-reveal=" enter  right over 0.44s after 0.2s ">市场监测反馈</h4>
              <p data-scroll-reveal=" enter  right over 0.77s after 0.2s ">
                超千家的合作终端渠道，保障了数据来源的及时、准确和全面
              </p>
            </div>
            <div className="block" data-scroll-reveal="  enter right  over 1s  after .7s ">
              <p>
                <img
                  alt="标准化数据整理"
                  title="标准化数据整理"
                  src={require('./../../assets/imgs/CamData/camdata3.png')}
                />
              </p>
              <h4 data-scroll-reveal=" enter  right   over 0.5s  after 0.2s">标准化数据整理</h4>
              <p data-scroll-reveal=" enter  right   over 0.77s  after 0.2s">
                专业的标准化的数据清洗流程，确保数据的准确性{' '}
              </p>
            </div>
            <div className="block" data-scroll-reveal="  enter right  over 0.8s  after 1s ">
              <p>
                <img
                  alt="数据价值提升"
                  title="数据价值提升"
                  src={require('./../../assets/imgs/CamData/camdata4.png')}
                />
              </p>
              <h4 data-scroll-reveal=" enter  right  over .66s after .4s">数据价值提升</h4>
              <p data-scroll-reveal=" enter  right  over .66s after .5s">
                构建多维平台，交叉关联分析，挖掘更多数据资源
              </p>
            </div>
          </div>
          <div className="data-content">
            <div>
              <img
                alt="CAM大数据"
                title="CAM大数据"
                src={require('./../../assets/imgs/CamData/CAM-Data1.png')}
              />
            </div>
            <div className="explain" data-scroll-reveal="  enter bottom  over 0.7s after 0.5s ">
              <p>
                CAM运用大数据思维，积累丰富的历史数据资源、终端渠道资源和代理商资源，依托数据集成和整体化架构，形成强大的数据资源中心。
              </p>
              <p>
                CAM数据资源涵盖价格、销量、促销、配置和用户；终端渠道资源全国已超千家，价格监测城市已覆盖全国省会并下探三、四线城市；开展长期战略合作、符合CAM专业要求和执行标准的代理商资源全国已达60多家。
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CamData
