import React from 'react'
import { Tabs } from 'antd'
import './industry.scss'
import EconomicResearch from '../../components/research/industry/economicResearch'
import HotspotResearch from '../../components/research/industry/hotspotResearch'
import HotspotObservation from '../../components/research/industry/hotspotObservation'
import PolicyResearch from '../../components/research/industry/policyResearch'
import AutomobileTrends from '../../components/research/industry/atomobileTrends'
class ResearchIndustry extends React.Component {
  render() {
    return (
      <div id="page-industry">
        <div className="title">CAM行业观察常规研究框架体系</div>
        <div className="chart">
          <img
            alt="CAM行业观察常规研究框架体系"
            title="CAM行业观察常规研究框架体系"
            src={require('./../../assets/imgs/Research/industry/yjkj.png')}
          />
        </div>

        <div className="nav-bj">
          <div className={'nav'}>
            <Tabs defaultActiveKey="1">
              <Tabs.TabPane tab="宏观经济研究" key="1">
                <EconomicResearch />
              </Tabs.TabPane>
              <Tabs.TabPane tab="宏观热点研究" key="2">
                <HotspotResearch />
              </Tabs.TabPane>
              <Tabs.TabPane tab="行业热点观察" key="3">
                <HotspotObservation />
              </Tabs.TabPane>
              <Tabs.TabPane tab="行业政策研究" key="4">
                <PolicyResearch />
              </Tabs.TabPane>
              <Tabs.TabPane tab="车企重要动向" key="5">
                <AutomobileTrends />
              </Tabs.TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    )
  }
}
export default ResearchIndustry
