import React from 'react'
import './Info.scss'
import Modal from '@/components/modal'
import { withRouter } from 'react-router-dom'
class CamInfo extends React.Component {
  applicate = () => {
    Modal.ontrial({
      visible: true,
      platform: this.props.history.location.pathname.split('/')[2],
    })
  }

  render() {
    return (
      <div id="page-CamInfo">
        <div className="base luxy-el">
          <div className="title">CAM INFO &nbsp;信息平台</div>
          <div className="describe">
            通过技术工具，打造CAM汽车行业信息平台，从而形成完整的汽车行业情报信息监测体系，支撑研究业务向纵深发展，满足客户的多样化、及时性业务需求。
          </div>
          <a className="applyFor" onClick={this.applicate}>
            申请试用
          </a>
          <div className="ct">
            {/* <div className="title1">
              <i></i>基础功能
            </div> */}
            <div className={'basicsFun'}>
              <div>
                <div className="car-box">
                  <span>爬虫技术</span>
                  <a>
                    <img
                      alt="爬虫技术"
                      title="爬虫技术"
                      src={require('./../../assets/imgs/CamInfo/camIcon1.png')}
                    />
                  </a>
                </div>
                {/* <p className={'jt'}>
                  <a>
                    <img alt="" title="" src={require('./../../assets/imgs/CamInfo/jt.png')} />
                  </a>
                </p> */}
                <div className="car-box">
                  <span>关键词</span>
                  <a>
                    <img
                      alt="关键词"
                      title="关键词"
                      src={require('./../../assets/imgs/CamInfo/camIcon2.png')}
                    />
                  </a>
                </div>
              </div>
              <div className="ui_base u_p3d">
                <div className="ball_c">
                  <div className="mk5">
                    <div className="img05">
                      <img
                        alt="INFO动图"
                        title="INFO动图"
                        src={require('./../../assets/imgs/sysMask.png')}
                      />
                    </div>
                    <div className="img07">
                      <img
                        alt="INFO动图"
                        title="INFO动图"
                        src={require('./../../assets/imgs/sysMay.png')}
                      />
                    </div>
                    <div className="img06">
                      <img
                        alt="INFO动图"
                        title="INFO动图"
                        src={require('./../../assets/imgs/CamInfo/INFO3.png')}
                      />
                    </div>
                    <div className="light">
                      <div className="img img7">
                        <img
                          alt="INFO动图"
                          title="INFO动图"
                          src={require('./../../assets/imgs/light.png')}
                        />
                      </div>
                      <div className="img img8">
                        <img
                          alt="INFO动图"
                          title="INFO动图"
                          src={require('./../../assets/imgs/light.png')}
                        />
                      </div>
                      <div className="img img9">
                        <img
                          alt="INFO动图"
                          title="INFO动图"
                          src={require('./../../assets/imgs/light.png')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="base u_p3d">
                  <div className="pan"></div>
                  <div className="ball_base u_p3d ball_1">
                    <div className="ball">后市场</div>
                  </div>
                  <div className="ball_base u_p3d ball_2">
                    <div className="ball">零部件</div>
                  </div>
                  <div className="ball_base u_p3d ball_3">
                    <div className="ball">车型信息</div>
                  </div>
                  <div className="ball_base u_p3d ball_4">
                    <div className="ball">新能源</div>
                  </div>
                  <div className="ball_base u_p3d ball_5">
                    <div className="ball">车企动态</div>
                  </div>
                  <div className="ball_base u_p3d ball_6">
                    <div className="ball">行业事件</div>
                  </div>
                  <div className="ball_base u_p3d ball_7">
                    <div className="ball">行业政策</div>
                  </div>
                  <div className="ball_base u_p3d ball_8">
                    <div className="ball">宏观经济</div>
                  </div>
                </div>
              </div>
              {/* <div className="ui_base u_p3d">
                <div className="ball_c" />
                <div className="base u_p3d">
                  <div className="pan"></div>
                  <div className="ball_base u_p3d ball_1">
                    <div className="ball">后市场</div>
                  </div>
                  <div className="ball_base u_p3d ball_2">
                    <div className="ball">零部件</div>
                  </div>
                  <div className="ball_base u_p3d ball_3">
                    <div className="ball">车型信息</div>
                  </div>
                  <div className="ball_base u_p3d ball_4">
                    <div className="ball">新能源</div>
                  </div>
                  <div className="ball_base u_p3d ball_5">
                    <div className="ball">车企动态</div>
                  </div>
                  <div className="ball_base u_p3d ball_6">
                    <div className="ball">行业事件</div>
                  </div>
                  <div className="ball_base u_p3d ball_7">
                    <div className="ball">行业政策</div>
                  </div>
                  <div className="ball_base u_p3d ball_8">
                    <div className="ball">宏观经济</div>
                  </div>
                </div>
              </div> */}
              <div>
                <div className="car-box">
                  <a>
                    <img
                      alt="情报信息库"
                      title="情报信息库"
                      src={require('./../../assets/imgs/CamInfo/camIcon3.png')}
                    />
                  </a>
                  <div>
                    <span>CAM行业</span>
                    <span>情报信息库</span>
                  </div>
                </div>
                {/* <p className={'jt'}>
                  <a>
                    <img src={require('./../../assets/imgs/CamInfo/jt.png')} alt="" />
                  </a>
                </p> */}
                <div className="car-box">
                  <a>
                    <img
                      alt="日报系统"
                      title="日报系统"
                      src={require('./../../assets/imgs/CamInfo/camIcon4.png')}
                    />
                  </a>
                  <div>
                    <span>CAM行业</span>
                    <span>日报系统</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="advantage luxy-el">
          <ul>
            <li data-scroll-reveal="  enter bottom  over 0.5s move 50px">
              <div className="img img1">
                <img
                  alt="庞大数据源支持"
                  title="庞大数据源支持"
                  src={require('./../../assets/imgs/CamInfo/InfoIco1.png')}
                />
              </div>
              <div className="line1">庞大数据源支持</div>
              <div className="line2">大数据技术支撑 信息精准全面</div>
            </li>
            <li data-scroll-reveal="  enter bottom  over 1s move 50px after 0.5s ">
              <div className="img img2">
                <img
                  alt="多字段筛选"
                  title="多字段筛选"
                  src={require('./../../assets/imgs/CamInfo/InfoIco2.png')}
                />
              </div>
              <div className="line1">多字段筛选</div>
              <div className="line2">提供多字段筛选 信息范围可控</div>
            </li>
            <li data-scroll-reveal="  enter bottom  over 1.5s move 50px after .5s ">
              <div className="img img3">
                <img
                  alt="成熟监测体系"
                  title="成熟监测体系"
                  src={require('./../../assets/imgs/CamInfo/InfoIco3.png')}
                />
              </div>
              <div className="line1">成熟监测体系</div>
              <div className="line2">完整的汽车行业情报监测体系</div>
            </li>
            <li data-scroll-reveal="  enter bottom  over 1.5s move 50px after 1s ">
              <div className="img img4">
                <img
                  alt="支持纵深研究"
                  title="支持纵深研究"
                  src={require('./../../assets/imgs/CamInfo/InfoIco4.png')}
                />
              </div>
              <div className="line1">支持纵深研究</div>
              <div className="line2">结合平台系统 支持纵深发展</div>
            </li>
          </ul>
        </div>

        <div className="bright luxy-el">
          <div>
            {/* <div className="title1">平台亮点</div> */}
            <ul>
              <li data-scroll-reveal="  enter bottom  over 0.5s ">
                <div className="img">
                  <img
                    alt="强大的信息监测和抓取平台"
                    title="强大的信息监测和抓取平台"
                    src={require('./../../assets/imgs/CamInfo/info_01.jpg')}
                  />
                </div>
                <div className="line1">
                  <p>强大的</p>信息监测和抓取平台
                </div>
              </li>
              <li data-scroll-reveal="  enter bottom  over 0.5s ">
                <div className="img">
                  <img
                    alt="强大的信息监测和抓取平台"
                    title="强大的信息监测和抓取平台"
                    src={require('./../../assets/imgs/CamInfo/info_01.jpg')}
                  />
                  <img
                    alt="强大的信息监测和抓取平台"
                    title="强大的信息监测和抓取平台"
                    src={require('./../../assets/imgs/CamInfo/info_03.png')}
                  />
                </div>
              </li>
              <li data-scroll-reveal="  enter bottom  over 0.7s after 0.5s ">
                <div className="line1">
                  <p>全面的</p>信息字段梳理系统
                </div>
                <div className="img">
                  <img
                    alt="全面的信息字段梳理系统"
                    title="全面的信息字段梳理系统"
                    src={require('./../../assets/imgs/CamInfo/info_02.jpg')}
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(CamInfo)
