import React from 'react'
import './AI.scss'
import Modal from '@/components/modal'
import { withRouter } from 'react-router-dom'
class CamAI extends React.Component {
  applicate = () => {
    Modal.ontrial({
      visible: true,
      platform: this.props.history.location.pathname.split('/')[2],
    })
  }
  render() {
    return (
      <div id="page-CamAI">
        <div className="base luxy-el">
          <div className="title">CAM AI &nbsp;人工智能平台</div>
          <div className="con">
            CAM AI
            人工智能平台以领先的大数据、机器学习、人工智能等技术为核心，结合实际需求形成舆情监测、口碑分析、竞争群分析、价量预测等应用场景。对人机交互方式进行深入的优化，普通人员也能轻松驾驭的大数据分析平台。
          </div>
          <a className="applyFor" onClick={this.applicate}>
            申请试用
          </a>
          <div className="ct">
            {/* <div className="title1"><i></i>基础功能</div> */}
            <div className="ct1">
              <div className="right">
                <div className="mk mk1">
                  <div className="img">
                    <img
                      alt="大数据分析"
                      title="大数据分析"
                      src={require('./../../assets/imgs/CamAI/camIcon1.png')}
                    />
                  </div>
                  <div className="txt">
                    <div className="line1" data-scroll-reveal=" enter  right  over 0.4s">
                      大数据分析
                    </div>
                    <div className="line2" data-scroll-reveal=" enter  right  over 0.66s">
                      易用的维度分析
                      <br />
                      启发式数据筛选
                      <br />
                      灵活数据预警
                    </div>
                  </div>
                </div>
                <div className="mk mk2">
                  <div className="img">
                    <img
                      alt="交互式应用"
                      title="交互式应用"
                      src={require('./../../assets/imgs/CamAI/camIcon2.png')}
                    />
                  </div>
                  <div className="txt">
                    <div
                      className="line1"
                      data-scroll-reveal=" enter  right over 0.44s after 0.2s "
                    >
                      交互式应用
                    </div>
                    <div
                      className="line2"
                      data-scroll-reveal=" enter  right over 0.77s after 0.2s "
                    >
                      属性分析
                      <br />
                      时间序列分析
                      <br />
                      指标分析 &nbsp; 漏斗分析
                    </div>
                  </div>
                </div>
                <div className="mk mk3">
                  <div className="img">
                    <img
                      alt="数据预测"
                      title="数据预测"
                      src={require('./../../assets/imgs/CamAI/camIcon3.png')}
                    />
                  </div>
                  <div className="txt">
                    <div
                      className="line1"
                      data-scroll-reveal=" enter  right   over 0.5s  after 0.2s"
                    >
                      数据预测
                    </div>
                    <div
                      className="line2"
                      data-scroll-reveal=" enter  right   over 0.77s  after 0.2s"
                    >
                      数值预测算法
                      <br />
                      分类预测算法
                      <br />
                      聚类预测算法
                    </div>
                  </div>
                </div>
                <div className="mk mk4">
                  <div className="img">
                    <img
                      alt="规则分析"
                      title="规则分析"
                      src={require('./../../assets/imgs/CamAI/camIcon4.png')}
                    />
                  </div>
                  <div className="txt">
                    <div className="line1" data-scroll-reveal=" enter  right  over .66s after .4s">
                      规则分析
                    </div>
                    <div className="line2" data-scroll-reveal=" enter  right  over .66s after .5s">
                      历史数据规则分析
                      <br />
                      实时数据规则分析
                    </div>
                  </div>
                </div>
                <div className="mk5">
                  <div className="img05">
                    <img
                      alt="AI动图"
                      title="AI动图"
                      src={require('./../../assets/imgs/sysMask.png')}
                    />
                  </div>
                  <div className="img07">
                    <img
                      alt="AI动图"
                      title="AI动图"
                      src={require('./../../assets/imgs/sysMay.png')}
                    />
                  </div>
                  <div className="img06">
                    <img
                      alt="AI动图"
                      title="AI动图"
                      src={require('./../../assets/imgs/CamAI/CAMAI3.png')}
                    />
                  </div>
                  <div className="light">
                    <div className="img img7">
                      <img
                        alt="AI动图"
                        title="AI动图"
                        src={require('./../../assets/imgs/light.png')}
                      />
                    </div>
                    <div className="img img8">
                      <img
                        alt="AI动图"
                        title="AI动图"
                        src={require('./../../assets/imgs/light.png')}
                      />
                    </div>
                    <div className="img img9">
                      <img
                        alt="AI动图"
                        title="AI动图"
                        src={require('./../../assets/imgs/light.png')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="advantage luxy-el">
          {/* <div className="title1">
            <i></i>平台优势
          </div> */}
          <ul>
            <li data-scroll-reveal="  enter bottom  over 0.5s move 50px">
              <div className="img img1">
                <img
                  alt="大数据分析能力"
                  title="大数据分析能力"
                  src={require('./../../assets/imgs/CamAI/AI1.png')}
                />
              </div>
              <div className="line1">大数据分析</div>
              <div className="line2">完整的大数据分析处理能力</div>
            </li>
            <li data-scroll-reveal="  enter bottom  over 1s move 50px after 0.5s ">
              <div className="img img2">
                <img
                  alt="AI赋能"
                  title="AI赋能"
                  src={require('./../../assets/imgs/CamAI/AI2.png')}
                />
              </div>
              <div className="line1">AI赋能</div>
              <div className="line2">人工智能与数据分析相结合</div>
            </li>
            <li data-scroll-reveal="  enter bottom  over 1.5s move 50px after .5s ">
              <div className="img img3">
                <img
                  alt="深入应用场景"
                  title="深入应用场景"
                  src={require('./../../assets/imgs/CamAI/AI3.png')}
                />
              </div>
              <div className="line1">深入应用场景</div>
              <div className="line2">深入应用场景，响应客户需求</div>
            </li>
            <li data-scroll-reveal="  enter bottom  over 1.5s move 50px after 1s ">
              <div className="img img4">
                <img
                  alt="急速响应"
                  title="急速响应"
                  src={require('./../../assets/imgs/CamAI/AI4.png')}
                />
              </div>
              <div className="line1">急速响应</div>
              <div className="line2">百亿级数据秒级响应</div>
            </li>
          </ul>
        </div>
        <div className="bright luxy-el">
          <div>
            <div className="title1">平台亮点</div>
            <ul>
              <li data-scroll-reveal="  enter bottom  over 0.5s ">
                <div className="img img1">
                  <img
                    alt="可视化交互分析图"
                    title="可视化交互分析图"
                    src={require('./../../assets/imgs/CamAI/AIIcon1.png')}
                  />
                </div>
                <div className="line1">可视化交互分析</div>
              </li>
              <li data-scroll-reveal="  enter bottom  over 0.7s after 0.5s ">
                <div className="img img2">
                  <img
                    alt="实时异常检测图"
                    title="实时异常检测图"
                    src={require('./../../assets/imgs/CamAI/AIIcon2.png')}
                  />
                </div>
                <div className="line1">实时异常检测</div>
              </li>
              <li data-scroll-reveal="  enter bottom  over 1s  after .5s ">
                <div className="img img3">
                  <img
                    alt="全方位数据整合图"
                    title="全方位数据整合图"
                    src={require('./../../assets/imgs/CamAI/AIIcon3.png')}
                  />
                </div>
                <div className="line1">全方位数据整合</div>
              </li>
              <li data-scroll-reveal="  enter bottom  over 0.8s  after 0.5s ">
                <div className="img img4">
                  <img
                    alt="数据洞察预测图"
                    title="数据洞察预测图"
                    src={require('./../../assets/imgs/CamAI/AIIcon4.png')}
                  />
                </div>
                <div className="line1">数据洞察预测</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(CamAI)
