import React from 'react'
import { Link } from 'react-router-dom'
import navs from './../../config/navs'
import './top.scss'
import { withRouter } from 'react-router-dom'
class Top extends React.Component {
  componentDidMount() {
    let st
    $(window).scroll(e => {
      clearTimeout(st)
      st = setTimeout(() => {
        let $top = $('#top')
        let $span = $('.navs li a span')
        let $span3 = $('.navs li:nth-of-type(3) a span')
        let $span4 = $('.navs li:nth-of-type(4) a span')
        let scrollTop = $(window).scrollTop()
        if (scrollTop > 70) {
          $top.css({ backgroundColor: 'rgb(34,38,46)' })
          //$top.css({'background': "url("+require('./../../assets/imgs/topBg.jpg')+") repeat left top"});
          $span.css({ backgroundColor: '#DE2E2E' })
        } else {
          $top.css({ background: '' })
          $span.css({ background: '#fff' })
        }
        if (document.title === 'CAM系统') {
          $span3.css({ opacity: 1 })
          $span4.css({ opacity: 0 })
        } else if (document.title === 'CAM研究') {
          $span4.css({ opacity: 1 })
          $span3.css({ opacity: 0 })
        } else {
          $span3.css({ opacity: 0 })
          $span4.css({ opacity: 0 })
        }
      }, 25)
    })
    //hover
    let stHover
    $('#top .navs li').hover(
      e => {
        clearTimeout(stHover)
        stHover = setTimeout(() => {
          $(e.currentTarget).addClass('hover')
        }, 200)
      },
      e => {
        clearTimeout(stHover)
        $(e.currentTarget).removeClass('hover')
      }
    )
    $('#top .navs li').click(e => {
      clearTimeout(stHover)
      $(e.currentTarget).removeClass('hover')
    })
  }

  jumpUrl = url => {
    this.props.history.push(url)
    if (this.props.location.pathname !== url) {
      $('html,body').animate({ scrollTop: 0 }, 500)
    }
  }

  render() {
    return (
      <div id="top">
        <img
          alt="logo"
          title="logo"
          className="logo"
          src={require('./../../assets/imgs/logo1.png')}
        />
        <ul className="navs">
          {navs.map((m, i) => {
            let isCurrentPage =
              m.url.length == 1 ? location.pathname == m.url : location.pathname.includes(m.url) //判断是否是当前页面
            let show = m.show === false ? false : true

            return (
              show && (
                <li key={'url_' + i}>
                  <a
                    onClick={() => this.jumpUrl(m.url)}
                    className={isCurrentPage ? 'selected' : ''}
                  >
                    {m.name}
                    <span />
                  </a>
                  {m.noShowChilds || m.childs == null || m.childs.length == 0 ? (
                    ''
                  ) : (
                    <div className="nav_childs">
                      {m.childs
                        .filter(sub => !sub.hide)
                        .map((sub, j) => (
                          <a key={'url_' + i + '_' + j} onClick={() => this.jumpUrl(sub.url)}>
                            {sub.name}
                          </a>
                        ))}
                    </div>
                  )}
                </li>
              )
            )
          })}
        </ul>
      </div>
    )
  }
}

export default withRouter(Top)
