import React from "react";

class HotspotObservation extends React.Component {
    componentDidMount() {
        $(function () {
            $('#hotspotObservationBanner').terseBanner({
                adaptive: true,
                auto: 4000,
                arrow: true,
            });
        });
    }

    componentWillUnmount() {
        let banner = $('#hotspotObservationBanner').data('terseBanner');
        if (banner.stop)
            banner.stop();//清除定时器
        $('#hotspotObservationBanner').data('terseBanner', undefined);
    }


    render() {
        return <div id={"hotspotObservation"} className={"tabContent"}>
            <div id="hotspotObservationBanner" className={"banner"}>
                <ul>
                    <li className="mb_item">
                        <img alt="行业热点观察一" title="行业热点观察一" data-src={require("../../../assets/imgs/Research/hotspotObservation/cam_sq56.jpg")}/>
                    </li>
                    <li className="mb_item">
                        <img alt="行业热点观察二" title="行业热点观察二" data-src={require("../../../assets/imgs/Research/hotspotObservation/cam_sq57.jpg")}/>
                    </li>
                    <li className="mb_item">
                        <img alt="行业热点观察三" title="行业热点观察三" data-src={require("../../../assets/imgs/Research/hotspotObservation/cam_sq58.jpg")}/>
                    </li>
                </ul>
            </div>
        </div>
    }
}

export default HotspotObservation;